import {
  useGetProfileQuery,
  useInitAccountMutation,
} from 'app/features/whatsapp'
import { Button } from 'components/ui/button'
import Loader from 'components/shared/loader'
import WhatsappProfileSettings from './whatsapp-profile-settings'

export default function WhatsappLogin() {
  const [initAccount] = useInitAccountMutation()

  const { data: profile, isLoading, isError, error } = useGetProfileQuery()

  console.log(error)

  const fbAsyncInit = function () {
    // JavaScript SDK configuration and setup
    window.FB.init({
      appId: process.env.REACT_APP_META_ID, // Meta App ID
      cookie: true, // enable cookies
      xfbml: true, // parse social plugins on this page
      version: 'v18.0', //Graph API version
    })
  }

  fbAsyncInit()

  function launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          const code = response.authResponse.code
          console.log('code ', code)
          console.log('response.authResponse ', response.authResponse)
          initAccount({ code })
          //Use this token to call the debug_token API and get the shared WABA's ID
          // resource.storeCredentials({token: code})
          //     .then((response) => {
          //         router.push({name: 'integration.whatsapp.select-phone-number'})
          //     })
          //     .catch(setError);
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      },
      {
        config_id: process.env.REACT_APP_META_CONFIG_ID, // configuration ID obtained in the previous step goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    )
  }

  if (isLoading)
    return (
      <div className="w-full h-[350px] grid place-content-center">
        <Loader />
      </div>
    )

  // TODO: to be added later
  // if (isError)
  //   return (
  //     <div className="w-full h-[350px] grid place-content-center">
  //       <Error message="Server error, contact administrator please" />
  //     </div>
  //   )

  return (
    <div className="flex flex-col space-y-4 justify-center items-center py-14">
      {profile ? (
        <WhatsappProfileSettings profile={profile} />
      ) : (
        <>
          <Button size="sm" onClick={() => launchWhatsAppSignup()}>
            Login with Facebook
          </Button>
        </>
      )}
    </div>
  )
}
