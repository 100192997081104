import { Font, StyleSheet, Text, View } from '@react-pdf/renderer'
import reportFont from 'assets/fonts/report-font.ttf'
import { createRef, useState, useEffect } from 'react'

Font.register({
  family: 'Bebas Neue',
  src: reportFont,
})

const styles = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  title: {
    fontSize: 30,
    color: '#00B4EF',
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subtitle: {
    fontSize: 12,
    color: '#4b5563',
  },
  tableTitle: {
    fontSize: 12,
    color: '#4b5563',
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
  },
  infoTitle: {
    fontSize: 10,
    color: '#4b5563',
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxNumber: {
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
    fontSize: 15,
  },
  boxText: {
    fontSize: 12,
    fontFamily: 'Bebas Neue',
  },
  subTitle: {
    fontSize: 15,
    fontFamily: 'Bebas Neue',
  },
  row: {
    marginTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default function AgentsPage({ data }: any) {
  const arrayToSort = [...data]
  const sortedUsers = arrayToSort.sort(
    (a: any, b: any) => b.userScore - a.userScore
  )

  function divideArray(bookings: any) {
    console.log(bookings)
    return bookings.reduce(
      (result: any, booking: any) => {
        if (booking.isShow && booking.isShowUpdated) {
          result[0].push(booking)
        } else if (!booking.isShow && booking.isShowUpdated) {
          result[1].push(booking)
        } else {
          result[2].push(booking)
        }
        console.log(result)
        return result
      },
      [[], [], []]
    )
  }
  const [elRefs, setElRefs] = useState([])

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(data.length)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    )
  }, [data])
  return (
    <View
      style={{
        marginTop: 50,
      }}
    >
      <Text style={{ ...styles.title }}>AGENTS PERFORMANCE</Text>

      <View
        style={{
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: '#e5e7eb',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View
          style={{
            flex: 1,
            marginRight: 20,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Agent</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Created</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Follow Ups</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Bookings</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Not Qualified</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>Show</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>No Show</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ ...styles.tableTitle }}>User Score</Text>
        </View>
      </View>

      {sortedUsers.map((agent: any, i) => {
        const [showBookings, noShowBooking, booking] = divideArray(
          agent.bookings
        )
        return (
          <View
            style={{
              marginTop: 20,
              borderBottomWidth: 1,
              borderBottomColor: '#e5e7eb',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 20,
              }}
            >
              <Text style={{ ...styles.subTitle, marginTop: 20 }}>
                {agent.firstName} {agent.lastName}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {agent.leadCount}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {agent.followUpCount}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {agent.bookingCount}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {agent.notQualifiedLeadsCount}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {showBookings?.length}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {noShowBooking?.length}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={{ ...styles.infoTitle, marginTop: 20 }}>
                {agent.userScore}
              </Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}
