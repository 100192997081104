import { useUpdateActiveTabsMutation } from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'

import { useEffect, useRef, useState } from 'react'
import useApp from 'hooks/useApp'

import { cn } from 'lib/utils'
import { Switch } from 'components/ui/switch'

export const tabs = [
  'Dashboard',
  'Leads',
  'Booked',
  'Show',
  'Not_Qualified',
  'Insurance',
  'Insurance_Portal',
  'Users',
  'Login_Requests',
  'Tasks',
  'Social',
]

export const allUsersTabs = ['Tasks']
export const tabsNavigation: any = {
  Dashboard: '/agents-dashboard',
  Leads: '/leads',
  Booked: '/booked',
  Show: '/show',
  Not_Qualified: '/not-qualified',
  Insurance: '/insurance',
  Insurance_Portal: '/insurance-portal',
  Social: '/social',
  Tasks: '/tasks',
  Users: '/users',
  Login_Requests: '/agents-login-requests',
}

export default function ActiveTabs() {
  const { activeTabs: settingsActiveTabs } = useApp()
  const [error, setError] = useState('')
  const [updateActiveTabs, { isLoading, isError }] =
    useUpdateActiveTabsMutation()

  const [activeTabs, setActiveTabs] = useState<any[]>([])

  useEffect(() => {
    setActiveTabs(
      settingsActiveTabs && settingsActiveTabs.length > 0
        ? settingsActiveTabs[0]?.activeTabs
        : []
    )
  }, [settingsActiveTabs])

  const switchHandler = (tab: string) => {
    setActiveTabs((prev: any[]) => {
      if (prev.includes(tab)) {
        const newTabs = prev.filter((item) => item != tab)
        return [...newTabs]
      } else {
        return [...prev, tab]
      }
    })
  }

  async function update() {
    setError('')

    const data = {
      activeTabs,
    }

    const result: any = await updateActiveTabs(data)
    if (result?.error) {
      return setError('Failed to update active tabs')
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col p-6 gap-3 ">
        <h1 className="text-lg font-medium">Tabs</h1>

        <div className="w-full flex items-center gap-3 max-md:flex-col">
          <div className="border rounded-md bg-white  overflow-hidden overflow-x-scroll ">
            <table
              className={cn(
                isLoading ? 'text-gray-400' : 'text-gray-800',
                'text-sm w-full border-collapse text-[13px]  table-fixed'
              )}
            >
              <thead>
                <tr className="text-left border-b w-full ">
                  <th className="xl:w-40 ">Tab</th>
                  <th className="xl:w-10">Active</th>
                </tr>
              </thead>
              <tbody>
                {tabs.map((tab) => {
                  return (
                    <tr key={tab} className="border-b last:border-b-0">
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis text-base">
                        {tab.replace('_', ' ')}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        <Switch
                          className="data-[state=checked]:bg-indigo-600"
                          checked={activeTabs.includes(tab)}
                          onCheckedChange={() => {
                            switchHandler(tab)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5 flex justify-between items-center">
        {error !== '' && (
          <p className="px-3 py-2 bg-rose-100 rounded-md text-xs font-medium text-rose-600">
            {error}
          </p>
        )}
        <div className="flex-1" />
        <Button onClick={update} size="sm">
          Update
        </Button>
      </div>
    </>
  )
}
