import { Textarea } from 'components/ui/textarea'
import { Label } from 'components/ui/label'

export const CustomTextArea = ({
  label,
  name,
  required,
  value,
  setValue,
  type,
  disabled,
}: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && <span className="ml-1 text-rose-500">*</span>}
      </Label>
      <Textarea
        id={name}
        value={value}
        onChange={setValue}
        disabled={disabled}
      ></Textarea>
    </div>
  )
}
