import { useAddCommentMutation } from 'app/features/task'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useState } from 'react'

export const AddComment = ({ taskId }: any) => {
  const [content, setContent] = useState('')

  const [addComment, { isLoading }] = useAddCommentMutation()

  const [error, setError] = useState('')

  async function addCommentHandler(e: any) {
    e.preventDefault()
    if (!content) {
      return setError('comment is required')
    }

    setError('')
    try {
      const result: any = await addComment({
        content,
        taskId: taskId,
      })
      if (result?.error?.status === 409) return setError(result?.error?.data)
      if (result?.error)
        return setError('Failed to add comment, please try again')
      setContent('')
    } catch (error) {
      return setError('Failed to add comment, please try again')
    }
  }

  return (
    <div className="flex-col">
      <form className="mb-6">
        <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <textarea
            id="comment"
            value={content}
            onChange={(e: any) => setContent(e.target.value)}
            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
            placeholder="Write a comment..."
            required
          ></textarea>
        </div>
        <Button disabled={isLoading} onClick={addCommentHandler} size="sm">
          Post Comment
        </Button>
      </form>

      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
    </div>
  )
}
