import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'

import ClaimInsurance from 'pages/leads/components/claim-insurance'
import LeadPopUp from './lead-pop-up'
import { hasInsuranceAccess, shouldClaimInsurance } from 'lib/allowed'
import { useSelector } from 'react-redux'

export default function LeadsByInsuranceClaim({ leads }: any) {
  const { user } = useSelector((state: any) => state.user)
  const { settings } = useApp()
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const [selectedInsurance, setSelectedInsurance] = useState<any>(null)
  const [open, setOpen] = useState(false)

  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)
  const [selectedLead, setSelectedLead] = useState<any>(null)

  const [reachMaxInsuranceClaims, setReachMaxInsuranceClaims] = useState([])
  const [endedManually, setEndedManually] = useState([])

  useEffect(() => {
    setReachMaxInsuranceClaims(
      leads.filter(
        (lead: any) =>
          lead.isSendToInsuranceEnded &&
          lead.sendToInsuranceEndedReason == 'Reached_Max'
      )
    )
  }, [leads])

  useEffect(() => {
    setEndedManually(
      leads.filter(
        (lead: any) =>
          lead.isSendToInsuranceEnded &&
          lead.sendToInsuranceEndedReason == 'Manual'
      )
    )
  }, [leads])

  return (
    <>
      <div className="border pl-3 pt-3 pb-3 rounded-md shadow-sm w-full h-full bg-white">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold">Need Insurance claim</h1>
        </div>

        <div className="flex flex-col gap-5 h-full pt-5 text-sm">
          {Array(parseInt(settings[0].numberOfInsuranceClaims))
            .fill(0)
            .map((_, i) => (
              <div key={`${i}dashboard`}>
                <div className="p-3 rounded-md border border-[#b1e1e7] relative">
                  <div className="absolute text-[#0a62a2] text-xs bg-white -top-2 px-1 font-bold">
                    {
                      leads?.filter(
                        (lead: any) =>
                          lead.insuranceClaimsLength === i &&
                          !lead.isSendToInsuranceEnded
                      ).length
                    }{' '}
                    leads needs insurance claim {i + 1}
                  </div>
                  {leads?.filter(
                    (lead: any) =>
                      lead.insuranceClaimsLength === i &&
                      !lead.isSendToInsuranceEnded
                  )?.length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No leads
                    </div>
                  )}
                  <div className="flex flex-col gap-1 mt-3">
                    {leads
                      ?.filter(
                        (lead: any) =>
                          lead.insuranceClaimsLength === i &&
                          !lead.isSendToInsuranceEnded
                      )
                      ?.slice(0, 5)
                      .map((lead: any) => (
                        <div
                          key={lead?.id}
                          className="grid grid-cols-4 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                        >
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.firstName} {lead?.lastName}
                          </p>
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.source?.name}
                          </p>
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.phone}
                          </p>
                          <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            <Button
                              size="sm"
                              variant="outline"
                              disabled={
                                !shouldClaimInsurance(
                                  lead,

                                  numberOfInsuranceClaims
                                ) || !hasInsuranceAccess(user)
                              }
                              onClick={async () => {
                                setSelectedLead(lead)
                                setOpenClaimInsurance(true)
                              }}
                            >
                              Insurance Claim
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                  {leads?.filter(
                    (lead: any) =>
                      lead.insuranceClaimsLength === i &&
                      !lead.isSendToInsuranceEnded
                  )?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setSelectedInsurance(
                            leads.filter(
                              (lead: any) =>
                                lead.insuranceClaimsLength === i &&
                                !lead.isSendToInsuranceEnded
                            )
                          )
                          setOpen(true)
                        }}
                        className="text-[#0a62a2] font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +
                        {leads.filter(
                          (lead: any) =>
                            lead.insuranceClaimsLength === i &&
                            !lead.isSendToInsuranceEnded
                        )?.length - 5}{' '}
                        more leads
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        <div key={``} className="mt-10">
          <div className="p-3 rounded-md border border-[#b1e1e7] relative ">
            <div className="absolute text-[#0a62a2] text-xs bg-white -top-2 px-1 font-bold">
              {reachMaxInsuranceClaims?.length} leads reached maximum insurance
              claims
            </div>
            {reachMaxInsuranceClaims?.length < 1 && (
              <div className="text-center text-gray-500 text-xs">No leads</div>
            )}
            <div className="flex flex-col gap-1 mt-3">
              {reachMaxInsuranceClaims?.slice(0, 5).map((lead: any) => (
                <div
                  key={lead?.id}
                  className="grid grid-cols-4 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                >
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.firstName} {lead?.lastName}
                  </p>
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.source?.name}
                  </p>
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.phone}
                  </p>
                  <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    <Button
                      size="sm"
                      variant="outline"
                      disabled={
                        !shouldClaimInsurance(
                          lead,

                          numberOfInsuranceClaims
                        )
                      }
                      onClick={async () => {
                        setSelectedLead(lead)
                        setOpenClaimInsurance(true)
                      }}
                    >
                      Insurance Claim
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            {reachMaxInsuranceClaims?.length > 5 && (
              <div className="mt-5">
                <span
                  onClick={() => {
                    setSelectedInsurance(reachMaxInsuranceClaims)
                    setOpen(true)
                  }}
                  className="text-[#0a62a2] font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                >
                  +{reachMaxInsuranceClaims.length - 5} more leads
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="mt-10">
          <div className="p-3 rounded-md border border-[#b1e1e7] relative ">
            <div className="absolute text-[#0a62a2] text-xs bg-white -top-2 px-1 font-bold">
              {endedManually?.length} leads insurance ended manually
            </div>
            {endedManually?.length < 1 && (
              <div className="text-center text-gray-500 text-xs">No leads</div>
            )}
            <div className="flex flex-col gap-1 mt-3">
              {endedManually?.slice(0, 5).map((lead: any) => (
                <div
                  key={lead?.id}
                  className="grid grid-cols-4 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                >
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.firstName} {lead?.lastName}
                  </p>
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.source?.name}
                  </p>
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.phone}
                  </p>
                  <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    <Button
                      size="sm"
                      variant="outline"
                      disabled={
                        !shouldClaimInsurance(
                          lead,

                          numberOfInsuranceClaims
                        )
                      }
                      onClick={async () => {
                        setSelectedLead(lead)
                        setOpenClaimInsurance(true)
                      }}
                    >
                      Insurance Claim
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            {endedManually?.length > 5 && (
              <div className="mt-5">
                <span
                  onClick={() => {
                    setSelectedInsurance(endedManually)
                    setOpen(true)
                  }}
                  className="text-[#0a62a2] font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                >
                  +{endedManually.length - 5} more leads
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <LeadPopUp
        leads={selectedInsurance}
        open={open}
        setOpen={setOpen}
        canClaimInsurance={true}
        setOpenClaimInsurance={setOpenClaimInsurance}
        setSelectedLead={setSelectedLead}
      />
      {selectedLead && openClaimInsurance && (
        <ClaimInsurance
          leadId={selectedLead.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
    </>
  )
}
