import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useMediaFileMutation,
  useSendTemplateMutation,
} from 'app/features/whatsapp'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button, buttonVariants } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

import {
  cn,
  containsSubstring,
  convertToCamelCase,
  convertToTitleCase,
  prepareMediaFormData,
} from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { FcDataSheet } from 'react-icons/fc'
import { GiReturnArrow } from 'react-icons/gi'
import { HiVariable } from 'react-icons/hi'
import { TEMPLATE_VARIABLES } from './actions/create-template'
import PreviewTemplateMessage from './actions/preview-template-message'

interface SendTemplateProps {
  template: any
  open: boolean
  lead?: any
  setOpen: (open: any) => void
  phoneNumber?: string
  startMessagingLeadId?: string
  displaySentTemplate: (template: any) => void
}

const initialData = {
  name: '',
  language: '',
  mediaSample: '',
  headerMediaSampleFile: null,
  headerMediaSampleFileContent: null,
  headerTextSample: {},
  bodySample: {},
}

export default function SendTemplate({
  template,
  open,
  setOpen,
  phoneNumber,
  lead,
  startMessagingLeadId,
  displaySentTemplate,
}: SendTemplateProps) {
  const [sendTemplateFunction] = useSendTemplateMutation()
  const [mediaFileFunction] = useMediaFileMutation()
  const [data, setData] = useState<any>(initialData)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )

  const headerFormat = headerComponent?.format
  const headerTextVariables = headerComponent?.example?.header_text
  const headerTextVariablesCount = headerComponent?.example?.header_text?.length
  const bodyVariables = bodyComponent?.example?.body_text
  const bodyVariablesCount =
    bodyComponent?.example?.body_text?.length > 0
      ? bodyComponent?.example?.body_text[0].length
      : 0

  useEffect(() => {
    const headerTextSample: any = {}
    if (headerTextVariablesCount > 0) {
      for (let i = 0; i < headerTextVariablesCount; i++) {
        //headerTextSample[i + 1] = headerTextVariables[i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (
            containsSubstring(headerTextVariables[i], TEMPLATE_VARIABLES[j])
          ) {
            headerTextSample[i + 1] =
              lead[convertToCamelCase(TEMPLATE_VARIABLES[j])]
          }
        }
      }
    }

    const bodySample: any = {}
    if (bodyVariablesCount > 0) {
      for (let i = 0; i < bodyVariablesCount; i++) {
        //bodySample[i + 1] = bodyVariables[0][i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (containsSubstring(bodyVariables[0][i], TEMPLATE_VARIABLES[j])) {
            bodySample[i + 1] = lead[convertToCamelCase(TEMPLATE_VARIABLES[j])]
          }
        }
      }
    }
    setData({
      ...initialData,
      headerTextSample,
      bodySample,
    })
    setError('')
    setLoading(false)
  }, [open])

  async function sendTemplate() {
    setLoading(true)
    setError('')

    /* header text variables validation */

    const headerTextSample = Object.values(data.headerTextSample)
    if (
      headerTextSample.length < headerTextVariablesCount ||
      headerTextSample.some((element) => element == '' || !element)
    ) {
      {
        setLoading(false)
        return setError('Header text variables required')
      }
    }

    /* header media sample validation */
    if (
      (headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT') &&
      !data.headerMediaSampleFile
    ) {
      setLoading(false)
      return setError('Media sample required')
    }

    /* body variables validation */
    const bodySample = Object.values(data.bodySample)
    if (
      bodySample.length < bodyVariablesCount ||
      bodySample.some((element) => element == '' || !element)
    ) {
      {
        setLoading(false)
        return setError('Body variables sample required')
      }
    }

    try {
      /* Header media upload */
      let headerMediaSampleFile = null

      const formData = prepareMediaFormData({
        my_file: data.headerMediaSampleFile,
      })

      if (
        headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT'
      ) {
        const uploadResult: any = await mediaFileFunction(formData)
        if (uploadResult?.error) {
          setLoading(false)
          return setError('Failed to upload media, please try again')
        }
        headerMediaSampleFile = uploadResult?.data?.id
      }

      /* create template */
      const result: any = await sendTemplateFunction({
        messaging_product: 'whatsapp',
        to: phoneNumber,
        leadName: lead?.firstName,
        startMessagingLeadId,
        template: {
          name: template?.name,
          category: template?.category,
          language: template?.language,
          headerFormat,
          headerMediaSampleFile,
          headerTextSample,
          bodySample,
          components: template?.components,
        },
      })

      if (result?.error) {
        setLoading(false)
        return setError('Failed to send template, please try again')
      }
      displaySentTemplate({
        name: template?.name,
        language: template?.language,
        headerMediaSampleFile,
        headerTextSample,
        bodySample,
      })
      setOpen(false)
    } catch (error) {
      setLoading(false)
      return setError('Failed to send template, please try again')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
            <div className="flex w-full items-center justify-between  pb-4 border-b">
              <h1 className="text-lg font-semibold capitalize">
                Send Template
              </h1>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <Cross1Icon className="h-3 w-3" />
              </Button>
            </div>

            <div className="flex gap-2 max-md:flex-col items-center">
              {template && (
                <PreviewTemplateMessage
                  template={template}
                  headerTextSample={Object.values(data.headerTextSample)}
                  bodySample={Object.values(data.bodySample)}
                  headerMediaSample={data.headerMediaSampleFileContent}
                />
              )}
              <div className=" p-4">
                {((headerFormat == 'TEXT' && headerTextVariablesCount > 0) ||
                  headerFormat == 'VIDEO' ||
                  headerFormat == 'IMAGE' ||
                  headerFormat == 'DOCUMENT' ||
                  bodyVariablesCount > 0) && (
                  <h1 className="flex items-center font-medium gap-1  ">
                    Fill dynamic information <FcDataSheet size={20} />
                  </h1>
                )}
                <div className="w-full space-y-3 mt-5 px-1">
                  {((headerFormat == 'TEXT' && headerTextVariablesCount > 0) ||
                    headerFormat == 'VIDEO' ||
                    headerFormat == 'IMAGE' ||
                    headerFormat == 'DOCUMENT') && (
                    <div className="w-full space-y-2  p-2 ">
                      <Label>Header</Label>
                      {headerFormat == 'TEXT' &&
                        headerTextVariablesCount > 0 && (
                          <DynamicTextArea
                            data={data}
                            setData={setData}
                            indexKey={'headerText'}
                            vars={headerTextVariablesCount}
                            samples={headerTextVariables}
                          />
                        )}
                      {(headerFormat == 'VIDEO' ||
                        headerFormat == 'IMAGE' ||
                        headerFormat == 'DOCUMENT') && (
                        <MediaSelect
                          data={data}
                          setData={setData}
                          indexKey="headerMedia"
                          format={headerFormat}
                        />
                      )}
                    </div>
                  )}
                  {bodyVariablesCount > 0 && (
                    <div className="w-full space-y-2  p-2 ">
                      <Label>Body</Label>

                      <DynamicTextArea
                        data={data}
                        setData={setData}
                        indexKey={'body'}
                        vars={bodyVariablesCount}
                        samples={bodyVariables[0]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {error !== '' && (
              <div className="text-rose-600 text-sm">{error}</div>
            )}
            <div className="flex items-center gap-3 justify-between">
              <div />
              {/**TOTO: preview button */}
              <Button size="sm" onClick={sendTemplate} disabled={loading}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function DynamicTextArea({ data, setData, indexKey, vars, samples }: any) {
  const sampleKey = indexKey + 'Sample'

  const handleVariableValueChange = (index: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: { ...prev[sampleKey], [index]: value },
    }))
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          {Array.from(Array(vars)).map((placeholder: any, index: any) => (
            <div className="flex  gap-1 items-center">
              <Label className="w-1/2 ">
                {convertToTitleCase(samples[index])}
                <span className="ml-1 text-rose-500 ">*</span>
              </Label>
              <Input
                type={'text'}
                className=""
                placeholder={samples[index]}
                value={data[sampleKey][index + 1] || ''}
                onChange={(e) =>
                  handleVariableValueChange(index + 1, e.target.value)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function MediaSelect({ setData, data, indexKey, format }: any) {
  const fileRef = useRef<any>()
  const sampleKey = indexKey + 'SampleFile'
  const contentKey = indexKey + 'SampleFileContent'

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setData((prev: any) => {
      return { ...prev, [sampleKey]: null, [contentKey]: null }
    })
  }

  const handleMediaFileChange = (event: any) => {
    const file = event.target.files[0]
    setData((prev: any) => {
      return { ...prev, [sampleKey]: file }
    })
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setData((prev: any) => {
          return { ...prev, [sampleKey]: file, [contentKey]: reader.result }
        })
      }
      reader.readAsDataURL(file)
    }
  }
  return (
    <div>
      <div className="flex mt-2  flex-col gap-2">
        <div className="w-full items-center flex justify-between">
          <Label className="w-1/3 ">
            Media
            <span className="ml-1 text-rose-500 ">*</span>
          </Label>
          <label
            htmlFor="media"
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-44 border text-left text-xs',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            ADD {format}
            <input
              id="media"
              type="file"
              ref={fileRef}
              accept={
                format == 'IMAGE'
                  ? 'image/*'
                  : format == 'VIDEO'
                    ? 'video/*'
                    : '*'
              }
              style={{ display: 'none' }}
              className="cursor-pointer"
              onChange={handleMediaFileChange}
            />
          </label>

          <Button
            onClick={replaceFileHandler}
            variant={'outline'}
            className="text-xs font-medium flex items-center"
          >
            reset
            <GiReturnArrow
              size={10}
              className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
            />
          </Button>
        </div>
        <span className="text-xs">{data[sampleKey]?.name}</span>
      </div>
    </div>
  )
}
