import { useState, useEffect } from 'react'
import Sources from './sources'
import Groups from './groups'
import FollowUp from './follow-up'
import LeadForm from './lead-form'
import { navigateAgents } from 'lib/allowed'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubscriptionWrapper } from 'components/shared/wrapperSubscription'

import { SettingsMobileMenu } from './components/settings-mobile-menu'
import { SettingsSideMenu } from './components/settings-side-menu'
import NotQualifiedReasons from './not-qualified-reasons'
import Brand from './brand'
import ActiveTabs from './active-tabs'
import useApp from 'hooks/useApp'
import { Insurance } from './insurance'
import { Social } from './social'
import { LeadInterests } from './lead-interests'

export default function Settings() {
  const navigate = useNavigate()
  const location = useLocation()

  const { settingsActiveTabs } = useApp()
  const { user } = useSelector((state: any) => state.user)
  const [screen, setScreen] = useState('followUp')
  useEffect(() => {
    if (location?.state?.screen) {
      setScreen(location?.state?.screen)
    } else {
      setScreen('followUp')
    }
  }, [location?.state])
  useEffect(() => {
    if (user && user.role === 'AGENT') {
      navigate(navigateAgents(user, settingsActiveTabs))
    }
  }, [user])

  /**
   * Update the URL when the screen changes
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('screen', screen)

    navigate({
      search: queryParams.toString(),
    })
  }, [screen])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const screenQuery = queryParams.get('screen')

    if (screenQuery) {
      setScreen(screenQuery)
    }
  }, [])

  return (
    // TODO:
    // ASK FOR
    // MOHAMMAD ALBACHA // 7XL
    <div className="p-3 overflow-hidden h-screen max-w-7xl max-md:pr-2 max-md:pb-10">
      <div className="flex gap-3 h-full max-md:flex-col max-md:justify-center max-md:items-center w-full">
        <SettingsMobileMenu screen={screen} setScreen={setScreen} />
        <SettingsSideMenu screen={screen} setScreen={setScreen} />
        <div className="flex-1 h-full bg-white border rounded-md  w  w-[calc(100vw-40rem)]  max-md:w-[94vw] overflow-y-scroll">
          {screen === 'followUp' && (
            <SubscriptionWrapper>
              <FollowUp />
            </SubscriptionWrapper>
          )}
          {screen === 'brand' && (
            <SubscriptionWrapper>
              <Brand />
            </SubscriptionWrapper>
          )}
          {screen === 'not-qualified-reasons' && (
            <SubscriptionWrapper>
              <NotQualifiedReasons />
            </SubscriptionWrapper>
          )}
          {screen === 'insurance' && (
            <SubscriptionWrapper>
              <Insurance />
            </SubscriptionWrapper>
          )}
          {screen === 'sources' && (
            <SubscriptionWrapper>
              <Sources />
            </SubscriptionWrapper>
          )}
          {screen === 'groups' && (
            <SubscriptionWrapper>
              <Groups />
            </SubscriptionWrapper>
          )}
          {screen === 'active-tabs' && (
            <SubscriptionWrapper>
              <ActiveTabs />
            </SubscriptionWrapper>
          )}

          {screen === 'lead-interests' && (
            <SubscriptionWrapper>
              <LeadInterests />
            </SubscriptionWrapper>
          )}

          {screen === 'leadForm' && (
            <SubscriptionWrapper>
              <LeadForm />
            </SubscriptionWrapper>
          )}

          {screen === 'social' && <Social />}
        </div>
      </div>
    </div>
  )
}
