import DateAndTime from 'components/shared/date-and-time'
import { useEffect, useState } from 'react'
import { Label, Line } from 'recharts'
import { useToast } from 'components/ui/use-toast'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  TbAlertTriangle,
  TbAsterisk,
  TbBrandWechat,
  TbCheck,
  TbChecks,
  TbChevronLeft,
  TbClock,
  TbInfoHexagon,
  TbMessage,
  TbMessages,
  TbRocket,
} from 'react-icons/tb'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import dayjs from 'dayjs'
import { Button } from 'components/ui/button'
import { useNavigate } from 'react-router-dom'
import {
  BarChart,
  LineChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import {
  useGetStatisticsQuery,
  useGetAnalyticsQuery,
  useGetResponseTimeReportQuery,
} from 'app/features/whatsappStats'
import { useGetCampaignsQuery } from 'app/features/whatsapp'

export default function WhatsappStats() {
  const { toast } = useToast()
  const navigate = useNavigate()

  /* Filters */
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [startDate, setStartDate] = useState<Date>(
    dayjs().subtract(30, 'day').toDate()
  )
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())

  /* Statistics */
  const [totalConversations, setTotalConversations] = useState(0)
  const [totalNewConversations, setTotalNewConversations] = useState(0)
  const [totalOpenConversations, setTotalOpenConversations] = useState(0)
  const [totalRepliedConversations, setTotalRepliedConversations] = useState(0)
  const [totalSentMessages, setTotalSentMessages] = useState(0)
  const [totalDeliveredMessages, setTotalDeliveredMessages] = useState(0)
  const [totalReadMessages, setTotalReadMessages] = useState(0)
  const [totalFailedMessages, setTotalFailedMessages] = useState(0)

  /* Analytics */
  const [whatsAppAnalyticsGranularity, setWhatsAppAnalyticsGranularity] =
    useState('DAY')
  const [
    whatsAppAnalyticsGranularityItems,
    setWhatsAppAnalyticsGranularityItems,
  ] = useState([
    {
      value: 'HALF_HOUR',
      label: 'Half Hour',
    },
    {
      value: 'DAY',
      label: 'Day',
    },
    {
      value: 'MONTH',
      label: 'Month',
    },
  ])

  /* Campaigns */
  const [campaigns, setCampaigns] = useState<
    {
      createdAt: string
      succeeded: number
      failed: number
    }[]
  >([])

  const {
    refetch: refetchStatistics,
    isLoading: isLoadingStatistics,
    data: statisticsData,
    isError: isErrorStatistics,
  } = useGetStatisticsQuery({
    from: dayjs(startDate).startOf('day').toISOString(),
    to: dayjs(endDate).endOf('day').toISOString(),
  })

  useEffect(() => {
    if (isErrorStatistics) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching statistics',
      })
    }
  }, [isErrorStatistics])

  useEffect(() => {
    if (statisticsData) {
      const {
        totalConversations,
        totalNewConversations,
        totalOpenConversations,
        totalRepliedConversations,
        totalSentMessages,
        totalDeliveredMessages,
        totalReadMessages,
        totalFailedMessages,
      } = statisticsData

      setTotalConversations(totalConversations)
      setTotalNewConversations(totalNewConversations)
      setTotalOpenConversations(totalOpenConversations)
      setTotalRepliedConversations(totalRepliedConversations)
      setTotalSentMessages(totalSentMessages)
      setTotalDeliveredMessages(totalDeliveredMessages)
      setTotalReadMessages(totalReadMessages)
      setTotalFailedMessages(totalFailedMessages)
    }
  }, [statisticsData])

  const {
    refetch: refetchAnalytics,
    isLoading: isLoadingAnalytics,
    data: analyticsChartDate,
    isError: isErrorAnalytics,
  } = useGetAnalyticsQuery({
    from: dayjs(startDate).format('YYYY-MM-DD'),
    to: dayjs(endDate).format('YYYY-MM-DD'),
    granularity: whatsAppAnalyticsGranularity,
  })

  useEffect(() => {
    if (isErrorAnalytics) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching analytics',
      })
    }
  }, [isErrorAnalytics])

  const {
    refetch: refetchResponseTimeReport,
    isLoading: isLoadingResponseTimeReport,
    data: responseTimeReport,
    isError: isErrorResponseTimeReport,
  } = useGetResponseTimeReportQuery({
    startDate: dayjs(startDate).startOf('day').toISOString(),
    endDate: dayjs(endDate).endOf('day').toISOString(),
  })

  useEffect(() => {
    if (isErrorResponseTimeReport) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching response time report',
      })
    }
  }, [isErrorResponseTimeReport])

  const {
    refetch: refetchCampaigns,
    isLoading: isLoadingCampaigns,
    data: campaignsData,
    isError: isErrorCampaigns,
  } = useGetCampaignsQuery({
    from: dayjs(startDate).startOf('day').toISOString(),
    to: dayjs(endDate).endOf('day').toISOString(),
  })

  useEffect(() => {
    if (campaignsData) {
      const rawData = campaignsData?.campaigns

      const processedData = rawData.map((item: any) => ({
        name: new Date(item.createdAt).toISOString().split('T')[0], // Use date as the name
        succeeded: item.succeeded?.length || 0,
        failed: item.failed?.length || 0,
      }))

      const aggregatedData = processedData.reduce(
        (acc: any, { createdAt, succeeded, failed }: any) => {
          const existing = acc.find((item: any) => item.createdAt === createdAt)
          if (existing) {
            existing.succeeded += succeeded
            existing.failed += failed
          } else {
            acc.push({
              createdAt: dayjs(createdAt).format('YYYY-MM-DD'),
              succeeded,
              failed,
            })
          }
          return acc
        },
        []
      )

      setCampaigns(aggregatedData)
    }
  }, [campaignsData])

  useEffect(() => {
    if (isErrorCampaigns) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching campaigns',
      })
    }
  }, [isErrorCampaigns])

  const formatXAxisAnalytics = (tick: any) => {
    return dayjs(tick).format('MM/DD')
  }

  /**
   * Custom tooltip for WhatsApp analytics chart.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip.
   * @param {string} param.label - Label for the tooltip.
   *
   * @returns {ReactNode} - JSX for the tooltip.
   */
  const customAnalyticsTooltip = ({ active, payload, label }: any) => {
    const g = whatsAppAnalyticsGranularity
    const addedPeriod = g == 'HALF_HOUR' ? 30 : g == 'DAY' ? 1 : 30
    const addedType = g == 'HALF_HOUR' ? 'minute' : g == 'DAY' ? 'day' : 'day'

    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>
            {dayjs(label).format('YYYY/MM/DD HH:mm')}
            &nbsp;to&nbsp;
            {dayjs(label).add(addedPeriod, addedType).format('MM/DD HH:mm')}
          </p>
          <p style={{ color: '#8884d8' }}>Sent messages: {payload[0].value}</p>
          <p style={{ color: '#82ca9d' }}>
            Delivered messages: {payload[1].value}
          </p>
        </div>
      )
    }

    return null
  }

  /**
   * Custom tooltip for displaying WhatsApp response analytics.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip containing response times.
   * @param {string} param.label - Label for the tooltip indicating the time period.
   *
   * @returns {ReactNode|null} - JSX for the tooltip displaying average response times
   *                             or null if inactive or no payload.
   */
  const customResponseTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>{label}</p>
          <p style={{ color: '#8884d8' }}>
            Average First Response Time: {payload[0].value} seconds
          </p>
          <p style={{ color: '#82ca9d' }}>
            Average Overall Response Time: {payload[1].value} seconds
          </p>
        </div>
      )
    }
  }

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between">
          <div>
            <Button variant={'outline'} onClick={() => navigate(-1)}>
              <TbChevronLeft />
            </Button>
          </div>
          <div className="flex flex-col-3 gap-4 justify-end mb-4">
            <div className="space-y-2">
              <Label className="text-sm font-semibold">Start Date:</Label>
              <DateAndTime
                date={startDate}
                setDate={setStartDate}
                hour={startHour}
                setHour={setStartHour}
                minute={startMinute}
                setMinute={setStartMinute}
                ampm={startAmpm}
                setAmpm={setStartAmpm}
              />
            </div>

            <div className="space-y-2">
              <Label className="text-sm font-semibold">End Date:</Label>
              <DateAndTime
                date={endDate}
                setDate={setEndDate}
                hour={endHour}
                setHour={setEndHour}
                minute={endMinute}
                setMinute={setEndMinute}
                ampm={endAmpm}
                setAmpm={setEndAmpm}
                beforeSelectedDate={startDate}
              />
            </div>
          </div>
        </div>

        <h1 className="text-2xl font-bold">Conversations</h1>
        <div className="grid grid-cols-8 gap-4 m-4">
          <Stat
            number={totalConversations}
            title="Total"
            icon={<TbMessage size={26} color="gray" />}
            description="Number of conversations that have messages created within the date range"
          />
          <Stat
            number={totalNewConversations}
            title="New"
            icon={<TbAsterisk size={26} color="gray" />}
            description="Number of new conversations that have at least one lead created within the date range"
          />
          <Stat
            number={totalOpenConversations}
            title="Open"
            icon={<TbInfoHexagon size={26} color="gray" />}
            description="Number of open conversations that have at least one message with a status of either 'SENT' or 'DELIVERED' within the date range"
          />
          <Stat
            number={totalRepliedConversations}
            title="Replied"
            icon={<TbMessages size={26} color="gray" />}
            description="Number of replied conversations that have at least one message sent by an agent within the date range"
          />
        </div>

        <h1 className="text-2xl font-bold">Messages </h1>
        <div className="grid grid-cols-8 gap-4 m-4">
          <Stat
            number={totalSentMessages}
            title="Sent"
            icon={<TbCheck size={26} color="gray" />}
            description="Total number of sent messages"
          />
          <Stat
            number={totalDeliveredMessages}
            title="Delivered"
            icon={<TbChecks size={26} color="gray" />}
            description="Total number of sent messages"
          />
          <Stat
            number={totalReadMessages}
            title="Read"
            icon={<TbChecks size={26} />}
            description="Total number of read messages"
          />
          <Stat
            number={totalFailedMessages}
            title="Failed"
            icon={<TbAlertTriangle size={26} color="red" />}
            description="Total number of failed messages"
          />
        </div>

        <h1 className="text-2xl font-bold mt-10 mb-4">WhatsApp Analytics </h1>
        <div className="text-sm">
          This chart visualizes key messaging metrics from the WhatsApp Business
          API, including the number of messages sent and delivered over time
        </div>

        <div className="grid grid-cols-6 justify-end gap-4 mt-4">
          <div>
            <Label className="text-sm font-semibold">Granularity</Label>
            <Select
              value={whatsAppAnalyticsGranularity}
              onValueChange={(value: any) =>
                setWhatsAppAnalyticsGranularity(value)
              }
            >
              <SelectTrigger className="w-full">
                <SelectValue>
                  {
                    whatsAppAnalyticsGranularityItems.find(
                      (item) => item.value === whatsAppAnalyticsGranularity
                    )?.label
                  }
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {whatsAppAnalyticsGranularityItems.map((item: any) => (
                    <SelectItem key={item.value} value={item.value}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <BarChart
          width={1000}
          height={200}
          data={analyticsChartDate}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="start" tickFormatter={formatXAxisAnalytics} />
          <YAxis />
          <Tooltip content={customAnalyticsTooltip} />
          <Legend />
          <Bar dataKey="sent" fill="#8884d8" name="Messages Sent" />
          <Bar dataKey="delivered" fill="#82ca9d" name="Messages Delivered" />
        </BarChart>

        <h1 className="text-2xl font-bold mb-2">Response report</h1>
        <div className="text-sm mb-8">
          Tracking the response time of conversations, this chart shows the
          efficiency of our agents in delivering prompt responses. The 'First
          Response Time' represents the time it takes for an agent to respond to
          the first message in a conversation. The 'Overall Response Time' shows
          the average time it takes for an agent to respond to all messages in a
          conversation. The 'Missed Messages' indicator indicates the number of
          times an agent missed a message in a conversation
        </div>

        <div className="grid grid-cols-8 gap-4 m-4">
          <Stat
            number={responseTimeReport?.system?.avgFirstResponseTime}
            title="Avg. first"
            icon={<TbClock size={26} color="gray" />}
            description="The average time in seconds it takes for an agent to respond to the first message in a conversation."
          />
          <Stat
            number={responseTimeReport?.system?.avgOverallResponseTime}
            title="Avg. All"
            icon={<TbRocket size={26} color="gray" />}
            description="The average time in seconds it takes for an agent to respond to all messages in a conversation."
          />
          <Stat
            number={responseTimeReport?.system?.missedMessages}
            title="Missed"
            icon={<TbAlertTriangle size={26} color="gray" />}
            description="The number of messages that were not responded to by an agent within 24h time frame."
          />
        </div>

        <BarChart
          width={1000}
          height={200}
          data={responseTimeReport?.agents}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={customResponseTooltip} />
          <Legend />
          <Bar
            dataKey="avgFirstResponseTime"
            fill="#8884d8"
            name="Avg. First"
          />
          <Bar
            dataKey="avgOverallResponseTime"
            fill="#82ca9d"
            name="Avg. All"
          />
        </BarChart>

        <h1 className="text-2xl font-bold mb-2">Campaigns</h1>
        <div className="text-sm mb-8">
          This chart visualizes the performance of our campaigns, including the
          number of successful and failed deliveries
        </div>

        <LineChart
          width={1000}
          height={200}
          data={campaigns}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="createdAt" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="succeeded" stroke="#82ca9d" />
          <Line type="monotone" dataKey="failed" stroke="#de3769" />
        </LineChart>
      </div>
    </>
  )
}
