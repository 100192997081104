import {
  useGetConversationQuery,
  useSendMediaMutation,
  useSendMessageMutation,
} from 'app/features/whatsapp'
import { prepareMediaFormData } from 'lib/utils'
import React, { useEffect } from 'react'
import WhatsappChatList from '../chat-list/whats-app'
import { useLocation } from 'react-router-dom'

interface ChatProps {
  selectedConversationId: string
  setConversationLeads: any
  selectedLead: any
  setSelectedLead: any
  lastReply: any
  initialLead: any
  isDetailsPage: any
  metaConversationId: any
}

export default function WhatsApp({
  selectedConversationId,
  setConversationLeads,
  selectedLead,
  setSelectedLead,
  lastReply,
  initialLead,
  isDetailsPage,
  metaConversationId,
}: ChatProps) {
  const [messagesState, setMessages] = React.useState<any[]>([])
  const [sendMessage] = useSendMessageMutation()
  const [sendMedia] = useSendMediaMutation()
  const {
    data: conversation,
    isLoading,
    isFetching,
  } = useGetConversationQuery(selectedConversationId)

  useEffect(() => {
    if (conversation) {
      setConversationLeads(conversation?.leads)
      const isSelectedLead = selectedLead
        ? conversation?.leads?.find((item: any) => item.id == selectedLead.id)
        : null
      if (isSelectedLead) setSelectedLead(isSelectedLead)
      else setSelectedLead(conversation?.leads[0])
    }
  }, [conversation])

  const sendMessageClick = (newMessage: any) => {
    sendMessage({
      text: newMessage,
      messaging_product: 'whatsapp',
      to: conversation?.phoneNumber,
    })
    setMessages([...messagesState, newMessage])
  }

  const sendMediaClick = (newMessage: any) => {
    const data = prepareMediaFormData({
      my_file: newMessage.file,
      type: newMessage.type,
      to: conversation?.phoneNumber || '',
    })
    sendMedia(data)
    setMessages([...messagesState, newMessage])
  }

  const sendTemplateClick = (template: any) => {
    // sendTemplateFunction({
    //   template: template,
    //   messaging_product: 'whatsapp',
    //   to: conversation?.phoneNumber,
    // })
    setMessages([...messagesState, template])
  }

  return (
    <WhatsappChatList
      messages={conversation?.messages}
      phoneNumber={conversation?.phoneNumber}
      selectedLeadId={selectedLead?.id}
      selectedLead={selectedLead}
      conversationLeads={conversation?.leads}
      setSelectedLead={setSelectedLead}
      tags={conversation?.tags}
      selectedConversationId={conversation?.id}
      selectedConversationName={conversation?.name}
      lastReply={lastReply}
      sendMessage={sendMessageClick}
      sendMedia={sendMediaClick}
      sendTemplate={sendTemplateClick}
      isFetching={isFetching}
      isLoading={isLoading}
      initialLead={initialLead}
      isDetailsPage={isDetailsPage}
      metaConversationId={metaConversationId}
    />
  )
}
