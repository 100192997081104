import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

type MatchHeadersMap = Map<string, string>

type FirstColumnProps = {
  onMatch: (firstItem: string, secondItem: string) => void
  matcher: MatchHeadersMap
  firstHeadersData: string[]
  secondHeadersData: string[]
}

export const MatchHeaders: React.FC<FirstColumnProps> = ({
  onMatch,
  matcher,
  firstHeadersData,
  secondHeadersData,
}) => {
  const handleSelect = (firstItem: string) => {
    const remainingItems = secondHeadersData.filter(
      (item) => !new Array(...matcher.values()).includes(item)
    )

    return (
      <SelectMatcher
        matcher={matcher}
        label={firstItem}
        name={firstItem}
        required={true}
        list={secondHeadersData}
        setValue={(v: string) => {
          onMatch(firstItem, v)
        }}
        value={matcher.get(firstItem)}
      />
    )
  }

  return (
    <div className="flex flex-wrap  gap-x-10 gap-y-2 ">
      {firstHeadersData.map((item) => (
        <div className="w-1/3 max-md:w-full" key={item}>
          {handleSelect(item)}
        </div>
      ))}
    </div>
  )
}

const SelectMatcher = ({
  matcher,
  label,
  name,
  required,
  list,
  value,
  setValue,
}: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && label != 'email' && (
          <span className="ml-1 text-rose-500">*</span>
        )}
      </Label>
      <Select value={value} onValueChange={setValue}>
        <SelectTrigger>
          <SelectValue placeholder={'select matcher'} />
        </SelectTrigger>
        <SelectContent>
          {list.map((item: any) => (
            <SelectItem
              key={item}
              value={item}
              disabled={new Array(...matcher?.values()).includes(item)}
            >
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
