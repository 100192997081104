import { useGetBranchesQuery } from 'app/features/branch'
import { useGetDepartmentsQuery } from 'app/features/department'
import {
  useAddSpecialistMutation,
  useGetSpecialistsQuery,
  useUpdateSpecialistMutation,
} from 'app/features/specialist'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useEffect, useState } from 'react'
import { TbEdit, TbCheck } from 'react-icons/tb'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { cn } from 'lib/utils'
import { ScrollArea } from 'components/ui/scroll-area'

export default function Specialists() {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')

  const [selectedSpecialist, setSelectedSpecialist] = useState<any>(null)
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null)
  const [selectedBranch, setSelectedBranch] = useState<any>(null)

  const [mode, setMode] = useState('add')

  const {
    data: specialists,
    isError: specialistError,
    isLoading: dataLoading,
  } = useGetSpecialistsQuery()
  const [addSpecialist, { isLoading: createLoading, isError: createError }] =
    useAddSpecialistMutation()
  const [updateSpecialist, { isLoading: updateLoading, isError: updateError }] =
    useUpdateSpecialistMutation()
  const {
    data: branches,
    isError: branchesIsError,
    isLoading: branchesIsLoading,
  } = useGetBranchesQuery()
  const {
    data: departments,
    isError: departmentsIsError,
    isLoading: departmentsIsLoading,
  } = useGetDepartmentsQuery()

  const isLoading =
    dataLoading ||
    createLoading ||
    updateLoading ||
    branchesIsLoading ||
    departmentsIsLoading

  const isError = branchesIsError || departmentsIsError || specialistError
  useEffect(() => {
    if (createError || updateError) setError('Failed to add specialist')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setSelectedDepartment(null)
      setSelectedBranch(null)
      setSelectedSpecialist(null)
      setError('')
    }
  }, [mode])

  const handleSpecialist = async (e: any) => {
    e.preventDefault()
    setError('')
    if (name === '' || !selectedDepartment || !selectedBranch)
      return setError('Name, department, and  branch are required')
    if (mode === 'add')
      await addSpecialist({
        name,
        departmentId: selectedDepartment.id,
        branchId: selectedBranch.id,
      })
    else
      await updateSpecialist({
        id: selectedSpecialist.id,
        data: {
          name,
          departmentId: selectedDepartment.id,
          branchId: selectedBranch.id,
        },
      })
    setName('')
    setSelectedDepartment(null)
    setSelectedBranch(null)
    setSelectedSpecialist(null)
    setMode('add')
  }

  const update = async (status: boolean) => {
    await updateSpecialist({
      id: selectedSpecialist.id,
      data: {
        name,
        departmentId: selectedDepartment.id,
        branchId: selectedBranch.id,
        disabled: status,
      },
    })
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Specialists</h1>
      <p className="text-xs text-gray-500">
        Create specialists that will be used to categorize the leads
      </p>
      {/* Form */}
      {((branches && branches.length < 1) ||
        (departments && departments.length < 1)) && (
        <p className="my-3 text-xs font-medium text-rose-600">
          Please add a branch and a department first
        </p>
      )}
      {branches &&
        branches.length > 0 &&
        departments &&
        departments.length > 0 && (
          <form
            onSubmit={handleSpecialist}
            className="mt-5 flex items-center gap-3 max-md:flex-col"
          >
            <Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              type="text"
              placeholder="Specialist Name"
              disabled={isLoading || createLoading}
            />

            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between"
                >
                  {selectedDepartment && selectedBranch
                    ? selectedDepartment?.name +
                      ' ' +
                      selectedBranch?.name +
                      ' ' +
                      selectedBranch?.location
                    : 'Select Department'}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[400px] p-0">
                <Command className="overflow-y-auto ">
                  <CommandInput
                    placeholder="Search departments..."
                    className="h-9"
                  />
                  <CommandEmpty>No department found.</CommandEmpty>
                  <CommandGroup>
                    <ScrollArea className="h-52 w-full">
                      {departments.map((department) => (
                        <CommandItem
                          key={department.id}
                          value={department.id}
                          onSelect={(currentValue) => {
                            setSelectedDepartment(department)
                            setSelectedBranch(department?.branch)
                            setOpen(false)
                          }}
                        >
                          {department.name} - {department.branch?.name} -{' '}
                          {department.branch?.location}
                          <TbCheck
                            className={cn(
                              'ml-auto h-4 w-4',
                              selectedDepartment?.id === department.id
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                        </CommandItem>
                      ))}
                    </ScrollArea>
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>

            <Button
              disabled={isLoading || createLoading}
              size="sm"
              type="submit"
            >
              {mode === 'add' ? 'Add Specialist' : 'Update Specialist'}
            </Button>
            {mode === 'update' && (
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setMode('add')}
                >
                  Cancel update
                </Button>
                {selectedSpecialist.disabled ? (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => update(false)}
                  >
                    Enable
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => update(true)}
                  >
                    Disable
                  </Button>
                )}
              </>
            )}
          </form>
        )}
      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3 min-h-[100px] max-h-[300px] overflow-y-scroll border rounded-md p-2 ">
        {specialists?.length == 0 && (
          <div className="flex items-center justify-center p-10 w-full">
            No specialists added
          </div>
        )}
        {specialists?.map((specialist: any) => (
          <div
            key={specialist.id}
            className="flex  items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
          >
            <div className="flex items-center gap-3">
              <div>{specialist.name}</div>
              {'-'}
              <div>{specialist.department?.name}</div>
              {'-'}
              <div>{specialist.branch?.name}</div>
              {'-'}
              <div>{specialist.branch?.location}</div>
            </div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setName(specialist.name)
                  setSelectedBranch(specialist.branch)
                  setSelectedDepartment(specialist.department)
                  setSelectedSpecialist(specialist)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
