import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import PhoneInput from 'components/shared/phone-input'
import { CustomTextArea } from 'components/shared/custom-text-area'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import CustomNationalityInput from 'components/shared/nationality-input'
import useApp from 'hooks/useApp'
import { Label } from 'components/ui/label'
import { Checkbox } from 'components/ui/checkbox'
import { useEffect, useState } from 'react'
import { Button } from 'components/ui/button'
import { isEmail, isPhone } from 'lib/validation'
import { useAddLeadMutation, useUpdateLeadMutation } from 'app/features/lead'
import { useSelector } from 'react-redux'
import { Checkmark } from 'react-checkmark'
import ContactActions from './contact-actions'
import { convertToTitleCase } from 'lib/utils'

export default function ContactInfo({
  initialLead,
  isDetailsPage,
  metaConversationId,
  displayActions,
}: any) {
  const { sources } = useApp()
  const [checkedWhatsappAsPhone, setCheckedWhatsappAsPhone] = useState(false)
  const [error, setError] = useState<string>('')

  const { user } = useSelector((state: any) => state.user)

  const [addLead] = useAddLeadMutation()
  const [updateLead] = useUpdateLeadMutation()

  const [data, setData] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    alternativePhone: '',
    whatsappNumber: '',
    gender: '',
    nationality: '',
    sourceId: '',
    interest: '',
  })

  const [showTick, setShowTick] = useState(false)

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  useEffect(() => {
    setData({
      ...initialLead,
      firstName: initialLead.firstName || '',
      lastName: initialLead.lastName || '',
      email: initialLead.email || '',
      phone: initialLead.phone || '',
      alternativePhone: initialLead.alternativePhone || '',
      whatsappNumber: initialLead.whatsappNumber || '',
      nationality: initialLead.nationality || '',
      sourceId: initialLead.sourceId || '',
      interest: initialLead.interest || '',
    })
  }, [initialLead])

  async function createLead() {
    setError('')
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }
    if (data.alternativePhone && !isPhone(data.alternativePhone)) {
      return setError('Valid Alternative phone is required')
    }
    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid whatsapp number is required')
    }

    if (data.sourceId === '') {
      return setError('Source is required')
    }

    const result: any = await addLead({
      ...data,
      status: 'NEW',
      userId: user.id,
      metaConversationId: metaConversationId,
    })
    if (result?.error?.status === 409) {
      return setError('Lead already exists')
    }
    setShowTick(true)
  }

  async function editLead() {
    console.log(data)
    setError('')
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }
    if (data.alternativePhone && !isPhone(data.alternativePhone)) {
      return setError('Valid Alternative phone is required')
    }
    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid whatsapp number is required')
    }
    if (data.sourceId === '') {
      return setError('Source is required')
    }

    const result: any = await updateLead({
      id: data.id,
      data,
    })
    if (result?.error?.status === 409) {
      return setError('Lead already exists')
    }
    if (result?.error) {
      return setError('Server Error, Lead Not Updated')
    }
    setShowTick(true)
  }

  useEffect(() => {
    if (showTick) {
      setShowTick(true)

      // Hide the component after 3 seconds
      const timeout = setTimeout(() => {
        setShowTick(false)
      }, 3000)

      return () => clearTimeout(timeout)
    }
  }, [showTick])

  return (
    <div className=" p-3 overflow-auto border rounded-xl">
      <div className="flex flex-col gap-3">
        <div className=" items-center gap-3">
          <div className="flex  justify-between items-center gap-1  mb-3">
            {!isDetailsPage ? (
              <h1 className="text-lg font-medium ">Create lead</h1>
            ) : (
              <h1 className="text-lg font-medium ">Update lead</h1>
            )}

            <div className="flex items-center ">
              <span className="px-1 text-xs rounded-md bg-gray-200 ">
                {data.isSentToInsurance && !data.isSendToInsuranceEnded
                  ? 'Insurance'
                  : convertToTitleCase(data.status)}
              </span>
              {displayActions && data?.id && (
                <ContactActions leadId={data.id} />
              )}
            </div>
          </div>

          {error !== '' && <p className="text-red-500 text-xs">{error}</p>}
          <div className="w-full space-y-3 px-1 ">
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <CustomInput
                name="firstName"
                label="First Name"
                value={data.firstName}
                setValue={handleChange}
                type="text"
                required
                disabled={isDetailsPage}
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <CustomInput
                name="lastName"
                label="Last Name"
                value={data.lastName}
                setValue={handleChange}
                type="text"
                disabled={isDetailsPage}
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <CustomInput
                name="email"
                label="Email"
                value={data.email}
                setValue={handleChange}
                type="email"
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <PhoneInput
                label="Phone Number"
                id="phone"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    phone: value,
                    ...(checkedWhatsappAsPhone && {
                      whatsappNumber: value,
                    }),
                  })
                }
                value={data.phone}
                required
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <div className="flex w-full pt-6 items-center space-x-2 ">
                <Checkbox
                  id="whatsapp"
                  checked={checkedWhatsappAsPhone}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setData({
                        ...data,
                        whatsappNumber: data.phone,
                      })
                    } else {
                      setData({
                        ...data,
                        whatsappNumber: '',
                      })
                    }
                    setCheckedWhatsappAsPhone((prev) => !prev)
                  }}
                />
                <label
                  htmlFor="whatsapp"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Use phone number
                </label>
              </div>
            </div>
            <div className="w-full flex items-center gap-3 ">
              <PhoneInput
                label="Whatsapp Number"
                id="whatsappNumber"
                className="w-full"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    whatsappNumber: value,
                  })
                }
                value={data.whatsappNumber}
                required
                disabled={checkedWhatsappAsPhone}
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <PhoneInput
                label="Phone Number2"
                id="alternativePhone"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    alternativePhone: value,
                  })
                }
                value={data.alternativePhone}
                disabled={isDetailsPage}
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <div className="w-full space-y-2">
                <Label>Gender</Label>
                <Select
                  value={data.gender}
                  onValueChange={(value: any) =>
                    setData({
                      ...data,
                      gender: value,
                    })
                  }
                >
                  <SelectTrigger>
                    <SelectValue>{data.gender}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="male">Male</SelectItem>
                      <SelectItem value="female">Female</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <CustomNationalityInput
                label="Nationality"
                id="nationality"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    nationality: value,
                  })
                }
                value={data.nationality}
                required
              />
            </div>
            <div className="w-full flex items-center gap-3 max-md:flex-col ">
              <CustomSelect
                label="Source"
                list={sources}
                value={data.sourceId}
                setValue={(value: any) =>
                  setData({
                    ...data,
                    sourceId: value,
                  })
                }
                required
              />
            </div>

            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <CustomTextArea
                label="Interest"
                name="interest"
                value={data.interest}
                setValue={handleChange}
                type="text"
              />
            </div>

            {/*
            //TODO: to be deleted
            <div className="w-full flex items-center gap-3 py-3">
              <Label
                htmlFor="is-insurance-checked"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Insurance Patient
              </Label>
              <Checkbox
                id="is-insurance-checked"
                checked={data?.isSentToInsurance}
                disabled={
                  isDetailsPage ||
                  (data?.insuranceClaims?.length &&
                    data?.insuranceClaims[data.insuranceClaims.length - 1]
                      .status === 'Approved')
                }
                onCheckedChange={(checked) =>
                  setData((prev: any) => {
                    return {
                      ...prev,
                      isSentToInsurance: !prev.isSentToInsurance,
                    }
                  })
                }
              />
            </div> */}
            <div className="w-full flex items-center gap-3 max-md:flex-col">
              <div className="w-full flex items-center gap-3 py-3">
                {!isDetailsPage ? (
                  <div className="pr-5 text-right">
                    <Button onClick={createLead}>Create</Button>
                  </div>
                ) : (
                  <div className="pr-5 text-right">
                    <Button onClick={editLead}>Update</Button>
                  </div>
                )}
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'green',
                    color: 'white',
                    display: showTick ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '24px',
                    opacity: showTick ? 1 : 0,
                    transition: 'opacity 0.5s',
                    animation: showTick ? 'scaleInOut 1s forwards' : 'none',
                  }}
                >
                  {showTick && <Checkmark size="small" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
