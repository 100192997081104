import * as React from 'react'

import { cn } from 'lib/utils'
import { TbEyeOff } from 'react-icons/tb'
import { MdEmail } from 'react-icons/md'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const EmailInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <div className="relative shadow-custom-blue">
        <input
          type={type}
          className={cn(
            'flex h-9  w-full rounded-md border border-gray-200 bg-transparent px-3 pl-10 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-customBlue disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300',
            className
          )}
          ref={ref}
          {...props}
        />
        <div className="absolute left-3 top-2 cursor-pointer text-customBlue">
          <MdEmail size={22} />
        </div>
      </div>
    )
  }
)
EmailInput.displayName = 'Input'

export { EmailInput }
