import useApp from 'hooks/useApp'
import { tabs, tabsNavigation } from 'pages/settings/active-tabs'

export const shouldClaimInsurance = (
  lead: any,
  numberOfInsuranceClaims: any
) => {
  if (!lead.isSentToInsurance || lead.isSendToInsuranceEnded) return false
  return true
}

export function shouldFollowUp(
  lead: any,
  lastFollowUp: any,
  numberOfFollowUps: number,
  timeGap: number,
  numberOfInsuranceClaims: number
) {
  if (lead?.followUps?.length >= numberOfFollowUps) return false
  if (shouldClaimInsurance(lead, numberOfInsuranceClaims)) return false
  if (['BOOKED', 'NOT_QUALIFIED', 'SHOW', 'NO_SHOW'].includes(lead?.status))
    return false
  if (lead?.status === 'NEW') return true

  if (lastFollowUp) {
    const lastFollowUpDate = new Date(lastFollowUp?.date)
    const { nextFollowUpTimeGap } = lastFollowUp
    const today = new Date()
    if (nextFollowUpTimeGap) {
      if (nextFollowUpTimeGap > 0) {
        const timeDiff = Math.abs(today.getTime() - lastFollowUpDate.getTime())
        // convert timeDiff to seconds
        const timeDiffInSeconds = timeDiff / 1000
        return timeDiffInSeconds > nextFollowUpTimeGap
      }
    } else {
      const timeDiff = Math.abs(today.getTime() - lastFollowUpDate.getTime())
      // convert timeDiff to seconds
      const timeDiffInSeconds = timeDiff / 1000
      return timeDiffInSeconds > timeGap
    }
  }
  return true
}

export const shouldBook = (lead: any, numberOfInsuranceClaims: any) => {
  return (
    !shouldClaimInsurance(lead, numberOfInsuranceClaims) &&
    lead.status != 'BOOKED'
  )
}

export const shouldShow = (lead: any) => {}

export const shouldNotQualified = (lead: any, numberOfInsuranceClaims: any) => {
  return (
    lead?.status != 'NOT_QUALIFIED' &&
    !shouldClaimInsurance(lead, numberOfInsuranceClaims)
  )
}

const hasUserAccess = (user: any, tab: string) => {
  const { activeTabs: settingsActiveTabs } = useApp()
  if (
    !settingsActiveTabs ||
    !settingsActiveTabs.length ||
    settingsActiveTabs.length == 0
  )
    return false
  const activeTabs = settingsActiveTabs[0].activeTabs ?? []

  return (
    (user?.role == 'ADMIN' ||
      user.role === 'SUPERADMIN' ||
      user?.group?.tabs?.includes(tab)) &&
    activeTabs?.includes(tab)
  )
}

export const hasFollowUpAccess = (user: any) => {
  if (!hasUserAccess(user, 'Dashboard') && !hasUserAccess(user, 'Leads'))
    return false
  return true
}
export const hasWhatsappAccess = (user: any) => {
  if (!hasUserAccess(user, 'Social')) return false
  return true
}
export const hasMetaAccess = (user: any) => {
  if (!hasUserAccess(user, 'Social')) return false
  return true
}
export const hasInsuranceAccess = (user: any) => {
  if (
    !hasUserAccess(user, 'Insurance') &&
    !hasUserAccess(user, 'Insurance_Portal')
  )
    return false
  return true
}

export const hasBookAccess = (user: any) => {
  if (
    !hasUserAccess(user, 'Booked') &&
    !hasUserAccess(user, 'Dashboard') &&
    !hasUserAccess(user, 'Leads')
  )
    return false
  return true
}

export const hasNotQualifiedAccess = (user: any) => {
  if (
    !hasUserAccess(user, 'Not_Qualified') &&
    !hasUserAccess(user, 'Dashboard') &&
    !hasUserAccess(user, 'Leads')
  )
    return false
  return true
}

export const accessibleTab = (
  tab: string,
  user: any,
  settingsActiveTabs: any
) => {
  return (
    (user?.role == 'ADMIN' ||
      user.role === 'SUPERADMIN' ||
      user?.group?.tabs?.includes(tab)) &&
    activeTab(tab, settingsActiveTabs)
  )
}

export const activeTab = (tab: string, settingsActiveTabs: any) => {
  if (
    !settingsActiveTabs ||
    !settingsActiveTabs.length ||
    settingsActiveTabs.length == 0
  )
    return false
  const activeTabs = settingsActiveTabs[0].activeTabs
  return activeTabs?.includes(tab)
}

export const navigateAdmins = (settingsActiveTabs: any) => {
  for (let i = 0; i < tabs.length; i++) {
    if (activeTab(tabs[i], settingsActiveTabs)) {
      if (tabsNavigation[tabs[i]] == '/agents-dashboard') return '/dashboard'
      return tabsNavigation[tabs[i]]
    }
  }
  return '/settings'
}

export const navigateAgents = (agent: any, settingsActiveTabs: any) => {
  const agentTabs = agent?.group?.tabs
  for (let i = 0; i < tabs.length; i++) {
    if (agentTabs?.includes(tabs[i]) && activeTab(tabs[i], settingsActiveTabs))
      return tabsNavigation[tabs[i]]
  }
  return '/no-access'
}
