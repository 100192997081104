import { useState } from 'react'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { TbEye, TbEyeOff, TbLock } from 'react-icons/tb'
export default function PasswordInput({
  password,
  label,
  setPassword,
  disabled,
  custom,
}: any) {
  const [show, setShow] = useState(false)
  return (
    <div className={`${!custom ? 'space-y-2' : ''} relative`}>
      {!custom && <Label>{label}</Label>}
      <div className={`w-full ${custom ? ' shadow-custom-blue' : ''}`}>
        <Input
          type={show ? 'text' : 'password'}
          value={password}
          placeholder="Password"
          onChange={(e: any) => setPassword(e.target.value)}
          required
          disabled={disabled}
          className={`${custom ? 'pl-10 focus-visible:ring-customBlue' : ''}`}
        />
      </div>
      {custom && (
        <div
          className={`absolute left-3  cursor-pointer text-customBlue  ${custom ? 'top-2' : 'top-8'}`}
        >
          <TbLock size={22} />
        </div>
      )}

      <div
        className={`absolute right-3 cursor-pointer ${custom ? 'top-2' : 'top-8'}`}
        onClick={() => setShow(!show)}
      >
        {show ? <TbEyeOff size={18} /> : <TbEye size={18} />}
      </div>
    </div>
  )
}
