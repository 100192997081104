import { useGetAllAgentsNamesQuery, useGetUsersQuery } from 'app/features/users'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Pagination from 'components/table/pagination'
import { cn, formatDateAndTime } from 'lib/utils'
import {
  useApproveAuthDeviceRequestMutation,
  useDeleteAuthDeviceRequestMutation,
  useGetAuthDeviceRequestsQuery,
  useRejectAuthDeviceRequestMutation,
} from 'app/features/auth'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Label } from 'components/ui/label'

import { Button } from 'components/ui/button'
import { ApproveLoginReuquest } from './components/approve-login-request'
import { useToast } from 'components/ui/use-toast'
import { RejectLoginReuquest } from './components/reject-login-request.'
import { Input } from 'components/ui/input'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical } from 'react-icons/tb'
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5'

import { MdClose } from 'react-icons/md'
import { navigateAgents } from 'lib/allowed'
import useApp from 'hooks/useApp'
export default function AuthDevicesRequests() {
  const { activeTabs: settingsActiveTabs } = useApp()
  const { user } = useSelector((state: any) => state.user)
  const navigate = useNavigate()
  const { toast } = useToast()
  const [isApproved, setIsApproved] = useState('all')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [keyword, setKeyword] = useState('')
  const [openApprove, setOpenApprove] = useState(false)
  const [openReject, setOpenReject] = useState(false)
  const [rejectMode, setRejectMode] = useState<'reject' | 'delete'>('reject')
  const [logoutFromOtherDevices, setLogoutFromOtherDevices] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState()
  const [filters, setFilters] = useState<{
    skip: number
    take: number
    isApproved?: string
    userKeyword?: string
  }>({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    isApproved,
    userKeyword: '',
  })
  const { data, isLoading, isError, isFetching } =
    useGetAuthDeviceRequestsQuery({ ...filters }, { refetchOnFocus: true })

  const [approve, { isLoading: isApproveLoading }] =
    useApproveAuthDeviceRequestMutation()
  const [reject, { isLoading: isRejectLoading }] =
    useRejectAuthDeviceRequestMutation()

  const [deletee, { isLoading: isDeleteLoading }] =
    useDeleteAuthDeviceRequestMutation()

  useEffect(() => {
    if (user && user.role === 'AGENT') {
      navigate(navigateAgents(user, settingsActiveTabs))
    }
  }, [user])

  useEffect(() => {
    setFilters({
      ...filters,
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
      isApproved: isApproved,
      userKeyword: keyword,
    })
  }, [currentPage, isApproved, keyword, rowsPerPage])

  const approveHandler = async () => {
    const payload = (await approve({
      logoutFromOtherDevices,
      requestId: selectedRequest!,
    })) as any
    if (payload?.data) {
      toast({
        title: 'auth device apprroved',
        description: 'auth device approved successfully, agent can login now ',
      })
    } else if (payload?.error) {
      toast({
        title: 'Error occured',
        description: payload?.error?.data,
      })
    }

    setOpenApprove(false)
  }
  const rejectHandler = async () => {
    if (rejectMode == 'reject') {
      const payload = (await reject({
        requestId: selectedRequest!,
      })) as any
      if (payload?.data) {
        toast({
          title: 'auth device rejected',
          description: 'auth device rejected successfully ',
        })
      } else if (payload?.error) {
        toast({
          title: 'Error occured',
          description: payload?.error?.data,
        })
      }
    } else {
      const payload = (await deletee({
        requestId: selectedRequest!,
      })) as any
      if (payload?.data) {
        toast({
          title: 'auth device deleted',
          description: 'auth device deleted successfully ',
        })
      } else if (payload?.error) {
        toast({
          title: 'Error occured',
          description: payload?.error?.data,
        })
      }
    }
    setOpenReject(false)
  }
  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="p-3 w-full space-y-3 h-screen max-w-7xl overflow-scroll ">
        <div className="flex items-center  gap-2 justify-start mt-12">
          <div className="space-y-2 flex flex-col">
            <Input
              type="text"
              placeholder="Search"
              value={keyword}
              className="bg-white"
              onChange={(e: any) => setKeyword(e.target.value)}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <Select
              value={isApproved}
              onValueChange={(value: any) => setIsApproved(value)}
            >
              <SelectTrigger className="w-32 ">
                <SelectValue>
                  {isApproved?.slice(0, 1).toUpperCase() +
                    isApproved?.slice(1, isApproved?.length)}
                </SelectValue>
              </SelectTrigger>
              <SelectContent className=" w-40  ">
                <SelectGroup>
                  <SelectItem value={'approved'}>Approved</SelectItem>
                  <SelectItem value={'pending'}>Pending</SelectItem>
                  <SelectItem value={'rejected'}>Rejected</SelectItem>
                  {/* <SelectItem value={'removed'}>Removed</SelectItem> */}
                  <SelectItem value="all">All</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="border rounded-md bg-white overflow-hidden overflow-x-scroll ">
          <table
            className={cn(
              isFetching ? 'text-gray-400' : 'text-gray-800',
              'text-sm w-full border-collapse table-fixed'
            )}
          >
            <thead>
              <tr className="text-left border-b">
                <th className="xl:w-40">Name</th>
                <th className="xl:w-52">Email</th>
                {/* <th>Device ID</th> */}
                <th className="xl:w-52">Date</th>
                <th className="xl:w-40 whitespace-normal">Actioned By </th>
                <th className="xl:w-52">Status</th>
                <th className="xl:w-28">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.requests.map((request: any) => {
                if (!request.isRemoved)
                  return (
                    <tr key={request.id} className="border-b last:border-b-0">
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {request?.user?.firstName} {request?.user?.lastName}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {request?.user?.email}
                      </td>
                      {/* <td>{request?.ip}</td> */}
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {formatDateAndTime(request?.createdAt)}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {request?.admin?.firstName} {request?.admin?.lastName}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {request.isApproved &&
                        request.isLogoutFromOtherDevices ? (
                          <span className="rounded bg-green-200 w-56  py-1 px-2 text-xs text-green-800">
                            Approved / logout from other devices
                          </span>
                        ) : request.isApproved ? (
                          <span className="rounded bg-green-200 w-20  py-1 px-2 text-xs text-green-800">
                            Approved
                          </span>
                        ) : request.isRejected ? (
                          <span className="rounded bg-red-500 w-20  py-1 px-2 text-xs text-gray-800">
                            Rejected
                          </span>
                        ) : request.isRemoved ? (
                          <span className="rounded bg-red-600 w-20  py-1 px-2 text-xs text-gray-800">
                            Removed
                          </span>
                        ) : (
                          <span className="rounded bg-orange-500 w-20  py-1 px-2 text-xs text-gray-800">
                            Pending
                          </span>
                        )}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {!request?.isApproved &&
                          !request.isRejected &&
                          !request.isRemoved && (
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  variant="ghost"
                                  className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
                                >
                                  <TbDotsVertical size={18} />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent
                                align="end"
                                className="w-[260px]"
                              >
                                <DropdownMenuItem
                                  className="hover:cursor-pointer"
                                  onClick={() => {
                                    setOpenApprove(true)
                                    setLogoutFromOtherDevices(false)
                                    setSelectedRequest(request.id)
                                  }}
                                  disabled={isApproveLoading || isRejectLoading}
                                >
                                  <div className="flex gap-3 items-center ">
                                    <IoCheckmark
                                      size={15}
                                      className=" text-green-500"
                                    />
                                    <span>Approve</span>
                                  </div>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="hover:cursor-pointer"
                                  onClick={() => {
                                    setOpenApprove(true)
                                    setLogoutFromOtherDevices(true)
                                    setSelectedRequest(request.id)
                                  }}
                                  disabled={isApproveLoading || isRejectLoading}
                                >
                                  <div className="flex gap-3 items-center ">
                                    <IoCheckmarkDone
                                      size={15}
                                      className=" text-green-500"
                                    />
                                    <span>
                                      {' '}
                                      Approve and logout from other devices
                                    </span>
                                  </div>
                                </DropdownMenuItem>

                                <DropdownMenuItem
                                  className="hover:cursor-pointer"
                                  onClick={() => {
                                    setRejectMode('reject')
                                    setOpenReject(true)
                                    setSelectedRequest(request.id)
                                  }}
                                  disabled={isApproveLoading || isRejectLoading}
                                >
                                  <div className="flex gap-3 items-center ">
                                    <MdClose
                                      size={15}
                                      className=" text-red-500"
                                    />
                                    <span> Reject</span>
                                  </div>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}
                      </td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
        <div>
          {data?.count > 0 && (
            <Pagination
              count={data?.count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      </div>
      <ApproveLoginReuquest
        open={openApprove}
        setOpen={setOpenApprove}
        logoutFromOtherDevices={logoutFromOtherDevices}
        handleApprove={approveHandler}
        isLoading={isApproveLoading}
      />
      <RejectLoginReuquest
        open={openReject}
        setOpen={setOpenReject}
        handleApprove={rejectHandler}
        isLoading={isRejectLoading}
        rejectMode={rejectMode}
      />
    </>
  )
}
