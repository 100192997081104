import { Page, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  body: {
    margin: 0,
    padding: 20,
    marginBottom: 40,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

export default function CustomPage({ children }: any) {
  return (
    <Page size="A4" style={styles.body}>
      {children}
    </Page>
  )
}
