import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from 'components/ui/menubar'
import { IoFileTray, IoShieldCheckmarkSharp } from 'react-icons/io5'
import {
  TbTextPlus,
  TbClipboardList,
  TbCircleDashedX,
  TbDotsVertical,
  TbBrandWhatsapp,
} from 'react-icons/tb'
import { MdEdit } from 'react-icons/md'
import {
  shouldFollowUp,
  shouldBook,
  shouldClaimInsurance,
  shouldNotQualified,
  hasFollowUpAccess,
  hasBookAccess,
  hasNotQualifiedAccess,
  hasInsuranceAccess,
  hasWhatsappAccess,
} from 'lib/allowed'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { LeadInfoReport } from './lead-info-report'
import { VscFilePdf } from 'react-icons/vsc'
import { Button } from 'components/ui/button'

export const Actions = ({
  lead,
  isDetailsPage,
  isLoading,
  id,
  navigate,
  user,
  settings,
  duplicateLead,
  isDuplicateLoading,
  setOpenStatus,
  setOpenBook,
  numberOfFollowUps,
  setOpenFollowUp,
  timeGap,
  numberOfInsuranceClaims,
  setOpenUpdateBook,
  setOpenClaimInsurance,
  setOpenNotQualified,
  setOpenSendToInsurance,
  setOpenEndInsurance,
  setOpenAttachFile,
  startMessagingViaWhatsApp,
}: any) => {
  return (
    <Menubar className="  border-none h-10 w-8  z-10   ">
      <MenubarMenu>
        <MenubarTrigger
          className="disabled:text-gray-300 hover:cursor-pointer      p-0    disabled={!isDetailsPage}
 "
        >
          <Button
            variant="ghost"
            className="flex h-8 w-8   p-0 data-[state=open]:bg-muted relative"
          >
            <TbDotsVertical size={18} />
          </Button>
        </MenubarTrigger>
        {lead && isDetailsPage && !isLoading && (
          <MenubarContent className="bg-white border   w-32">
            <MenubarItem
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              onClick={() => navigate(`/update-lead/${id}`)}
            >
              Edit
              <MenubarShortcut>
                <MdEdit size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            {(user.role == 'ADMIN' || user.role == 'SUPERADMIN') && (
              <PDFDownloadLink
                document={
                  <LeadInfoReport
                    lead={lead}
                    clinicDescription={settings ? settings[0]?.description : ''}
                  />
                }
                fileName="report.pdf"
              >
                {({ loading }) =>
                  true && (
                    <div className="hover:cursor-pointer hover:bg-gray-100 font-medium">
                      <div className="flex items-center  justify-between w-full   relative cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                        Lead journey
                        <MenubarShortcut>
                          <VscFilePdf size={15} className="text-gray-700" />
                        </MenubarShortcut>
                      </div>
                    </div>
                  )
                }
              </PDFDownloadLink>
            )}
            <MenubarItem
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              onClick={duplicateLead}
              disabled={isDuplicateLoading}
            >
              Duplicate
              <MenubarShortcut>
                <HiOutlineDuplicate size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            {hasBookAccess(user) && (
              <>
                {lead?.status != 'BOOKED' ? (
                  <>
                    <MenubarItem
                      className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                      onClick={() => setOpenBook(true)}
                      disabled={!shouldBook(lead, numberOfInsuranceClaims)}
                    >
                      Book
                      <MenubarShortcut>
                        <TbTextPlus className="text-gray-500" size={15} />
                      </MenubarShortcut>
                    </MenubarItem>
                  </>
                ) : (
                  <>
                    <MenubarItem
                      className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                      onClick={() => setOpenStatus(true)}
                    >
                      Update Status
                      <MenubarShortcut>
                        <TbTextPlus className="text-gray-500" size={15} />
                      </MenubarShortcut>
                    </MenubarItem>
                    <MenubarItem
                      className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                      onClick={() => setOpenUpdateBook(true)}
                    >
                      Update Book
                      <MenubarShortcut>
                        <TbTextPlus className="text-gray-500" size={15} />
                      </MenubarShortcut>
                    </MenubarItem>
                  </>
                )}
              </>
            )}
            {hasFollowUpAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenFollowUp(true)}
                disabled={
                  !shouldFollowUp(
                    lead,
                    lead?.followUps[lead.followUps.length - 1],
                    numberOfFollowUps,
                    timeGap,
                    numberOfInsuranceClaims
                  )
                }
              >
                Follow Up
                <MenubarShortcut>
                  {' '}
                  <TbClipboardList className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}{' '}
            {hasNotQualifiedAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenNotQualified(true)}
                disabled={!shouldNotQualified(lead, numberOfInsuranceClaims)}
              >
                Not qualified
                <MenubarShortcut>
                  <TbCircleDashedX className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {hasInsuranceAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenClaimInsurance(true)}
                disabled={!shouldClaimInsurance(lead, numberOfInsuranceClaims)}
              >
                Insurance Claim
                <MenubarShortcut>
                  <IoShieldCheckmarkSharp className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {/**TODO: refactor authorization and disabled */}
            {hasInsuranceAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenSendToInsurance(true)}
                disabled={
                  lead?.insuranceType == 'Cash' ||
                  (lead?.isSentToInsurance && !lead?.isSendToInsuranceEnded)
                }
              >
                Send to insurance
                <MenubarShortcut>
                  <IoShieldCheckmarkSharp className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            {/**TODO: refactor authorization and disabled */}
            {hasInsuranceAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenEndInsurance(true)}
                disabled={
                  lead?.insuranceType == 'Cash' ||
                  !lead?.isSentToInsurance ||
                  (lead?.isSentToInsurance && lead?.isSendToInsuranceEnded)
                }
              >
                End insurance
                <MenubarShortcut>
                  <IoShieldCheckmarkSharp className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}
            <MenubarItem
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              onClick={() => setOpenAttachFile(true)}
            >
              Attach file
              <MenubarShortcut>
                <IoFileTray className="text-gray-500" size={15} />
              </MenubarShortcut>
            </MenubarItem>
            {hasWhatsappAccess(user) && (
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => startMessagingViaWhatsApp()}
              >
                Start messaging via WhatsApp
                <MenubarShortcut>
                  <TbBrandWhatsapp className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
            )}
          </MenubarContent>
        )}
      </MenubarMenu>
    </Menubar>
  )
}
