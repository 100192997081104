import dayjs from 'dayjs'
import { TbUserScan } from 'react-icons/tb'

export const Comment = ({ comment }: any) => {
  return (
    <article className="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
      <footer className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
            <TbUserScan
              size={20}
              className="group-hover:text-indigo-600 mr-2 rounded-full"
            />
            {comment.user?.firstName} {comment.user?.lastName}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            {dayjs(comment.createdAt).format('DD/MM/YYYY hh:mm A')}
          </p>
        </div>
      </footer>
      <p className="text-gray-500 dark:text-gray-400 ml-10">
        {comment.content}
      </p>
    </article>
  )
}
