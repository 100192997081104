import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { cn } from 'lib/utils'

export const SubscriptionWrapperOld = ({ children, isActive = true }: any) => {
  const { user } = useSelector((state: any) => state.user)

  return (
    <div className="w-full h-full">
      {user?.role == 'ADMIN' && (
        /* Render the subscription overlay Or Navigate if not subscribed */
        // <SubscriptionOverlay user={user} />
        <Navigate to="/settings" state={{ screen: 'subscriptions' }} />
      )}
      <div
        className={cn(user?.role == 'ADMIN' ? 'blur-sm' : '', 'w-full h-full')}
      >
        {user?.role != 'ADMIN' ? <>{children}</> : <></>}
      </div>
    </div>
  )
}

export const SubscriptionWrapper = ({ children, isActive = true }: any) => {
  return (
    <div className="w-full h-full">
      {false && (
        /* Render the subscription overlay Or Navigate if not subscribed */
        // <SubscriptionOverlay user={user} />
        <Navigate to="/settings" state={{ screen: 'subscriptions' }} />
      )}
      <div className={cn(false ? 'blur-sm' : '', 'w-full h-full')}>
        {true ? <>{children}</> : <></>}
      </div>
    </div>
  )
}
