import Contact from './contact'
import { SelectConversationLead } from './select-conversation-lead'

export const ContactSection = ({
  initialLead,
  isDetailsPage,
  metaConversationId,
  conversationLeads,
  selectedLead,
  setSelectedLead,
  displayActions = true,
}: any) => (
  <div className="flex flex-col gap-2">
    <SelectConversationLead
      conversationLeads={conversationLeads}
      setSelectedLead={setSelectedLead}
      selectedLead={selectedLead}
    />
    <Contact
      initialLead={initialLead}
      isDetailsPage={isDetailsPage}
      metaConversationId={metaConversationId}
      displayActions={displayActions}
    />
  </div>
)
