import { AppProvider } from 'hooks/useApp'
import { SocketProvider } from 'hooks/useSocket'
import { ReportProvider } from 'hooks/useReport'

const combineComponents = (...components: any[]) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: { children: any }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }: { children: any }) => <>{children}</>
  )
}

const providers = [AppProvider, SocketProvider, ReportProvider]
export const Context = combineComponents(...providers)
