import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useEffect, useRef, useState } from 'react'
import Loader from 'components/shared/loader'
import { IoMdClose } from 'react-icons/io'
import { Input } from 'components/ui/input'
import { prepareMediaFormData } from 'lib/utils'
import { useAttachFileMutation } from 'app/features/lead'
import { CustomTextArea } from 'components/shared/custom-text-area'
import { useToast } from 'components/ui/use-toast'
import { v4 as uuidv4 } from 'uuid'

const MAX_FILE_SIZE = 30 * 1024 * 1024 // 30 MB
const CHUNK_SIZE = 300 * 1024 // 300 KB
const chunkFile = (file: File, chunkSize: number) => {
  const chunks = []
  let start = 0

  while (start < file.size) {
    const end = Math.min(start + chunkSize, file.size)
    const chunk = file.slice(start, end)
    chunks.push(chunk)
    start = end
  }

  return chunks
}

export default function AttachFile({ open, setOpen, lead }: any) {
  const { toast } = useToast()
  const [error, setError] = useState('')
  const fileRef = useRef<any>()
  const [attachment, setAttachment] = useState<any>()
  const [description, setDescription] = useState<any>()
  const [attachFile, { isLoading }] = useAttachFileMutation()
  const [isUploading, setIsUploading] = useState(false)

  const handleFileChange = async (event: any) => {
    event.preventDefault()

    try {
      const file = event.target.files[0]

      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          setAttachment(null)
          fileRef.current.value = null
          return setError('File size exceeds 30 MB limit.')
        } else {
          setError('')
          setAttachment(file)
        }
      }
    } catch (error) {
      setError('Invalid  file')
    }
  }

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setAttachment(null)
    setError('')
  }

  useEffect(() => {
    setError('')
    setAttachment(null)
    setDescription('')
  }, [open])

  async function attachHandler() {
    setError('')
    try {
      if (!attachment) {
        return setError('attachment required')
      }
      setIsUploading(true)
      const chunks = chunkFile(attachment, CHUNK_SIZE)
      const uuid = uuidv4()
      for (let i = 0; i < chunks.length; i++) {
        const body: any = {
          attachment: chunks[i],
          leadId: lead.id,
          chunkIndex: i,
          totalChunks: chunks.length,
          fileName: attachment.name,
          uuid,
        }
        if (description && description != '') body['description'] = description
        const formData = prepareMediaFormData(body)
        try {
          const result: any = await attachFile(formData)
          if (result?.error) {
            setIsUploading(false)
            return setError('Failed to attach file, please try again')
          }
        } catch (error) {
          setIsUploading(false)
          return setError('Failed to attach file, please try again')
        }
      }
      setIsUploading(false)
      setError('')
      toast({
        title: 'Attached Successfully',
        description: 'File attached successfully to the lead',
      })
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        {isUploading && (
          <div className="h-72  grid place-content-center">
            <Loader />
          </div>
        )}
        {!isUploading && (
          <>
            <AlertDialogHeader>
              <div className="flex items-center justify-between text-black">
                <h1 className="text-lg font-semibold">
                  {lead.firstName} {lead.lastName}
                </h1>
              </div>
              <p className="text-gray-500 text-xs">{lead.phone}</p>
              <p className="text-gray-500 text-xs">{lead.email}</p>
              {error !== '' && <p className="text-red-500 text-xs">{error}</p>}

              <div className="mt-5 items-center flex relative pt-4">
                <Input
                  type="file"
                  ref={fileRef}
                  className="cursor-pointer"
                  onChange={handleFileChange}
                />
                <IoMdClose
                  size={20}
                  className="text-gray-600 hover:cursor-pointer hover:text-gray-900 absolute right-2"
                  onClick={replaceFileHandler}
                />
              </div>
              <div className="w-full flex items-center gap-3 max-md:flex-col">
                <CustomTextArea
                  label="Description"
                  name="Description"
                  value={description}
                  setValue={(e: any) => setDescription(e.target.value)}
                  type="text"
                />
              </div>
            </AlertDialogHeader>

            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button onClick={attachHandler}>Submit</Button>
            </AlertDialogFooter>
            <div className="w-full border-t border-dashed" />
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
