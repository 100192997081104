import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
  Svg,
  StyleSheet,
} from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { formatDate } from 'lib/utils'

const styles = StyleSheet.create({
  page: { padding: 40, paddingTop: 90, position: 'relative' },
  mainHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 50,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '08B3E2',
  },
  mainInfo: {
    padding: 50,
    gap: 20,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 0,

    position: 'relative',
  },

  pdfTitle: {
    backgroundColor: 'white',
    color: '#08B3E2',
    fontSize: 20,
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
    paddingTop: 50,
    fontFamily: 'Helvetica',
  },
  chart: { padding: 5 },
  section: {
    margin: 30,
    padding: 10,
    flexGrow: 1,
  },

  name: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  bullet: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  bulletItem: {
    color: 'gray',
    fontSize: 10,
    marginLeft: 10, // Indent bullet points
    marginBottom: 3,
  },
  dateDetails: {
    color: 'gray',
    fontSize: 10,
    margin: 7,
    marginBottom: 3,
    marginTop: 3,
  },
  dateInfoContainer: {
    gap: 10,
    marginTop: 20,
    width: '100%',
    padding: 10,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: '#c8effa',
    fontFamily: 'Helvetica',
  },
  dateInfo: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },

  noLeads: {
    textAlign: 'center',
    color: '#6b7280', // gray-500
    fontSize: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // gray-100
    paddingBottom: 4,
    marginBottom: 4,
  },
  leadRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // gray-100
    paddingVertical: 4,
    justifyContent: 'space-between',
  },
  leadCell: {
    fontSize: 10,
    flexGrow: 1,
    textAlign: 'left',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 90,
  },

  clinicDescriptionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 20,
    textAlign: 'center',
    color: '#333',
  },
  logo: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 100, // Adjust as needed
    height: 'auto',
  },
  clinicLogo: {
    position: 'absolute',
    top: 0,
    left: 50,
    width: 80, // Adjust as needed
    height: 'auto',
  },
  clinicDescription: {
    marginTop: 15,
    fontSize: 12,
    lineHeight: 1.5,
    color: '#555',
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fff',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    textAlign: 'center',
    fontSize: 8,
    color: '#6b7280', // gray-500
  },

  container: {
    backgroundColor: '#f9fafb',
    padding: 15,
    paddingRight: 30,
    width: '100%',
    borderRadius: 5,
    marginBottom: 28,
    height: 205,
    border: '1px solid #e5e7eb',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#08B3E2',
    fontSize: 16,
    fontWeight: 'bold',
  },
  taskHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  status: {
    backgroundColor: '#08B3E2',
    color: 'white',
    padding: 4,
    borderRadius: 4,
    fontSize: 10,
    textAlign: 'center',
    width: 80,
  },
  description: {
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 12,
  },
  details: {
    fontSize: 10,
    marginLeft: 8,
  },
  taskDateDetails: {
    color: 'gray',
    fontSize: 7,
    marginLeft: 8,
  },
  assignee: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,

    fontSize: 7,
  },
  createdDetails: {
    fontSize: 8,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    color: '#475569', // text-slate-600
  },
})

const Header = ({ clinicLogoUrl }: any) => {
  return (
    <>
      <Image
        src={{
          uri: clinicLogoUrl,
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.clinicLogo}
      />

      <Image
        src={{
          uri: '/logo.jpeg',
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.logo}
      />
    </>
  )
}

const Footer = () => {
  return (
    <View style={styles.footer}>
      <Text>
        {`\u00A9`} Doctorna By Code Guru, Al Barsha Business Centre Dubai, UAE,
        Mobile: +971 58 696 4342 | admin@codeguru.ae | All Rights Reserved.
      </Text>
    </View>
  )
}
export const TasksReport = ({
  tasks,
  startDate,
  endDate,
  clinicDescription,
  status,
  assignee,
}: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />
        <View>
          <Text style={styles.pdfTitle}>Tasks Report</Text>
        </View>
        <View style={styles.mainInfo}>
          <View style={styles.dateInfoContainer}>
            <View>
              <View style={styles.dateInfo}>
                <Text style={{ fontSize: 10 }}>Generated At: </Text>
                <Text style={styles.dateDetails}>
                  {dayjs(new Date()).format('DD/MM/YYYY h:mm A')}
                </Text>
              </View>

              <View style={styles.dateInfo}>
                <Text style={{ fontSize: 10 }}>Date range: </Text>
                {startDate || endDate ? (
                  <>
                    {startDate && (
                      <Text style={styles.dateDetails}>
                        From: {dayjs(startDate).format('DD/MM/YYYY')}
                      </Text>
                    )}
                    {startDate && (
                      <Text style={styles.dateDetails}>
                        Until: {dayjs(endDate).format('DD/MM/YYYY')}
                      </Text>
                    )}
                  </>
                ) : (
                  <Text style={styles.dateDetails}>All Time</Text>
                )}
              </View>
              <View style={styles.dateInfo}>
                <Text style={{ fontSize: 10 }}> Status: </Text>
                <Text style={styles.dateDetails}>{status}</Text>
              </View>

              <View style={styles.dateInfo}>
                <Text style={{ fontSize: 10, marginBottom: 4 }}>
                  {' '}
                  Assignee:
                </Text>

                {assignee?.length == 0 ? (
                  <>
                    <Text
                      style={{
                        ...styles.bulletItem,
                      }}
                    >
                      • All
                    </Text>{' '}
                  </>
                ) : (
                  <>
                    {assignee.map((assign: any) => (
                      <Text style={styles.bulletItem} key={assign.id}>
                        • {assign.name}
                      </Text>
                    ))}
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        {tasks?.map((task: any) => {
          return (
            <View style={styles.container}>
              <View style={styles.taskHeader}>
                <Text style={styles.title}>{task.title}</Text>
                <Text style={styles.status}>
                  {task?.status.replace('_', ' ')}
                </Text>
              </View>
              <Text style={styles.description}>Description:</Text>
              <Text style={styles.details}>{task.details}</Text>
              <View style={styles.assignee}>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>Assignee:</Text>{' '}
                  {task?.assignee?.firstName} {task?.assignee?.lastName}
                </Text>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>Reporter:</Text>{' '}
                  {task?.reporter?.firstName} {task?.reporter?.lastName}
                </Text>
              </View>

              <View style={styles.createdDetails}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  {task.recurring && (
                    <Text style={styles.taskDateDetails}>
                      Recurrence: {task.recurrenceType}
                    </Text>
                  )}
                  {task.recurring && task.recurrenceEnd && (
                    <Text style={styles.taskDateDetails}>
                      Recurrence ends: {formatDate(task.recurrenceEnd)}
                    </Text>
                  )}
                  {task.dueDate && (
                    <Text style={styles.taskDateDetails}>
                      Due: {dayjs(task.dueDate).format('MMMM D, YYYY  hh:mm A')}
                    </Text>
                  )}
                  <Text style={styles.taskDateDetails}>
                    Created:{' '}
                    {dayjs(task.createdAt).format('MMMM D, YYYY : hh:mm A')}
                  </Text>
                </View>
              </View>
            </View>
          )
        })}
        <Footer />
      </Page>

      <Page size="A4" style={{ ...styles.page, backgroundColor: '#f9f9f9' }}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.clinicDescriptionTitle}>About Us</Text>

        <Text style={styles.clinicDescription}>{clinicDescription}</Text>
        <Footer />
      </Page>
    </Document>
  )
}
