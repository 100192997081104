import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { useEffect, useState } from 'react'
import { Input } from 'components/ui/input'

/**
 * Props for the Table component.
 */
interface TableProps {
  allowSelection: boolean
  onSelectionChange?: (selectedRows: Array<Record<string, any>>) => void
  columns: Array<{ label: string; key: string }>
  data: Array<Record<string, any>>
  actions?: (row: Record<string, any>) => JSX.Element
  pagination?: boolean
  perPage?: number
  isLoading?: boolean
  searchable?: boolean
}

/**
 * Table component for displaying tabular data with optional selection and pagination features.
 * @property {boolean} allowSelection - Whether to allow row selection.
 * @property {(selectedRows: Array<Record<string, any>>) => void} onSelectionChange - Callback for when row selection changes.
 * @property {Array<{label: string, key: string}>} columns - Array of column definitions.
 * @property {Array<Record<string, any>>} data - Array of data rows.
 * @property {(row: Record<string, any>) => JSX.Element} actions - Function to render actions for each row.
 * @property {boolean} pagination - Whether to enable pagination.
 * @property {number} perPage - Number of rows per page.
 * @property {boolean} isLoading - Whether the table is currently loading data.
 */
function SimpleTable({
  allowSelection,
  onSelectionChange,
  columns,
  data,
  actions,
  pagination,
  perPage,
  isLoading,
  searchable,
}: TableProps) {
  const [rows, setRows] = useState<Array<Record<string, any>>>([])
  const [currentRows, setCurrentRows] = useState<Array<Record<string, any>>>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [allRowsChecked, setAllRowsChecked] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0)

  /**
   * Update rows when data changes.
   */
  useEffect(() => {
    if (!data || data?.length === 0) {
      setRows([])
      setCurrentRows([])
      return
    }
    const newRows = data?.map((row: any, index: number) => ({
      ...row,
      checked: false,
      _internalId: index,
    }))
    setRows(newRows)
  }, [data])

  /**
   * Update current rows when rows change.
   */
  useEffect(() => {
    let filteredData = []

    if (searchable && searchQuery)
      filteredData = data.filter((row) =>
        columns.some((column) =>
          row[column.key]
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      )
    else filteredData = rows

    if (allowSelection && perPage) {
      const start = (currentPage - 1) * perPage
      const end = start + perPage

      setCurrentRows(filteredData?.slice(start, end))
      // setCurrentPage(1)
      setTotalPages(Math.ceil(filteredData?.length / perPage))
    } else {
      setCurrentRows(filteredData)
    }
  }, [rows, searchQuery, searchable])

  /**
   * Update current rows when currentPage changes.
   */
  useEffect(() => {
    if (pagination && perPage && perPage > 0) {
      setCurrentRows(
        rows?.slice((currentPage - 1) * perPage, currentPage * perPage)
      )
      setTotalPages(Math.ceil(rows?.length / perPage))
    } else setCurrentRows(rows)
  }, [currentPage])

  /**
   * Called when the checked state of a row changes.
   */
  useEffect(() => {
    if (onSelectionChange) {
      const selectedRows = rows?.filter((row) => row.checked)
      onSelectionChange(selectedRows)
      setSelectedRowsCount(selectedRows?.length)
    }
  }, [rows])

  /**
   * Called when the "Next page" button is clicked.
   * If the current page is not the last page, increments the current page by 1.
   */
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  /**
   * Called when the "Previous page" button is clicked.
   * If the current page is not the first page, decrements the current page by 1.
   */
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  /**
   * Called when the "Check all" checkbox is toggled.
   * Sets the `checked` property of all rows to the given value.
   * @param {boolean} checked - The new `checked` value for all rows.
   */
  const onSelectAllChange = (checked: boolean) => {
    setRows((prevRows) => prevRows.map((row) => ({ ...row, checked })))
    setCurrentRows((prevRows) => prevRows.map((row) => ({ ...row, checked })))
    setAllRowsChecked(checked)
  }

  /**
   * Called when the checked state of a row changes.
   * @param {number} _internalId - The _internalId of the row whose checked state changed.
   * @param {boolean} checked - The new checked state of the row.
   */
  const onRowCheckedChange = (_internalId: number, checked: boolean) => {
    const newRows = rows?.map((row) =>
      row._internalId === _internalId ? { ...row, checked } : row
    )
    setRows(newRows)
    const newCurrentRows = currentRows?.map((row) =>
      row._internalId === _internalId ? { ...row, checked } : row
    )
    setCurrentRows(newCurrentRows)
  }

  return (
    <>
      {searchable && (
        <div className="m-1 mb-4">
          <Input
            type="search"
            value={searchQuery}
            onChange={(e: any) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="w-full"
          />
        </div>
      )}
      <table className="table-auto w-full border border-gray-300">
        <thead>
          <tr>
            {allowSelection && (
              <th className="px-4 py-2 border-b text-left">
                <Checkbox
                  checked={allRowsChecked}
                  onCheckedChange={(checked) => {
                    onSelectAllChange(!!checked)
                  }}
                />
              </th>
            )}
            {columns.map((col, key) => (
              <th key={key} className="px-4 py-2 border-b">
                {col.label}
              </th>
            ))}
            {actions && <th className="px-4 py-2 border-b">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan={
                  columns.length + (actions ? 1 : 0) + (allowSelection ? 1 : 0)
                }
                className="px-4 py-2 text-center"
              >
                loading ...
              </td>
            </tr>
          ) : (
            <>
              {currentRows?.length > 0 ? (
                currentRows?.map((row) => (
                  <tr key={row._internalId} className="hover:bg-gray-100">
                    {allowSelection && (
                      <td className="px-4 py-2 border-b">
                        <Checkbox
                          checked={row.checked}
                          onCheckedChange={(checked) => {
                            onRowCheckedChange(row._internalId, !!checked)
                          }}
                        />
                      </td>
                    )}
                    {columns.map((col) => (
                      <td
                        key={col.key}
                        className="px-4 py-2 border-b text-center"
                      >
                        {row[col.key]}
                      </td>
                    ))}
                    {actions && (
                      <td className="px-4 py-2 border-b">{actions(row)}</td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={
                      columns.length +
                      (actions ? 1 : 0) +
                      (allowSelection ? 1 : 0)
                    }
                    className="px-4 py-2 text-center"
                  >
                    No data available.
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      {/* footer */}
      <div className="flex justify-between">
        <div>
          {pagination && (
            <div className="mt-4 mr-2 flex items-center">
              <span className="mr-2 text-sm">
                Page {currentPage} of {totalPages}
              </span>
              {totalPages > 1 && (
                <Button
                  size="sm"
                  className="mr-2"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous page
                </Button>
              )}
              {totalPages > 1 && (
                <Button
                  size="sm"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next page
                </Button>
              )}
            </div>
          )}
        </div>
        <div>
          {selectedRowsCount > 0 && (
            <div className="mt-4 mr-2 flex items-center">
              <span className="mr-2 text-sm">
                {selectedRowsCount} rows selected
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SimpleTable
