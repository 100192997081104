import {
  useClaimInsuranceMutation,
  useClaimLeadMutation,
  useLazyGetLeadQuery,
} from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/textarea'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { formatPrice, isDatePastThan } from 'lib/utils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LuStickyNote } from 'react-icons/lu'
import { Switch } from 'components/ui/switch'
import { useGetInsuranceStatusesQuery } from 'app/features/settings'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import DateAndTime from 'components/shared/date-and-time'
import { Input } from 'components/ui/input'

export default function ClaimInsurance({ open, setOpen, leadId }: any) {
  const { settings } = useApp()
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const { user } = useSelector((state: any) => state.user)
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState('')
  const [approvalAmount, setApprovalAmount] = useState<any>()
  const [authorizationNumber, setAuthorizationNumber] = useState<any>('')
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(undefined)
  const [expiryHour, setExpiryHour] = useState<string>('1')
  const [expiryMinute, setExpiryMinute] = useState<string>('0')
  const [expiryAmpm, setExpiryAmpm] = useState<string>('AM')

  const [error, setError] = useState('')
  const [claimInsurance, { isLoading }] = useClaimInsuranceMutation()

  const {
    data: insuranceStatuses,
    isError,
    isLoading: insuranceStatusesLoading,
  } = useGetInsuranceStatusesQuery()

  const [claimLead] = useClaimLeadMutation()

  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    const fetch = async () => {
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }
    }
    fetch()
  }, [])
  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setComment('')
    setError('')
  }, [open])

  async function addInsuranceClaim() {
    setError('')
    if (comment === '') {
      setError('Please add a comment')
      return
    }
    if (status === '') {
      setError('Please select a status')
      return
    }

    try {
      await claimInsurance({
        leadId: lead.id,
        comment,
        status: status,
        userId: user.id,
        ...(expiryDate && {
          expiryDate: dayjs(expiryDate)
            .hour(parseInt(expiryHour) + (expiryAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(expiryMinute))
            .toDate(),
        }),
        ...(authorizationNumber && { authorizationNumber }),
        ...(approvalAmount && { approvalAmount }),
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="max-h-[50rem]">
        {(isLoading || isLoadingLead || insuranceStatusesLoading) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            {lead && (
              <>
                <AlertDialogHeader>
                  <div className="flex items-center justify-between text-black">
                    <h1 className="text-lg font-semibold">
                      {lead.firstName} {lead.lastName}
                    </h1>
                    <div className="text-xs font-light py-1 px-2 bg-indigo-200 rounded-md text-indigo-600">
                      Claim insurance {lead?.insuranceClaimsLength + 1}
                    </div>
                  </div>
                  <p className="text-gray-500 text-xs">{lead.phone}</p>
                  <p className="text-gray-500 text-xs">{lead.email}</p>{' '}
                  {lead.sendToInsuranceComment && (
                    <p className="flex items-center pb-4">
                      <span className="font-semibold text-sm">
                        Sender Note:
                      </span>
                      <span className="ml-2">
                        {lead.sendToInsuranceComment}
                      </span>
                    </p>
                  )}
                  <div className="w-full flex items-center gap-3 my-10 p-2  border-t border-dashed" />
                  {error !== '' && (
                    <p className="text-red-500 text-xs">{error}</p>
                  )}
                  <div className="w-full grid grid-cols-2 items-center gap-3 gap-y-4 pb-4   ">
                    <div className="flex flex-col gap-2 w-full  ">
                      <Label>
                        Status
                        <span className="ml-1 text-rose-500">*</span>
                      </Label>
                      <Select
                        value={status}
                        onValueChange={(value: any) => setStatus(value)}
                      >
                        <SelectTrigger className="w-full ">
                          <SelectValue>{status}</SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {insuranceStatuses?.map((insuranceStatus: any) => {
                              return (
                                <SelectItem value={insuranceStatus.name}>
                                  {insuranceStatus.name}
                                </SelectItem>
                              )
                            })}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="flex flex-col gap-2 w-full  ">
                      <Label>Expiry Date:</Label>
                      <DateAndTime
                        date={expiryDate}
                        setDate={setExpiryDate}
                        hour={expiryHour}
                        setHour={setExpiryHour}
                        minute={expiryMinute}
                        setMinute={setExpiryMinute}
                        ampm={expiryAmpm}
                        setAmpm={setExpiryAmpm}
                      />
                    </div>

                    <div className="flex flex-col gap-2 w-full w-1/3">
                      <Label htmlFor="authorizationNumber">
                        Authorization Number
                      </Label>
                      <Input
                        placeholder="Auth Number"
                        name="authorizationNumber"
                        min={1}
                        value={authorizationNumber}
                        onChange={(e: any) => {
                          const newValue = e.target.value

                          if (/^\d*$/.test(newValue)) {
                            setAuthorizationNumber(newValue)
                          }
                        }}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full w-1/3">
                      <Label htmlFor="approvalAmount">Approval Amount</Label>
                      <Input
                        placeholder="Enter amount"
                        name="approvalAmount"
                        type="number"
                        min={1}
                        value={approvalAmount}
                        onChange={(e: any) => {
                          setApprovalAmount(parseInt(e.target.value))
                        }}
                        className="w-full"
                        required
                      />
                    </div>
                  </div>
                  <Textarea
                    className="mt-5"
                    id="nex-follow-up-checkbox"
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder="Add comment"
                  />
                </AlertDialogHeader>

                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button
                    disabled={
                      lead?.insuranceClaimsLength >= numberOfInsuranceClaims
                    }
                    onClick={addInsuranceClaim}
                  >
                    Add Insurance Claim
                  </Button>
                </AlertDialogFooter>
                <div className="w-full border-t border-dashed " />

                {lead?.insuranceClaims?.length === 0 && (
                  <p className="text-sm text-gray-600 text-center mt-3">
                    No insurance claims history
                  </p>
                )}
                {lead?.insuranceClaims?.length > 0 && (
                  <h1 className="text-md font-semibold">
                    Insurance Claims history
                  </h1>
                )}
                <div className="overflow-hidden overflow-y-scroll pt-3 pl-3 pb-3 max-h-32 h-full flex flex-col gap-3 text-sm">
                  {lead?.insuranceClaims
                    ?.map(
                      (item: any, idx: any) =>
                        lead?.insuranceClaims[
                          lead?.insuranceClaims.length - 1 - idx
                        ]
                    )
                    .map((insuranceClaim: any) => (
                      <div className="bg-gray-50 rounded-md p-3 space-y-3 border flex justify-between items-end">
                        <div>
                          <p className="text-indigo-700 text-xs">
                            {dayjs(insuranceClaim.createdAt).format(
                              'DD/MM/YYYY hh:mm A'
                            )}
                          </p>
                          {insuranceClaim.comment}
                          <p className="text-xs">
                            Status: {insuranceClaim?.status}{' '}
                          </p>
                          {insuranceClaim?.expiryDate && (
                            <p className="text-xs">
                              Expiry date:{' '}
                              {dayjs(insuranceClaim?.expiryDate).format(
                                'DD/MM/YYYY hh:mm A'
                              )}{' '}
                            </p>
                          )}
                          {insuranceClaim?.authorizationNumber && (
                            <p className="text-xs">
                              Authorization number:{' '}
                              {insuranceClaim?.authorizationNumber}{' '}
                            </p>
                          )}
                          {insuranceClaim?.approvalAmount && (
                            <p className="text-xs">
                              Approving amount:{' '}
                              {formatPrice(insuranceClaim?.approvalAmount)}{' '}
                            </p>
                          )}
                        </div>

                        <div>
                          <p className="text-xs ">
                            Claimed by: {insuranceClaim?.user?.firstName}{' '}
                            {insuranceClaim?.user?.lastName}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
