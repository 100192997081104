import useApp from 'hooks/useApp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { navigateAdmins, navigateAgents } from 'lib/allowed'

export default function DefaultRoute() {
  const { activeTabs: settingsActiveTabs } = useApp()
  const navigate = useNavigate()
  const { user, token } = useSelector((state: any) => state.user)

  useEffect(() => {
    if (user && user.role === 'AGENT') {
      navigate(navigateAgents(user, settingsActiveTabs), { replace: true })
    } else if (user && (user.role == 'ADMIN' || user.role == 'SUPERADMIN')) {
      navigate(navigateAdmins(settingsActiveTabs), { replace: true })
    }
  }, [user])

  return <></>
}
