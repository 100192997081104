import { FaImage } from 'react-icons/fa'
import { GoVideo } from 'react-icons/go'
import { IoIosDocument } from 'react-icons/io'

interface PreviewMediaProps {
  type: string
  href: string
  content?: string
  filename?: string
  timestamp?: string
}

export function PreviewMedia({ type, href, filename }: PreviewMediaProps) {
  return (
    <div>
      {href ? (
        <>
          {type === 'IMAGE' && (
            <img className="w-80 my-2" src={href} alt="Image" />
          )}
          {type === 'STICKER' && (
            <img className="h-52 my-2" src={href} alt="Image" />
          )}
          {type === 'VIDEO' && (
            <video className="w-80 my-2" src={href} controls />
          )}
          {type === 'AUDIO' && <audio src={href} controls />}
          {type === 'DOCUMENT' && (
            <div className="bg-gray-200 p-4 rounded-lg my-2">
              <a
                href={href}
                className="document-link text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {filename}
              </a>
            </div>
          )}
        </>
      ) : (
        <>
          {type === 'IMAGE' && (
            <FaImage className="text-gray-500 items-center w-full" size={200} />
          )}
          {type === 'VIDEO' && (
            <GoVideo className="text-gray-500 items-center w-full" size={200} />
          )}
          {type === 'DOCUMENT' && (
            <IoIosDocument
              className="text-gray-500 items-center w-full"
              size={200}
            />
          )}
        </>
      )}
    </div>
  )
}
