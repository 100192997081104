import { useGetLeadsWhatsappQuery } from 'app/features/lead'
import DateAndTime from 'components/shared/date-and-time'
import MySelect from 'components/shared/select/MySelect'
import SimpleTable from 'components/simple-table'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'
import { Label } from 'recharts'

export default function FilterLeads({
  setSelectedPhones,
  open,
}: {
  setSelectedPhones: any
  open: boolean
}) {
  const [selectedBranches, setSelectedBranches] = useState<any>([])
  const [selectedDepartments, setSelectedDepartments] = useState<any>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<any>([])

  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)

  const {
    branches: branchesData,
    departments: departmentsData,
    specialists: specialistsData,
  } = useApp()

  const [branches, setBranches] = useState([])
  const [departments, setDepartments] = useState([])
  const [specialists, setSpecialists] = useState([])

  useEffect(() => {
    setBranches(branchesData)
  }, [branchesData])

  useEffect(() => {
    setDepartments(departmentsData)
  }, [departmentsData])

  useEffect(() => {
    setSpecialists(specialistsData)
  }, [specialistsData])

  /**
   * Filter departments and specialists based on the selected branches.
   */
  useEffect(() => {
    setSelectedDepartments([])

    if (selectedBranches.length) {
      const branchesIds = selectedBranches.map((branch: any) => branch.id)

      const filteredDepartments = departmentsData.filter((department: any) =>
        branchesIds.includes(department.branch.id)
      )

      setDepartments(filteredDepartments)
    } else setDepartments(departmentsData)
  }, [selectedBranches])

  /**
   * Filter specialists based on the selected departments.
   */
  useEffect(() => {
    setSelectedSpecialties([])

    if (selectedDepartments.length) {
      const departmentsIds = selectedDepartments.map(
        (department: any) => department.id
      )

      const filteredSpecialists = specialistsData.filter((specialist: any) =>
        departmentsIds.includes(specialist.department.id)
      )

      setSpecialists(filteredSpecialists)
    } else setSpecialists(specialistsData)
  }, [selectedDepartments])

  const {
    data: leadsData,
    isFetching,
    refetch,
  } = useGetLeadsWhatsappQuery({
    startDate: startDate,
    endDate: endDate,
    branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
    departmentIDs: selectedDepartments.map(
      (department: { id: any }) => department.id
    ),
    specialistIDs: selectedSpecialties.map(
      (specialist: { id: any }) => specialist.id
    ),
  })

  const onSelectionChange = (selectedRows: Array<Record<string, any>>) => {
    setSelectedPhones(selectedRows?.map((lead: any) => lead.id))
  }

  /**
   * Clears all filters and resets the checked state of all leads when the filter modal is closed.
   */
  useEffect(() => {
    if (open === false) handleClearFilters()
    refetch()
  }, [open])

  /**
   * Clears all filters and resets the checked state of all leads.
   * This is called when the filter modal is closed.
   */
  const handleClearFilters = () => {
    setSelectedBranches([])
    setSelectedDepartments([])
    setSelectedSpecialties([])
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
  }

  return (
    <>
      <div className="">
        <div className="mb-3">Find Leads using filters: </div>
        <div className="w-full rounded-lg bg-white dark:bg-gray-900 mb-3">
          <form>
            <div className="flex mb-4 ">
              <div className="w-1/3 pe-2 max-md:w-full">
                <div className="space-y-2 flex flex-col">
                  <Label className="text-sm font-semibold">Branches</Label>
                  <MySelect
                    selected={selectedBranches}
                    setSelected={setSelectedBranches}
                    data={branches}
                    selectMeta="branch"
                  />
                </div>
              </div>
              <div className="w-1/3 pe-2 max-md:w-full">
                <div className="space-y-2 flex flex-col">
                  <Label className="text-sm font-semibold">Departments</Label>
                  <MySelect
                    selected={selectedDepartments}
                    setSelected={setSelectedDepartments}
                    data={departments.map((item: any) => {
                      return {
                        ...item,
                        name: item.name + ' - ' + item?.branch?.location,
                      }
                    })}
                    selectMeta="department"
                  />
                </div>
              </div>
              <div className="w-1/3 pe-2 max-md:w-full">
                <div className="space-y-2 flex flex-col">
                  <Label className="text-sm font-semibold">Specialists</Label>
                  <MySelect
                    selected={selectedSpecialties}
                    setSelected={setSelectedSpecialties}
                    //data={specialtiesData}
                    data={specialists}
                    selectMeta="specialist"
                  />
                </div>
              </div>
            </div>
            <div className="flex mb-4 ">
              <div className="w-1/3 pe-2 max-md:w-full">
                <div className="space-y-2">
                  <Label className="text-sm font-semibold">Start Date:</Label>
                  <DateAndTime
                    date={startDate}
                    setDate={setStartDate}
                    hour={startHour}
                    setHour={setStartHour}
                    minute={startMinute}
                    setMinute={setStartMinute}
                    ampm={startAmpm}
                    setAmpm={setStartAmpm}
                  />
                </div>
              </div>
              <div className="w-1/3 pe-2 max-md:w-full">
                <div className="space-y-2">
                  <Label className="text-sm font-semibold">End Date:</Label>
                  <DateAndTime
                    date={endDate}
                    setDate={setEndDate}
                    hour={endHour}
                    setHour={setEndHour}
                    minute={endMinute}
                    setMinute={setEndMinute}
                    ampm={endAmpm}
                    setAmpm={setEndAmpm}
                    beforeSelectedDate={startDate}
                  />
                </div>
              </div>
              <div className="w-1/3 pe-2 max-md:w-full flex justify-end items-start">
                <Button size="sm" type="button" onClick={handleClearFilters}>
                  Clear Filters
                </Button>
              </div>
            </div>
          </form>
        </div>

        {/* leads table */}
        <SimpleTable
          allowSelection={true}
          onSelectionChange={onSelectionChange}
          columns={[
            { label: 'First name', key: 'firstName' },
            { label: 'Last name', key: 'lastName' },
            { label: 'Whatsapp number', key: 'whatsappNumber' },
          ]}
          data={leadsData}
          pagination={true}
          perPage={10}
          isLoading={isFetching}
          searchable
        />
      </div>
    </>
  )
}
