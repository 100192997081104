import { useEffect, useState } from 'react'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical } from 'react-icons/tb'
import Book from './book'
import FollowUp from './follow-up'
import EditLead from '../edit-lead'
import Status from './status'
import useApp from 'hooks/useApp'
import { TbEdit, TbTextPlus, TbClipboardList } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import UpdateBook from './update-book'
import ClaimInsurance from './claim-insurance'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import {
  shouldFollowUp,
  shouldBook,
  shouldClaimInsurance,
  hasInsuranceAccess,
  hasBookAccess,
  hasFollowUpAccess,
} from 'lib/allowed'
import { useSelector } from 'react-redux'

export function RowAction({ lead, status }: any) {
  const { user } = useSelector((state: any) => state.user)
  const { settings } = useApp()

  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [numberOfInsuranceClaims, setNumberOfInsuranceClaims] = useState(0)
  const [timeGap, setTimeGap] = useState(0)
  const navigate = useNavigate()
  const [selectedLead, setSelectedLead] = useState<any>(null)
  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openUpdateBook, setOpenUpdateBook] = useState(false)
  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setNumberOfInsuranceClaims(settings[0]?.numberOfInsuranceClaims)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
          >
            <TbDotsVertical size={18} />
            {status && hasFollowUpAccess(user) && (
              <>
                {!lead.isSentToInsurance && (
                  <span className="absolute top-1/2 -right-2.5 -translate-y-1/2 flex h-1 w-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-1 w-1 bg-rose-500"></span>
                  </span>
                )}
              </>
            )}
            {lead.isSentToInsurance && (
              <span className="absolute top-1/2 -right-3.5 -translate-y-1/2 flex h-3 w-3">
                <IoShieldCheckmarkSharp
                  size={10}
                  className="group-hover:text-indigo-600"
                />
              </span>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {hasFollowUpAccess(user) && (
            <DropdownMenuItem
              onClick={() => {
                setSelectedLead(lead)
                setOpenFollowUp(true)
              }}
              disabled={
                lead?.followUps?.length >= numberOfFollowUps ||
                !status ||
                !shouldFollowUp(
                  lead,
                  lead?.followUps[lead.followUps.length - 1],
                  numberOfFollowUps,
                  timeGap,
                  numberOfInsuranceClaims
                )
              }
            >
              <div className="flex gap-3 items-center">
                <TbClipboardList className="text-gray-500" size={15} />
                <span>Follow Up</span>
              </div>
            </DropdownMenuItem>
          )}

          {hasBookAccess(user) && (
            <>
              {lead.status === 'BOOKED' ? (
                <>
                  <DropdownMenuItem
                    onClick={() => {
                      setSelectedLead(lead)
                      setOpenStatus(true)
                    }}
                  >
                    <div className="flex gap-3 items-center">
                      <TbTextPlus className="text-gray-500" size={15} />
                      <span>Update Status</span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setSelectedLead(lead)
                      setOpenUpdateBook(true)
                    }}
                  >
                    <div className="flex gap-3 items-center">
                      <TbTextPlus className="text-gray-500" size={15} />
                      <span>Update Book</span>
                    </div>
                  </DropdownMenuItem>
                </>
              ) : (
                <DropdownMenuItem
                  onClick={() => {
                    setSelectedLead(lead)
                    setOpenBook(true)
                  }}
                  disabled={!shouldBook(lead, numberOfInsuranceClaims)}
                >
                  <div className="flex gap-3 items-center">
                    <TbTextPlus className="text-gray-500" size={15} />
                    <span>Book</span>
                  </div>
                </DropdownMenuItem>
              )}
            </>
          )}

          {hasInsuranceAccess(user) && (
            <DropdownMenuItem
              disabled={!shouldClaimInsurance(lead, numberOfInsuranceClaims)}
              onClick={() => {
                setSelectedLead(lead)
                setOpenClaimInsurance(true)
              }}
            >
              <div className="flex gap-3 items-center">
                <IoShieldCheckmarkSharp className="text-gray-500" size={15} />
                <span>Insurance Claim</span>
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              setSelectedLead(lead)
              navigate(`/update-lead/${lead.id}`)
            }}
          >
            <div className="flex gap-3 items-center">
              <TbEdit className="text-gray-500" size={15} />
              <span>Edit</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedLead && openFollowUp && (
        <FollowUp
          status={status}
          leadId={lead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={() => {
            setOpenFollowUp(false)
            setOpenBook(true)
          }}
        />
      )}
      {selectedLead && openBook && (
        <Book leadId={lead?.id} open={openBook} setOpen={setOpenBook} />
      )}
      {selectedLead && openClaimInsurance && (
        <ClaimInsurance
          leadId={lead?.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
      {selectedLead && openStatus && (
        <Status lead={selectedLead} open={openStatus} setOpen={setOpenStatus} />
      )}
      {selectedLead && openUpdateBook && (
        <UpdateBook
          leadId={lead?.id}
          open={openUpdateBook}
          setOpen={setOpenUpdateBook}
        />
      )}
    </>
  )
}
