import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import dayjs from 'dayjs'
import {
  convertCamelCaseToTitleCase,
  formatPrice,
  reconstructTimeGap,
} from 'lib/utils'
import { TbCloudDownload, TbUserScan } from 'react-icons/tb'
import { FcOvertime } from 'react-icons/fc'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

export const LeadHistory = ({
  isLoading,
  loadingLead,
  loading,
  lead,
  numberOfFollowUps,
}: any) => {
  console.log(lead)
  return (
    <>
      {(isLoading || loadingLead || loading) && (
        <div className="h-screen grid place-content-center">
          <Loader />
        </div>
      )}
      {lead && !isLoading && !loadingLead && !loading && (
        <div className="relative  rounded-lg p-6 max-lg:p-0">
          <div className="relative   rounded-lg p-4 bg-slate-50">
            {lead?.leadActions?.map((action: any, index: any) => (
              <div
                key={index}
                className={`mb-10 ${index === lead.leadActions.length - 1 ? 'mb-0' : 'mb-10'} `}
              >
                <div className=" p-4 pr-10 max-md:pr-6 pt-0 relative min-h-[12rem]  ">
                  <h2 className="text-indigo-500 font-bold  ">
                    {action.action.replaceAll('_', ' ')}
                  </h2>

                  {action.updateDetails && (
                    <div className="mt-2">
                      {/* <p className="font-medium">Updating details</p> */}

                      <table
                        className={
                          'text-gray-800 text-sm w-full border-collapse text-[13px]  table-fixed'
                        }
                      >
                        <thead>
                          <tr className="text-left border-b w-full ">
                            <th className="xl:w-28 ">Field</th>
                            <th className="xl:w-56">Previous</th>
                            <th className="xl:w-56 ">Current</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          <>
                            {[
                              'firstName',
                              'lastName',
                              'email',
                              'phone',
                              'whatsappNumber',
                              'alternativePhone',
                              'source',
                              'nationality',
                              'gender',
                              'emiratesId',
                              'mrnUrn',
                              'insuranceType',
                              'insuranceNumber',
                              'insuranceProvider',
                            ].map((field) => {
                              if (action.updateDetails[field])
                                return (
                                  <tr
                                    key={lead.id}
                                    className="border-b last:border-b-0"
                                  >
                                    <td className=" font-medium overflow-x-hidden whitespace-nowrap text-ellipsis ">
                                      {convertCamelCaseToTitleCase(field)}
                                    </td>
                                    <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                                      {action.updateDetails[field].prev}
                                    </td>
                                    <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                                      {action.updateDetails[field].current}
                                    </td>
                                  </tr>
                                )
                            })}

                            {['branches', 'departments', 'specialists'].map(
                              (field) => {
                                if (action.updateDetails[field])
                                  return (
                                    <tr
                                      key={lead.id}
                                      className="border-b last:border-b-0"
                                    >
                                      <td className=" font-medium overflow-x-hidden whitespace-nowrap text-ellipsis ">
                                        {convertCamelCaseToTitleCase(field)}
                                      </td>
                                      <td className="overflow-x-hidden whitespace-normal text-ellipsis ">
                                        <div className="flex flex-wrap gap-2 max-md:flex-col">
                                          {action.updateDetails[
                                            field
                                          ].prev?.map((item: any) => (
                                            <span>{item}</span>
                                          ))}
                                        </div>

                                        {/* {action.updateDetails[field].prev?.join(
                                          ', '
                                        )} */}
                                      </td>
                                      <td className="overflow-x-hidden whitespace-normal text-ellipsis ">
                                        <div className="flex flex-wrap gap-2 max-md:flex-col">
                                          {action.updateDetails[
                                            field
                                          ].current?.map((item: any) => (
                                            <span>{item}</span>
                                          ))}
                                        </div>
                                        {/* {action.updateDetails[
                                          field
                                        ].current?.join(', ')} */}
                                      </td>
                                    </tr>
                                  )
                              }
                            )}
                          </>
                        </tbody>
                      </table>
                      <div className="text-xs w-full flex items-center justify-center my-1 mb-4 text-gray-600">
                        Update details.
                      </div>
                    </div>
                  )}
                  <div className="mt-2">
                    {action.followUpComment && (
                      <p>• comment: {action.followUpComment}</p>
                    )}
                    {action.nextFollowUpTimeGap && (
                      <p className="test-xs ">
                        • Next follow up time gap: {action.nextFollowUpTimeGap}{' '}
                      </p>
                    )}
                    {action.bookDate && (
                      <p>
                        • date:{' '}
                        {dayjs(action.bookDate).format('DD/MM/YYYY hh:mm:A')}
                      </p>
                    )}
                    {action.bookBranch && <p>• branch: {action.bookBranch}</p>}
                    {action.bookDepartment && (
                      <p>• department: {action.bookDepartment}</p>
                    )}
                    {action.bookSpecialist && (
                      <p>• specialist: {action.bookSpecialist}</p>
                    )}
                    {action.bookComment && (
                      <p>• comment: {action.bookComment}</p>
                    )}
                    {action.bookStatusComment && (
                      <p>• comment: {action.bookStatusComment}</p>
                    )}
                    {action.action === 'Update_Book_Status' && (
                      <>
                        <p>
                          • status:{' '}
                          {action.bookStatusIsShow ? 'Show' : 'No Show'}
                        </p>
                        {action.bookStatusIsShow && (
                          <>
                            <p>
                              • is paid:{' '}
                              {action.bookStatusIsPaid ? 'Yes' : 'No'}
                            </p>
                            {action.bookStatusIsPaid && (
                              <p>
                                • amount: {formatPrice(action.bookStatusAmount)}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {action.sendToInsuranceComment && (
                      <p>• comment: {action.sendToInsuranceComment}</p>
                    )}
                    {action.sendToInsuranceEndedReason && (
                      <p>• reason: {action.sendToInsuranceEndedReason}</p>
                    )}
                    {action.sendToInsuranceEndedComment && (
                      <p>• comment: {action.sendToInsuranceEndedComment}</p>
                    )}
                    {action.insuranceClaimStatus && (
                      <p>• status: {action.insuranceClaimStatus}</p>
                    )}
                    {action?.insuranceClaimExpiryDate && (
                      <p>
                        • expiry date:{' '}
                        {dayjs(action?.insuranceClaimExpiryDate).format(
                          'DD/MM/YYYY hh:mm A'
                        )}{' '}
                      </p>
                    )}
                    {action?.insuranceClaimAuthorizationNumber && (
                      <p>
                        • Authorization number:{' '}
                        {action?.insuranceClaimAuthorizationNumber}{' '}
                      </p>
                    )}
                    {action?.insuranceClaimApprovalAmount && (
                      <p>
                        • Approving amount:{' '}
                        {formatPrice(action?.insuranceClaimApprovalAmount)}{' '}
                      </p>
                    )}
                    {action.insuranceClaimComment && (
                      <p>• comment: {action.insuranceClaimComment}</p>
                    )}
                    {action.notQualifiedReason && (
                      <p>• reason: {action.notQualifiedReason}</p>
                    )}
                    {action.attachmentUrl && (
                      <Button
                        className='className="flex gap-3 items-center justify-start  h-8 mb-2 text-white'
                        onClick={async () => {
                          try {
                            const response = await fetch(action.attachmentUrl)
                            if (!response.ok) {
                              throw new Error('Network response was not ok')
                            }
                            const blob = await response.blob()
                            const downloadUrl = window.URL.createObjectURL(blob)

                            const a = document.createElement('a')
                            a.href = downloadUrl
                            a.download = action.attachmentName
                            document.body.appendChild(a)
                            a.click()
                            window.URL.revokeObjectURL(downloadUrl)
                          } catch (error) {
                            console.error('Download failed:', error)
                          }
                        }}
                      >
                        {' '}
                        <TbCloudDownload size={15} />
                        <span> Download</span>
                      </Button>
                    )}
                    {action?.attachmentDescription && (
                      <p>• Description: {action?.attachmentDescription} </p>
                    )}
                  </div>
                  <div className="mt-2 text-gray-500 text-xs items-start">
                    <p className=" flex items-center gap-2 ">
                      {/* action at:{' '} */}
                      <FcOvertime size={18} />
                      {dayjs(action.createdAt).format('DD/MM/YYYY hh:mm A')}
                    </p>
                    <p className="flex items-center gap-2 pl-[3px]">
                      {/* action by: */}
                      <TbUserScan
                        size={16}
                        className="group-hover:text-indigo-600"
                      />
                      {action.user?.firstName} {action.user?.lastName}
                    </p>
                  </div>
                  <div className="absolute right-9  max-lg:right-2 top-1 w-3 h-3 bg-indigo-500 z-40 rounded-full" />
                  {/* <div className="absolute -right-32  max-lg:right-0 -top-1    text-gray-400 z-40">
                    {dayjs(action.createdAt).format('DD/MM/YYYY hh:mm:A')}
                  </div> */}
                  {index < lead?.leadActions.length - 1 && (
                    <div className="absolute right-[41px] max-lg:right-[13px]  top-8 w-[2px] h-full z-30 bg-slate-100" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        // <>
        //   <div className="w-full  my-10 p-2">
        //     <h1 className="text-md font-semibold py-4">Follow Up history</h1>

        //     {lead?.followUps?.length === 0 ? (
        //       <p className="text-sm text-gray-600 text-center my-3">
        //         No follow up history
        //       </p>
        //     ) : (
        //       <div className="overflow-hidden overflow-y-scroll pt-1 pl-3 pb-1 max-h-96 h-full flex flex-col gap-6 text-sm ">
        //         {lead?.followUps
        //           ?.slice(0, numberOfFollowUps)
        //           .map((followUp: any) => (
        //             <div className="bg-slate-100  p-3 space-y-3 border rounded-lg">
        //               <p className="text-indigo-700 text-sm">
        //                 {dayjs(followUp.createdAt).format('DD/MM/YYYY hh:mm A')}
        //               </p>
        //               <p className="test-sm ">{followUp.comment} </p>
        //               {followUp.nextFollowUpTimeGap && (
        //                 <p className="test-xs ">
        //                   <span className="font-medium">
        //                     Next follow up time gap:{' '}
        //                   </span>
        //                   {reconstructTimeGap(followUp.nextFollowUpTimeGap)}{' '}
        //                 </p>
        //               )}
        //               <p className="text-xs text-right text-gray-500">
        //                 Followed by: {followUp?.user?.firstName}{' '}
        //                 {followUp?.user?.lastName}
        //               </p>
        //             </div>
        //           ))}
        //       </div>
        //     )}
        //   </div>
        //   <div className="w-full  my-10 p-2  border-t border-dashed">
        //     <h1 className="text-md font-semibold py-4">Booking history</h1>

        //     {lead?.bookings?.length === 0 ? (
        //       <p className="text-sm text-gray-600 text-center py-4 my-3">
        //         No booking history
        //       </p>
        //     ) : (
        //       <div className="overflow-hidden overflow-y-scroll pt-1 pl-3 pb-1 max-h-96 h-full flex flex-col gap-6 text-sm ">
        //         {lead?.bookings.map((booking: any) => (
        //           <div className="bg-slate-100  p-3  space-y-1 border rounded-lg">
        //             <p className="text-indigo-700 text-sm">
        //               {dayjs(booking.updatedAt).format('DD/MM/YYYY hh:mm A')}
        //             </p>

        //             <p className="test-sm  ">
        //               • <span className="font-semibold">Date:</span>{' '}
        //               {dayjs(booking.date).format('DD/MM/YYYY hh:mm A')}
        //             </p>
        //             <p className="test-sm  ">
        //               •{' '}
        //               <span className="font-semibold">
        //                 {booking.branch.name}
        //                 {' - '}
        //                 {booking.department.name}
        //                 {' - '}
        //                 {booking.specialist.name}
        //               </span>{' '}
        //             </p>

        //             {booking.isShowUpdated && (
        //               <>
        //                 <p className="test-sm ">
        //                   • <span className="font-semibold">Status:</span>{' '}
        //                   {booking.isShow ? 'show' : 'no-show'}
        //                 </p>
        //                 {booking.isShow && (
        //                   <>
        //                     <p className="test-sm ">
        //                       • <span className="font-semibold">Paid:</span>{' '}
        //                       {booking.isPaid ? 'Yes' : 'No'}
        //                     </p>
        //                     {booking.isPaid && (
        //                       <p className="test-sm ">
        //                         • <span className="font-semibold">Amount:</span>
        //                         : {formatPrice(booking.amount)}
        //                       </p>
        //                     )}
        //                   </>
        //                 )}

        //                 <p className="test-sm ">
        //                   •{' '}
        //                   <span className="font-semibold">Status Comment:</span>{' '}
        //                   {booking.isShowComment}
        //                 </p>
        //               </>
        //             )}

        //             <p className="test-sm ">{booking.comment}</p>

        //             <p className="text-xs text-right text-gray-500">
        //               Booked by: {booking?.user?.firstName}{' '}
        //               {booking?.user?.lastName}
        //             </p>
        //             {/* <p className="text-xs text-right text-gray-500">
        //               Action at:
        //               {dayjs(booking.updatedAt).format('DD/MM/YYYY hh:mm A')}
        //             </p> */}

        //             {booking?.oldBookings && (
        //               <div className="w-full  my-10 p-2  border-t border-dashed">
        //                 <h1 className="text-md font-semibold py-4">
        //                   book history
        //                 </h1>

        //                 <div className="overflow-hidden overflow-y-scroll pt-1 pl-3 pb-1 max-h-72 h-full flex flex-col gap-3 text-sm ">
        //                   {booking?.oldBookings
        //                     .map(
        //                       (item: any, idx: any) =>
        //                         booking?.oldBookings[
        //                           booking?.oldBookings.length - 1 - idx
        //                         ]
        //                     )

        //                     .map((oldBooking: any) => (
        //                       <div className="bg-gray-50  p-3 space-y-1 border rounded-lg">
        //                         <p className="text-indigo-700 text-sm">
        //                           {dayjs(oldBooking.updatedAt).format(
        //                             'DD/MM/YYYY hh:mm A'
        //                           )}
        //                         </p>

        //                         <p className="test-sm ">
        //                           • <span className="font-semibold">Date:</span>{' '}
        //                           {dayjs(oldBooking.date).format(
        //                             'DD/MM/YYYY hh:mm A'
        //                           )}
        //                         </p>

        //                         <p className="test-sm ">
        //                           •{' '}
        //                           <span className="font-semibold">
        //                             {oldBooking.branch}
        //                             {' - '}
        //                             {oldBooking.department}
        //                             {' - '}
        //                             {oldBooking.specialist}
        //                           </span>{' '}
        //                         </p>
        //                         <p className="test-sm ">{oldBooking.comment}</p>

        //                         {/* <p className="text-xs text-right text-gray-500">
        //                           Action at:
        //                           {dayjs(oldBooking.updatedAt).format(
        //                             'DD/MM/YYYY hh:mm A'
        //                           )}
        //                         </p> */}
        //                       </div>
        //                     ))}
        //                 </div>
        //               </div>
        //             )}
        //           </div>
        //         ))}
        //       </div>
        //     )}
        //   </div>

        //   {lead?.isSentToInsurance ? (
        //     <div className="w-full flex items-center gap-3 my-10 p-2  border-t border-dashed">
        //       <div className="w-full space-y-4">
        //         <h1 className="text-md font-semibold pt-4">
        //           Insurance History
        //         </h1>
        //         <div className="overflow-hidden overflow-y-scroll  pb-1 max-h-96 h-full flex flex-col gap-6 text-sm ">
        //           {lead?.sendToInsurance?.map((sendToInsurance: any) => {
        //             return (
        //               <div className="pt-1 pl-3">
        //                 <div className="bg-slate-100 rounded-lg p-3 space-y-3 border flex  flex-col">
        //                   <div>
        //                     <p className="text-indigo-700 text-sm">
        //                       {dayjs(sendToInsurance?.sentAt).format(
        //                         'DD/MM/YYYY hh:mm A'
        //                       )}
        //                     </p>
        //                     <p className="text-sm py-2">
        //                       {sendToInsurance.comment}{' '}
        //                     </p>

        //                     <>
        //                       <p className="text-sm ">
        //                         •{' '}
        //                         <span className="font-semibold">is Ended:</span>{' '}
        //                         {sendToInsurance.isEnded ? 'Yes' : 'No'}
        //                       </p>
        //                       {sendToInsurance.isEnded && (
        //                         <>
        //                           <p className="text-sm ">
        //                             •{' '}
        //                             <span className="font-semibold ">
        //                               Ended Reason:
        //                             </span>{' '}
        //                             {sendToInsurance.endedReason === 'Manual'
        //                               ? 'Ended manually'
        //                               : 'Lead reached max insurance claims attempts'}
        //                           </p>

        //                           {sendToInsurance.endedReason === 'Manual' && (
        //                             <p className="text-sm ">
        //                               •{' '}
        //                               <span className="font-semibold ">
        //                                 Ended Comment:
        //                               </span>{' '}
        //                               {sendToInsurance.endedComment}
        //                             </p>
        //                           )}

        //                           <p className="text-sm ">
        //                             •{' '}
        //                             <span className="font-semibold ">
        //                               Ended At:
        //                             </span>{' '}
        //                             {dayjs(sendToInsurance?.endedAt).format(
        //                               'DD/MM/YYYY hh:mm A'
        //                             )}{' '}
        //                           </p>
        //                         </>
        //                       )}
        //                     </>
        //                   </div>
        //                   <div>
        //                     <p className="text-xs  text-right text-gray-500">
        //                       Sent by: {sendToInsurance?.user?.firstName}{' '}
        //                       {sendToInsurance?.user?.lastName}
        //                     </p>
        //                   </div>

        //                   {sendToInsurance.insuranceClaims?.length > 0 && (
        //                     <>
        //                       <h1 className="pt-4 font-semibold text-sm">
        //                         Insurance claims
        //                       </h1>
        //                       {sendToInsurance.insuranceClaims?.map(
        //                         (insuranceClaim: any) => {
        //                           return (
        //                             <div className=" pl-3">
        //                               <div className="bg-gray-50 rounded-lg p-3 space-y-3 border flex  flex-col">
        //                                 <div>
        //                                   <p className="text-indigo-700 text-sm">
        //                                     {dayjs(insuranceClaim?.date).format(
        //                                       'DD/MM/YYYY hh:mm A'
        //                                     )}
        //                                   </p>
        //                                   <p className="text-sm py-2">
        //                                     {insuranceClaim.comment}{' '}
        //                                   </p>

        //                                   <p className="text-sm ">
        //                                     •{' '}
        //                                     <span className="font-medium ">
        //                                       Status:
        //                                     </span>{' '}
        //                                     {insuranceClaim?.status}{' '}
        //                                   </p>
        //                                 </div>
        //                                 <div>
        //                                   <p className="text-xs  text-right text-gray-500">
        //                                     Claimed by:{' '}
        //                                     {insuranceClaim?.user?.firstName}{' '}
        //                                     {insuranceClaim?.user?.lastName}
        //                                   </p>
        //                                 </div>
        //                               </div>
        //                             </div>
        //                           )
        //                         }
        //                       )}
        //                     </>
        //                   )}
        //                 </div>
        //               </div>
        //             )
        //           })}
        //         </div>
        //       </div>
        //     </div>
        //   ) : (
        //     ''
        //   )}

        //   {lead?.status === 'NOT_QUALIFIED' ? (
        //     <div className="w-full flex items-center gap-3 my-10 p-2  border-t border-dashed">
        //       <div className="w-full space-y-4">
        //         <h1 className="text-md font-semibold pt-4">
        //           Not Qualified Reason
        //         </h1>
        //         <div className="pt-1 pl-3 ">
        //           <div className="bg-gray-50 rounded-lg p-3 space-y-3 border flex flex-col">
        //             <div>
        //               <p className="text-indigo-700 text-xm">
        //                 {dayjs(lead?.notQualifiedAt).format(
        //                   'DD/MM/YYYY hh:mm A'
        //                 )}
        //               </p>
        //               {lead?.notQualifiedReason}
        //             </div>
        //             <div>
        //               <p className="text-xs text-right text-gray-500">
        //                 Not qualified by: {lead?.notQualifiedBy?.firstName}{' '}
        //                 {lead?.notQualifiedBy?.lastName}
        //               </p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   ) : (
        //     ''
        //   )}
        // </>
      )}
    </>
  )
}
