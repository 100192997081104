import { Cross1Icon } from '@radix-ui/react-icons'
import { CustomInput } from 'components/shared/custom-input'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import { useEffect, useState } from 'react'
import {
  useCreateTaskMutation,
  useGetTasksQuery,
  useLazyGetAllTasksQuery,
} from 'app/features/task'
import MySelect from 'components/shared/select/MySelect'
import { useGetAllAgentsNamesQuery } from 'app/features/users'
import { CustomTextArea } from 'components/shared/custom-text-area'
import DateAndTime from 'components/shared/date-and-time'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import dayjs from 'dayjs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { TasksReport } from './task-report'
import { VscFilePdf } from 'react-icons/vsc'
import { getStartDateAndEndDate } from 'lib/utils'
import useApp from 'hooks/useApp'

const initialFilters = {
  assignee: [],
  status: 'All',
}

export default function GenerateReport() {
  const { settings } = useApp()
  const {
    data: agents,
    isLoading: isAgentsLoading,
    isError: isAgentsError,
  } = useGetAllAgentsNamesQuery()

  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState<any>(initialFilters)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [selectedDate, setSelectedDate] = useState<string>('All')
  const [dates, setDates] = useState<any>()

  const handleClearFilters = () => {
    setSelectedDate('All')
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
    setFilters(initialFilters)

    //setOpen(false)
  }
  useEffect(() => {
    setError('')
    handleClearFilters()
  }, [open])

  const [
    trigger,
    { data: tasks, isLoading: isLoadingTasks, isError, isFetching },
  ] = useLazyGetAllTasksQuery({})

  async function getTasks() {
    setError('')
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }

    if (selectedDate != 'All') {
      setDates({
        startDate: startDate
          ? dayjs(startDate)
              .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(startMinute))
              .toDate()
          : startDateValue,
        endDate: endDate
          ? dayjs(endDate)
              .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(endMinute))
              .toDate()
          : endDateValue,
      })
    }
    try {
      const result: any = await trigger({
        ...(selectedDate != 'All' && {
          startDate: startDate
            ? dayjs(startDate)
                .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
                .minute(parseInt(startMinute))
                .toDate()
            : startDateValue,
          endDate: endDate
            ? dayjs(endDate)
                .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
                .minute(parseInt(endMinute))
                .toDate()
            : endDateValue,
        }),
        ...(filters?.status && { status: filters.status }),
        ...(filters?.assignee &&
          filters?.assignee?.length > 0 && {
            assignee: filters.assignee.map((item: any) => item.id),
          }),
      })
      if (result?.error?.status === 409) return setError(result?.error?.data)
      if (result?.error)
        return setError('Failed to generate report, please try again')

      //setOpen(false)
    } catch (error) {
      return setError('Failed to , please try again')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">Generate Report</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          {isAgentsLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}
          {isAgentsError && (
            <div className="h-40 grid place-content-center">
              <Error message="server error, please contact administrators" />
            </div>
          )}
          {!isAgentsError && !isAgentsLoading && (
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  Generate Report
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="w-full space-y-5 mt-5 px-1">
                <div className="w-1/3  max-md:w-full">
                  <div className="space-y-2 flex flex-col">
                    <Label className="text-sm font-semibold">Status</Label>
                    <Select
                      value={filters.status.replace('_', ' ')}
                      onValueChange={(value: any) =>
                        setFilters((prev: any) => {
                          return { ...prev, status: value }
                        })
                      }
                    >
                      <SelectTrigger className="w-full ">
                        <SelectValue>{filters.status}</SelectValue>
                      </SelectTrigger>
                      <SelectContent className=" w-40  ">
                        <SelectGroup>
                          <SelectItem value={'Pending'}>Pending</SelectItem>
                          <SelectItem value={'In_Progress'}>
                            In Progress
                          </SelectItem>
                          <SelectItem value={'In_Review'}>In Review</SelectItem>
                          <SelectItem value={'Canceled'}>Canceled</SelectItem>
                          <SelectItem value={'Completed'}>Completed</SelectItem>
                          <SelectItem value="All">All</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="w-1/3  max-md:w-full">
                  <div className="space-y-2 flex flex-col">
                    <Label className="text-sm font-semibold">Assignee</Label>
                    <MySelect
                      selected={filters.assignee || []}
                      setSelected={(value: any) => {
                        setFilters((prev: any) => {
                          return {
                            ...prev,
                            assignee: [...value],
                          }
                        })
                      }}
                      // data={departmentsData}
                      data={agents?.map((agent: any) => {
                        return {
                          id: agent.id,
                          name: agent.firstName + agent.lastName,
                        }
                      })}
                      selectMeta="agent"
                    />
                  </div>
                </div>
                <div className="flex gap-2 max-md:flex-col">
                  <div className="space-y-2 w-full ">
                    <Label className="text-sm font-semibold">Date</Label>
                    <Select
                      value={selectedDate}
                      onValueChange={(value: any) => setSelectedDate(value)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue>{selectedDate}</SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="Last 30 days">
                            Last 30 days
                          </SelectItem>
                          <SelectItem value="Last month">Last month</SelectItem>
                          <SelectItem value="This week">This week</SelectItem>
                          <SelectItem value="Last week">Last week</SelectItem>
                          <SelectItem value="Today">Today</SelectItem>
                          <SelectItem value="Yesterday">Yesterday</SelectItem>
                          <SelectItem value="Custom date">
                            Custom Date
                          </SelectItem>
                          <SelectItem value="All">All</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2 w-full">
                    <Label className="text-sm font-semibold">Start Date:</Label>
                    <DateAndTime
                      date={startDate}
                      setDate={setStartDate}
                      hour={startHour}
                      setHour={setStartHour}
                      minute={startMinute}
                      setMinute={setStartMinute}
                      ampm={startAmpm}
                      setAmpm={setStartAmpm}
                      disabled={!(selectedDate === 'Custom date')}
                    />
                  </div>
                  <div className="space-y-2 w-full">
                    <Label className="text-sm font-semibold">End Date:</Label>
                    <DateAndTime
                      date={endDate}
                      setDate={setEndDate}
                      hour={endHour}
                      setHour={setEndHour}
                      minute={endMinute}
                      setMinute={setEndMinute}
                      ampm={endAmpm}
                      setAmpm={setEndAmpm}
                      beforeSelectedDate={startDate}
                      disabled={!(selectedDate === 'Custom date')}
                    />
                  </div>
                </div>

                <div className="space-y-3 mt-5">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}

                  <div className="flex items-center gap-3 justify-end">
                    <Button
                      size="sm"
                      className='className="flex gap-3 items-center justify-start  h-8  text-white'
                      disabled={!(!isLoadingTasks && !isFetching && tasks)}
                    >
                      <PDFDownloadLink
                        document={
                          <TasksReport
                            tasks={tasks}
                            clinicDescription={
                              settings ? settings[0]?.description : ''
                            }
                            status={filters?.status?.replace('_', ' ')}
                            startDate={dates?.startDate}
                            endDate={dates?.endDate}
                            assignee={filters?.assignee}
                          />
                        }
                        fileName="report.pdf"
                      >
                        {({ loading }) =>
                          true && (
                            <span className="flex items-center gap-2">
                              <span> Download</span>
                              <VscFilePdf size={18} className="" />
                            </span>
                          )
                        }
                      </PDFDownloadLink>
                    </Button>

                    <Button size="sm" onClick={getTasks}>
                      Generate report
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
