import { Routes, Route } from 'react-router-dom'
import './App.css'

import Layout from 'components/shared/layout'
import Login from 'pages/auth/login'
import Verify from 'pages/auth/verify'
import ResetPassword from 'pages/auth/reset-password'
import Dashboard from 'pages/dashboard'
import Leads from 'pages/leads'
import Users from 'pages/users'
import Settings from 'pages/settings'
import AgentDashboard from 'pages/dashboard/components/agent-dashboard'
import RequireAuth from 'lib/auth-guard'
import Prefetch from 'app/features/prefetch'
import { SubscriptionWrapper } from 'components/shared/wrapperSubscription'
import TemporarilyFailure from 'pages/auth/temporarilyFailure'
import AuthDevicesRequests from 'pages/auth/auth-devices-requests.'
import LeadForm from 'pages/lead/lead-form'
import Lead from 'pages/lead'
import Form from 'pages/form'
import { TabsWrapper } from 'components/shared/wrapperTabs'
import InsurancePortal from 'pages/dashboard/components/insurance-portal'
import { TbError404 } from 'react-icons/tb'
import Tasks from 'pages/tasks/tasks'
import Social from 'pages/social'
import DefaultRoute from 'components/shared/default-route'
import WhatsappStats from 'pages/whatsapp-stats'
import Reports from 'pages/reports'

export default function App() {
  return (
    <Routes>
      <Route path="/form" element={<Form />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/verify/:token" element={<Verify byToken={true} />} />

      <Route path="/temporarily-failure" element={<TemporarilyFailure />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route element={<RequireAuth />}>
        <Route element={<Prefetch />}>
          <Route element={<Layout />}>
            <Route
              path="/"
              //element={<Navigate to="/dashboard" replace={true} />}
              element={<DefaultRoute />}
            />
            <Route
              path="/reports"
              //element={<Navigate to="/dashboard" replace={true} />}
              element={<Reports />}
            />
            <Route
              path="/dashboard"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Dashboard'}>
                    <Dashboard />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/whatsapp-stats"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Dashboard'}>
                    <WhatsappStats />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/agents-dashboard"
              element={
                <TabsWrapper tab={'Dashboard'}>
                  <AgentDashboard />
                </TabsWrapper>
              }
            />

            <Route
              path="/create-lead"
              element={
                <SubscriptionWrapper>
                  <LeadForm />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/lead-details/:id"
              element={
                <SubscriptionWrapper>
                  <Lead />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/update-lead/:id"
              element={
                <SubscriptionWrapper>
                  <Lead />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/leads"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Leads'}>
                    <Leads statuses={['NEW', 'FOLLOW_UP', 'END_INSURANCE']} />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/booked"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Booked">
                    <Leads status="BOOKED" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/not-qualified"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Not_Qualified">
                    <Leads status="NOT_QUALIFIED" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/insurance"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Insurance">
                    <Leads status="Insurance" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/insurance-portal"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Insurance_Portal">
                    <InsurancePortal />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />

            <Route
              path="/show"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Show'}>
                    <Leads status="SHOW" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/not-show"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Show'}>
                    <Leads status="NO_SHOW" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />

            <Route
              path="/users"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Users'}>
                    <Users />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/agents-login-requests"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Login_Requests'}>
                    <AuthDevicesRequests />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/tasks"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Tasks'}>
                    <Tasks />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/social"
              element={
                <TabsWrapper tab={'Social'}>
                  <Social />
                </TabsWrapper>
              }
            />

            <Route path="/settings" element={<Settings />} />
            <Route
              path="/no-access"
              element={
                <div className="w-screen h-screen  flex items-center justify-center">
                  <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
                    <TbError404 className="text-rose-600" size={30} />
                    You don't have access to any section in the system, please
                    contact your administrator
                  </div>
                </div>
              }
            />
          </Route>
        </Route>

        <Route
          path="*"
          element={
            <div className="w-screen h-screen  flex items-center justify-center">
              <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
                <TbError404 className="text-rose-600" size={30} />
                Not Found
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  )
}
