import { useInitMetaAccountMutation } from 'app/features/meta'
import { Button } from 'components/ui/button'

export default function MetaLogin() {
  const [initAccount] = useInitMetaAccountMutation()

  const fbAsyncInit = function () {
    // JavaScript SDK configuration and setup
    window.FB.init({
      appId: '1652942735556261', // Meta App ID
      cookie: true, // enable cookies
      xfbml: true, // parse social plugins on this page
      version: 'v20.0', //Graph API version
    })
  }

  fbAsyncInit()

  function loginWithFacebook() {
    // Launch Facebook login
    // eslint-disable-next-line no-undef
    FB.login(
      function (response) {
        console.log('response ', response)
        if (response.authResponse) {
          const code = response.authResponse.code
          console.log('code ', code)
          initAccount({ code })
          console.log('response.authResponse ', response.authResponse)
          console.log('Welcome!  Fetching your information.... ')
          // eslint-disable-next-line no-undef
          FB.api('/me', function (response: any) {
            console.log('Successful login for: ' + response.name)
            console.log('Thanks for logging in, ' + response.name + '!')
          })
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      },
      {
        config_id: '1205642967312877',
        response_type: 'code',
        override_default_response_type: true,
      }
    )
  }

  return (
    <div className="flex flex-col space-y-4 justify-center items-center py-10">
      <Button size="sm" onClick={() => loginWithFacebook()}>
        Login with Facebook
      </Button>
    </div>
  )
}
