import { useState, useEffect } from 'react'
import ChartSection from './chart-section'

export default function ReportsChart({ sourcesData }: any) {
  const [allArrays, setAllArrays] = useState<any>(null)

  useEffect(() => {
    const array = []
    for (let i = 0; i < sourcesData.length; i += 4) {
      array.push(sourcesData.slice(i, i + 4))
    }
    setAllArrays(array)
    sessionStorage.setItem('total-charts', JSON.stringify(array?.length))
  }, [sourcesData])

  return (
    <div>
      {allArrays &&
        allArrays.map((array: any, index: any) => (
          <ChartSection
            key={`chart-section-${index}`}
            data={array}
            id={index}
          />
        ))}
    </div>
  )
}
