import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import PhoneInput from 'components/shared/phone-input'
import { CustomTextArea } from 'components/shared/custom-text-area'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import CustomNationalityInput from 'components/shared/nationality-input'
import useApp from 'hooks/useApp'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Checkbox } from 'components/ui/checkbox'
import { useEffect, useState } from 'react'
import { da } from 'date-fns/locale'
import dayjs from 'dayjs'
import { hasInsuranceAccess } from 'lib/allowed'
import { useSelector } from 'react-redux'
import { Button } from 'components/ui/button'

export default function LeadInfo({
  data,
  setData,
  handleChange,
  isDetailsPage,
}: any) {
  const { user } = useSelector((state: any) => state.user)
  const { sources, settings, insuranceProviders } = useApp()
  const [checkedWhatsappAsPhone, setCheckedWhatsappAsPhone] = useState(false)
  const [numberOfInsuranceClaims, setNumberOfInsuranceClaims] = useState(0)
  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfInsuranceClaims(settings[0]?.numberOfInsuranceClaims)
    }
  }, [settings])

  return (
    <div className="w-full space-y-3 px-1 ">
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomInput
          name="firstName"
          label="First Name"
          value={data.firstName}
          setValue={handleChange}
          type="text"
          required
          disabled={isDetailsPage}
        />
        <CustomInput
          name="lastName"
          label="Last Name"
          value={data.lastName}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomInput
          name="email"
          label="Email"
          value={data.email}
          setValue={handleChange}
          type="email"
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <PhoneInput
          label="Phone Number"
          id="phone"
          handleChange={(value: any) =>
            setData({
              ...data,
              phone: value,
              ...(checkedWhatsappAsPhone && {
                whatsappNumber: value,
              }),
            })
          }
          value={data.phone}
          required
          disabled={isDetailsPage}
        />

        <PhoneInput
          label="Alternative Phone Number"
          id="alternativePhone"
          handleChange={(value: any) =>
            setData({
              ...data,
              alternativePhone: value,
            })
          }
          value={data.alternativePhone}
          disabled={isDetailsPage}
        />
      </div>

      <div className="w-full flex items-center gap-3 ">
        <PhoneInput
          label="Whatsapp Number"
          id="whatsappNumber"
          className="w-full"
          handleChange={(value: any) =>
            setData({
              ...data,
              whatsappNumber: value,
            })
          }
          value={data.whatsappNumber}
          required
          disabled={isDetailsPage || checkedWhatsappAsPhone}
        />
        <div className="flex w-full pt-6 items-center space-x-2 ">
          <Checkbox
            id="whatsapp"
            className=" disabled:bg-gray-500"
            disabled={isDetailsPage}
            checked={checkedWhatsappAsPhone}
            onCheckedChange={(checked) => {
              if (checked) {
                setData({
                  ...data,
                  whatsappNumber: data.phone,
                })
              } else {
                setData({
                  ...data,
                  whatsappNumber: '',
                })
              }
              setCheckedWhatsappAsPhone((prev) => !prev)
            }}
          />
          <label
            htmlFor="whatsapp"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Use phone number
          </label>
        </div>
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <div className="w-full space-y-2">
          <Label>Gender</Label>
          <Select
            value={data.gender}
            onValueChange={(value: any) =>
              setData({
                ...data,
                gender: value,
              })
            }
            disabled={isDetailsPage}
          >
            <SelectTrigger>
              <SelectValue>{data.gender}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <CustomNationalityInput
          label="Nationality"
          id="nationality"
          handleChange={(value: any) =>
            setData({
              ...data,
              nationality: value,
            })
          }
          value={data.nationality}
          required
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col ">
        <CustomSelect
          label="Source"
          list={sources}
          value={data.sourceId}
          setValue={(value: any) =>
            setData({
              ...data,
              sourceId: value,
            })
          }
          required
          disabled={isDetailsPage}
        />{' '}
        <CustomInput
          name="mrnUrn"
          label="MRN / URN"
          labelStyle="text-xs font-medium"
          value={data.mrnUrn}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>

      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomInput
          name="emiratesId"
          label="Emirates ID"
          value={data.emiratesId}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>

      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomTextArea
          label="Interest"
          name="interest"
          value={data.interest}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>
      {/* 
      {hasInsuranceAccess(user) && (
        <div className="w-full flex items-center gap-3  py-3">
          <Label
            htmlFor="is-insurance-checked"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Insurance Patient
          </Label>
          <Checkbox
            id="is-insurance-checked"
            className=" disabled:bg-gray-500"
            checked={data?.isSentToInsurance}
            disabled={
              isDetailsPage ||
              data?.insuranceClaims?.length >= numberOfInsuranceClaims ||
              (data?.insuranceClaims?.length &&
                data?.insuranceClaims[data.insuranceClaims.length - 1]
                  .status === 'Approved')
            }
            onCheckedChange={(checked) =>
              setData((prev: any) => {
                return {
                  ...prev,
                  isSentToInsurance: !prev.isSentToInsurance,
                }
              })
            }
          />
        </div>
      )} */}

      {hasInsuranceAccess(user) && (
        <div className="w-full flex items-start gap-3  py-3">
          <Label
            htmlFor="is-insurance-checked"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Insurance Type
          </Label>
          <div className="space-y-6 md:ml-10 flex flex-col  items-start">
            <div className="flex justify-center items-center">
              <Button
                className={` transition-all w-20 h-8 rounded-none ${data?.insuranceType == 'Cash' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
                onClick={() => {
                  setData((prev: any) => {
                    return {
                      ...prev,
                      insuranceType: 'Cash',
                    }
                  })
                }}
                disabled={isDetailsPage}
              >
                Cash
              </Button>
              <Button
                className={` transition-all w-20 h-8 rounded-none ${data?.insuranceType == 'Insurance' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
                onClick={() => {
                  setData((prev: any) => {
                    return {
                      ...prev,
                      insuranceType: 'Insurance',
                    }
                  })
                }}
                disabled={isDetailsPage}
              >
                Insurance
              </Button>
            </div>
            {data?.insuranceType == 'Insurance' && (
              <div className="w-full flex items-center gap-3 max-md:flex-col   ">
                <CustomInput
                  name="insuranceNumber"
                  label="Insurance Number"
                  labelStyle="text-xs font-medium"
                  value={data.insuranceNumber}
                  setValue={handleChange}
                  type="text"
                  disabled={isDetailsPage}
                />
                <CustomSelect
                  label="Provider"
                  labelStyle="text-xs font-medium"
                  list={insuranceProviders || []}
                  value={data.insuranceProviderId}
                  setValue={(value: any) =>
                    setData({
                      ...data,
                      insuranceProviderId: value,
                    })
                  }
                  disabled={isDetailsPage}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
