import { useSendWhatsappMutation } from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useState } from 'react'

export default function SendWhatsApp({ leadId }: { leadId: string }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sendWhatsapp, { isLoading }] = useSendWhatsappMutation()

  async function handleSendWhatsApp() {
    setLoading(true)
    await sendWhatsapp({ leadId })
    setLoading(false)
    setOpen(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="outline"
          className="text-xs overflow-hidden text-ellipsis whitespace-normal"
        >
          Send WhatsApp
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <>
          {!loading || !isLoading ? (
            <>
              <AlertDialogHeader>
                <AlertDialogTitle>Is WhatsApp message sent ?</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>No</AlertDialogCancel>
                <AlertDialogAction onClick={handleSendWhatsApp}>
                  Yes
                </AlertDialogAction>
              </AlertDialogFooter>
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </>
      </AlertDialogContent>
    </AlertDialog>
  )
}
