import { useEffect, useState } from 'react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from 'components/ui/menubar'

import { Button } from 'components/ui/button'
import { WhatsappTags } from './whatsapp-conversation-tags'
import { IoPricetagSharp } from 'react-icons/io5'
import { WhatsappConversationManage } from './whatsapp-conversation-manage'
import { TbDotsVertical } from 'react-icons/tb'
import { MdSubtitles } from 'react-icons/md'

export default function WhatsappConversationActions({
  tags,
  name,
  conversationId,
}: any) {
  const [openTags, setOpenTags] = useState(false)
  const [openManageConversation, setOpenManageConversation] = useState(false)

  return (
    <div className="mr-2">
      <Menubar className="  border-none h-10 w-8  z-10   ">
        <MenubarMenu>
          <MenubarTrigger
            className="disabled:text-gray-300 hover:cursor-pointer      p-0    disabled={!isDetailsPage}
 "
          >
            <Button
              variant="ghost"
              className="flex h-8 w-8   p-0 data-[state=open]:bg-muted relative"
            >
              <TbDotsVertical size={18} />
            </Button>
          </MenubarTrigger>
          <MenubarContent className="bg-white border">
            <MenubarItem
              className="hover:cursor-pointer hover:bg-gray-100 "
              onClick={() => setOpenTags(true)}
            >
              Manage Tags
              <MenubarShortcut>
                <IoPricetagSharp size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              className="hover:cursor-pointer hover:bg-gray-100 "
              onClick={() => setOpenManageConversation(true)}
            >
              Rename
              <MenubarShortcut>
                <MdSubtitles size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      {conversationId && openTags && (
        <WhatsappTags
          conversationId={conversationId}
          tags={tags}
          open={openTags}
          setOpen={setOpenTags}
        />
      )}
      {conversationId && openManageConversation && (
        <WhatsappConversationManage
          conversationId={conversationId}
          name={name}
          open={openManageConversation}
          setOpen={setOpenManageConversation}
        />
      )}
    </div>
  )
}
