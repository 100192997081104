import { useGetLeadsQuery } from 'app/features/lead'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import Pagination from 'components/table/pagination'
import { Input } from 'components/ui/input'
import useApp from 'hooks/useApp'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import BulkUpload from './bulk-upload'
import CreateLead from './create-lead'
import { RowAction } from './components/row-action'
import dayjs from 'dayjs'
import { Button } from 'components/ui/button'
import { Link, useNavigate } from 'react-router-dom'
import FiltersForm from 'components/shared/FiltersForm'
import axios, { AxiosError } from 'axios'
import { TbCloudDownload, TbCloudUpload, TbDotsVertical } from 'react-icons/tb'
import { useToast } from 'components/ui/use-toast'
import FiltersFormModal from 'components/shared/FiltersFormModal'
import { Label } from '@radix-ui/react-label'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { shouldFollowUp } from 'lib/allowed'

export default function Leads({ status, statuses }: any) {
  const navigate = useNavigate()
  const { settings, filters: appFilters } = useApp()

  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [numberOfInsuranceClaims, setNumberOfInsuranceClaims] = useState(0)
  const [timeGap, setTimeGap] = useState(0)

  const { user, token } = useSelector((state: any) => state.user)
  const { toast } = useToast()
  const [keyword, setKeyword] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    query: { keyword: '', status, statuses },
    filter: {},
  })

  const { data, isLoading, isFetching, isError } = useGetLeadsQuery(
    { ...filters },
    { refetchOnFocus: true }
  )

  const [openImport, setOpenImport] = useState(false)

  const [isExportAsCsvLoading, setIsExportAsCsvLoading] = useState(false)
  const [exportAsCsvError, setExportAsCsvError] = useState(null)

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setNumberOfInsuranceClaims(settings[0]?.numberOfInsuranceClaims)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])

  useEffect(() => {
    const { ...filter } = appFilters || {}
    setFilters({
      ...filters,
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
      query: { keyword, status, statuses },
      filter,
    })
  }, [currentPage, rowsPerPage, appFilters, status, statuses, keyword])

  useEffect(() => {
    setCurrentPage(1)
    setRowsPerPage(10)
    setKeyword('')
    setFilters({
      skip: 0,
      take: 10,
      query: { keyword: '', status, statuses },
      filter: {},
    })
  }, [status])

  const initiateCsvDownload = (blobData: any) => {
    const url = URL.createObjectURL(blobData)
    const a = document.createElement('a')
    a.href = url
    a.download = 'leads.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  const exportAsCsvHandler = async () => {
    setExportAsCsvError(null)
    setIsExportAsCsvLoading(true)

    try {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_DEV_API_URL
          : process.env.REACT_APP_PRD_API_URL
      const response = await axios.get(
        `${baseUrl}/leads/export-as-csv?filters=${JSON.stringify(filters)}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Call the function to initiate the download
      initiateCsvDownload(response.data)
    } catch (error: any) {
      // Handle error
      toast({
        title: 'Error occurred',
        description:
          error.response.status == 401
            ? 'UnAuthorized'
            : error.response.status == '409'
              ? 'sorry, cannot complete this operation in free trial period or if you do not subscribed yet, please subscribe'
              : 'please try again',
      })
    }
    setIsExportAsCsvLoading(false)
  }

  if (isLoading || !settings)
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="p-3 w-full  h-full space-y-3 overflow-scroll max-md:pb-10 max-md:pr-1">
      <div className="flex items-center justify-between mt-3">
        <div>
          <Input
            type="text"
            placeholder="Search"
            value={keyword}
            className="bg-white"
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-2  ">
          <FiltersFormModal status={status} statuses={statuses} />

          {!window.location.href.includes('insurance') && (
            <Button
              size="sm"
              disabled={isLoading}
              className="w-20"
              onClick={() => navigate(`/create-lead`)}
            >
              New Lead
            </Button>
          )}

          {(user.role === 'ADMIN' ||
            (user?.role === 'ADMIN' &&
              window.location.href.includes('leads'))) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
                >
                  <TbDotsVertical size={18} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[160px]">
                {user.role === 'ADMIN' && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onClick={exportAsCsvHandler}
                    disabled={isExportAsCsvLoading}
                  >
                    <div className="flex gap-3 items-center ">
                      <TbCloudDownload size={15} className=" text-gray-500" />
                      <span>Export</span>
                    </div>
                  </DropdownMenuItem>
                )}

                {user?.role === 'ADMIN' &&
                  window.location.href.includes('leads') && (
                    <DropdownMenuItem
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setOpenImport(true)
                      }}
                    >
                      <div className="flex gap-3 items-center ">
                        <TbCloudUpload size={15} className=" text-gray-500" />
                        <span>Import</span>
                      </div>
                    </DropdownMenuItem>
                  )}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      <div className="border rounded-md bg-white  overflow-hidden overflow-x-scroll ">
        <table
          className={cn(
            isFetching ? 'text-gray-400' : 'text-gray-800',
            'text-sm w-full border-collapse text-[13px]  table-fixed'
          )}
        >
          <thead>
            <tr className="text-left border-b w-full ">
              <th className="xl:w-40 ">Name</th>
              <th className="xl:w-32">Phone</th>
              <th className="xl:w-28">Source</th>
              <th className="xl:w-52 ">Email</th>
              <th className="xl:w-36  whitespace-normal">Added By</th>
              <th className="xl:w-32 whitespace-normal"> Added date</th>
              <th className="xl:w-32 max-md:hidden whitespace-normal">
                Last Follow Up
              </th>
              <th className="xl:w-40 max-md:hidden whitespace-normal">
                Last follow up date
              </th>
              <th className="text-center ">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.leads.map((lead: any) => (
              <tr key={lead.id} className="border-b last:border-b-0">
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                  <Link
                    to={`/lead-details/${lead.id}`}
                    className="text-blue-600 underline"
                  >
                    {lead.firstName} {lead.lastName}
                  </Link>
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {lead.phone}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {lead.source.name}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {lead.email}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {lead.user.firstName} {lead.user.lastName}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                  {dayjs(lead.createdAt).format('DD/MM/YYYY')}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis max-md:hidden">
                  {lead?.followUps[0]?.user.firstName}{' '}
                  {lead?.followUps[0]?.user.lastName}
                </td>
                <td className="overflow-x-hidden whitespace-nowrap text-ellipsis max-md:hidden">
                  {lead.followUps.length > 0
                    ? dayjs(lead?.followUps[0]?.createdAt).format('DD/MM/YYYY')
                    : null}
                </td>
                <td className="flex justify-center">
                  <RowAction
                    status={shouldFollowUp(
                      lead,
                      lead?.followUps[lead.followUps.length - 1],
                      numberOfFollowUps,
                      timeGap,
                      numberOfInsuranceClaims
                    )}
                    lead={lead}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {data && data?.count > 0 && (
          <Pagination
            count={data?.count}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      {window.location.href.includes('leads') && (
        <BulkUpload open={openImport} setOpen={setOpenImport} />
      )}
    </div>
  )
}
