import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'

// Load the necessary plugins
dayjs.extend(require('dayjs/plugin/localeData'))
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(advancedFormat)

// Set the locale to use (e.g., 'en' for English)
dayjs.locale('en')

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isExist(value: any) {
  if (!value) return false
  if (typeof value === 'object' && value.length > 0) {
    return (
      value.filter(
        (item: any) => item === undefined || item === null || item === ''
      ).length === 0
    )
  }
  return value !== undefined || value !== null || value !== ''
}

export function convertTimeGap(value: any, type: any): number {
  if (type === 'MINUTE') return value * 60
  if (type === 'HOUR') return value * 60 * 60
  if (type === 'DAY') return value * 24 * 60 * 60
  if (type === 'WEEK') return value * 24 * 7 * 60 * 60
  else return 0
}

export function reconstructTimeGap(seconds: number) {
  const totalMinutes = Math.floor(seconds / 60)
  const hours = Math.floor(totalMinutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  const remainingMinutes = totalMinutes % 60
  const remainingHours = hours % 24
  const remainingDays = days % 7
  return `Weeks: ${weeks}, Days: ${remainingDays}, Hours: ${remainingHours}, Minutes: ${remainingMinutes}`
}

export function getStartDateAndEndDate(
  selectedDate: string
): { startDate: Dayjs; endDate: Dayjs } | null {
  if (selectedDate === 'Last 30 days') {
    const endDate = dayjs()
    const startDate = endDate.subtract(29, 'day')
    return { startDate, endDate }
  } else if (selectedDate === 'Last month') {
    const startDate = dayjs().subtract(1, 'month').startOf('month')
    const endDate = dayjs().subtract(1, 'month').endOf('month')
    return { startDate, endDate }
  } else if (selectedDate === 'This week') {
    const startDate = dayjs().startOf('week')
    const endDate = dayjs().endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Last week') {
    const startDate = dayjs().subtract(1, 'week').startOf('week')
    const endDate = dayjs().subtract(1, 'week').endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Yesterday') {
    const startDate = dayjs().subtract(1, 'day').startOf('day')
    const endDate = dayjs().subtract(1, 'day').endOf('day')
    return { startDate, endDate }
  } else if (selectedDate === 'Today') {
    const startDate = dayjs().startOf('day')
    const endDate = dayjs().endOf('day')
    return { startDate, endDate }
  } else return null
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (!phoneNumberRegex.test(phoneNumber)) {
    return false
  }
  return true
}

export const cleanPhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')
  if (cleaned.length == 0) return null

  if (phoneNumber?.length && phoneNumber.charAt(0) == '+') {
    cleaned = '+' + cleaned
  }

  if (isPossiblePhoneNumber(cleaned, 'AE') && isValidPhoneNumber(cleaned, 'AE'))
    return cleaned

  cleaned = ('' + phoneNumber).replace(/\D/g, '')

  if (cleaned.length <= 10) {
    if (cleaned.charAt(0) == '0') cleaned = cleaned.substring(1)
    cleaned = '971' + cleaned
  }
  return '+' + cleaned
}

export const validateName = (name: string) => {
  // Check if the name contains only letters and spaces
  const nameRegex = /^[a-zA-Z\s]+$/
  if (!nameRegex.test(name)) {
    return false
  }

  // Check if the name has at least one letter
  if (!/[a-zA-Z]/.test(name)) {
    return false
  }

  return true
}

export const formatPrice = (price: any) => {
  const formatter = new Intl.NumberFormat('en-AE', {
    style: 'currency',
    currency: 'aed',
    currencySign: 'accounting',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(price)
}

export const formatDate = (date: any) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzDate = dayjs(date).tz(tz)
  return tzDate.format('MMMM D, YYYY')
}

export const formatDateAndTime = (date: any) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzDate = dayjs(date).tz(tz)
  return `${tzDate.format('dddd, MMMM D, YYYY h:mm A ')}`
}

export const formatDateAndTimeWithoutDay = (date: any) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzDate = dayjs(date).tz(tz)
  return `${tzDate.format(' MMMM D, YYYY h:mm A ')}`
}

export const isDatePastThan = (date: any, minutes: number = 2) => {
  const currentTime = new Date()
  const pastMinutes = new Date(currentTime.getTime() - minutes * 60 * 1000)
  try {
    const dateInMilliseconds = new Date(date)

    return dateInMilliseconds < pastMinutes
  } catch (error) {
    console.error('Error checking date:', error)
    return false
  }
}

export const isTimestampPastThan = (
  timestamp: string,
  minutes: number = 60 * 24
) => {
  const currentTime = new Date()
  const pastMinutes = new Date(currentTime.getTime() - minutes * 60 * 1000)
  const dateInMilliseconds = new Date(parseInt(timestamp))
  try {
    return dateInMilliseconds < pastMinutes
  } catch (error) {
    console.error('Error checking date:', error)
    return false
  }
}

export const prepareMediaFormData = (data: Record<string, any>) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const containsSubstring = (source: string, target: string) => {
  const lowerSource = source.toLowerCase()
  const lowerTarget = target.toLowerCase()
  return lowerSource.includes(lowerTarget)
}

export const replacePlaceholdersByIndices = (str: string) => {
  if (!str) return ''
  let index = 1
  return str.replace(/{{\w+}}/g, () => `{{${index++}}}`)
}

export const extractPlaceholdersIntoArray = (str: string) => {
  if (!str) return ''
  const matches = str.match(/{{(\w+)}}/g)
  return matches ? matches.map((match) => match.replace(/{{|}}/g, '')) : []
}

export const convertToCamelCase = (str: string) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export const convertToTitleCase = (str: string) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const convertCamelCaseToTitleCase = (str: string) => {
  if (!str) return ''
  const result = str.replace(/([A-Z])/g, ' $1').trim()
  return result
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const replaceAndConvertPlaceholders = (
  template: string,
  values: string[]
) => {
  if (!template) return ''
  const placeholders = template.match(/{{\w+}}/g)

  if (!placeholders) return template
  return placeholders.reduce((result, placeholder, index) => {
    return result.replace(placeholder, values[index] || '')
  }, template)
}

export const formatTime = (seconds: number) => {
  if (seconds == 0) return ''
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  return `${minutes} m ${remainingSeconds} s`
}
