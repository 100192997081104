import { useDisableUserMutation } from 'app/features/users'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

export default function DisableUser({ user, open, setOpen }: any) {
  const [disableUser, { isLoading }] = useDisableUserMutation()

  async function handleDisable() {
    await disableUser(user.id)
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change user status</AlertDialogTitle>
          <AlertDialogDescription>
            {user.disabled
              ? 'By enabling the user will be able to login to their account.'
              : 'By disabling the user will not be able to login to their account.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            variant={user.disabled ? 'default' : 'destructive'}
            disabled={isLoading}
            size="sm"
            onClick={handleDisable}
          >
            {user.disabled ? 'Enable' : 'Disable'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
