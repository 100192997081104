import { api } from '../api'

type ConversationData = {
  data: {
    id: string
    link: string
    updated_time: Date
  }[]
}

type Conversation = {
  id: string
  userId: string
  name: string
  leadId: string
  metaId: string
  messages: Message[]
}

type Message = {
  id: string
  metaId: string
  created_time: Date
}

type Contact = {
  id: string
  name: string
  metaId: string
}

type ContactResponse = Contact[]

type MetaMessage = {
  id: string
  to: {
    data: [
      {
        name: string
        email: string
        id: string
      },
    ]
  }
  from: {
    name: string
    email: string
    id: string
  }
  message: string
}

export const metaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetaContacts: builder.query<ContactResponse, void>({
      query: () => '/meta',
    }),
    getMetaConversations: builder.query<ConversationData, void>({
      query: () => `/meta/facebook-messages`,
      providesTags: ['MetaConversations'],
    }),
    getMetaConversation: builder.query<Conversation, string | undefined>({
      query: (id) => `/meta/${id}`,
      providesTags: ['MetaConversations'],
    }),
    getMetaMessage: builder.query<MetaMessage, string | undefined>({
      query: (id) => `/meta/facebook-message/${id}`,
    }),
    sendMetaMessage: builder.mutation({
      query: (message) => ({
        url: '/meta/message',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [{ type: 'MetaConversations' }],
    }),
    initMetaAccount: builder.mutation({
      query: (data) => ({
        url: `/meta/account`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetMetaContactsQuery,
  useGetMetaConversationQuery,
  useGetMetaMessageQuery,
  useGetMetaConversationsQuery,
  useSendMetaMessageMutation,
  useInitMetaAccountMutation,
} = metaApi
