import { useState, FormEvent, useEffect } from 'react'
import DateAndTime from './date-and-time'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { Label } from '@radix-ui/react-label'
import MySelect from './select/MySelect'
import dayjs from 'dayjs'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { getStartDateAndEndDate } from 'lib/utils'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import NotQualifiedInput from './notqualified-input'
import { useGetInsuranceStatusesQuery } from 'app/features/settings'

export default function FiltersFormModal({ status, statuses }: any) {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [selectedDate, setSelectedDate] = useState<string>('All')

  const [free, setFree] = useState('All')
  const [selectedBranches, setSelectedBranches] = useState<any>([])
  const [selectedDepartments, setSelectedDepartments] = useState<any>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<any>([])

  const [notQualifiedReason, setNotQualifiedReason] = useState<string | null>(
    null
  )
  const [insuranceType, setInsuranceType] = useState('All')
  const [recentInsuranceClaimStatus, setRecentInsuranceClaimStatus] =
    useState('All')
  const [customNotQualifiedReason, setCustomNotQualifiedReason] = useState('')

  const [selectedSources, setSelectedSources] = useState<any>([])

  const {
    setFilters,
    branches,
    departments,
    specialists,
    sources,
    notQualifiedReasons,
  } = useApp()

  const [keyword, setKeyword] = useState('')

  const [open, setOpen] = useState(false)

  const {
    data: insuranceStatuses,
    isError,
    isLoading: insuranceStatusesLoading,
  } = useGetInsuranceStatusesQuery()

  useEffect(() => {
    handleClearFilters()
  }, [status, statuses])

  const [notQualifiedReasonsList, setList] = useState<any>([])
  useEffect(() => {
    if (notQualifiedReasons)
      setList([...notQualifiedReasons, { id: 'other', name: 'Other...' }])
  }, [notQualifiedReasons])

  // TODO: to be deleted
  // const [departmentsData, setDepartmentsData] = useState<any>(departments)
  // const [specialtiesData, setSpecialtiesData] = useState<any>(specialists)

  useEffect(() => {
    // TODO: to be deleted
    // setDepartmentsData([])
    // setSpecialtiesData([])

    // selectedBranches.forEach((branch: { departments: any[] }) => {
    //   setDepartmentsData((prevDepartmentsData: any) => [
    //     ...prevDepartmentsData,
    //     ...branch.departments,
    //   ])

    //   branch.departments?.forEach((department: { specialists: any }) => {
    //     setSpecialtiesData((prevSpecialistsData: any) => [
    //       ...prevSpecialistsData,
    //       ...department.specialists,
    //     ])
    //   })
    // })
    // if (selectedBranches.length === 0) {
    //   setDepartmentsData(departments)
    //   setSpecialtiesData(specialists)
    //   setSelectedDepartments([])
    //   setSelectedSpecialties([])
    // }

    const filteredDepartments = selectedDepartments.filter((department: any) =>
      selectedBranches.some((branch: any) =>
        branch.departments.some(
          (branchDepartment: any) => department.id === branchDepartment.id
        )
      )
    )

    const filteredSpecialists = selectedSpecialties.filter(
      (specialist: { id: any }) =>
        selectedBranches.some((branch: { departments: any[] }) =>
          branch.departments.some(
            (departmentSpecialist: { specialists: any[] }) =>
              departmentSpecialist.specialists.some(
                (item: any) => specialist.id === item.id
              )
          )
        )
    )

    setSelectedDepartments(filteredDepartments)
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedBranches])

  useEffect(() => {
    // TODO: to be deleted
    // setSpecialtiesData([])
    // selectedDepartments.forEach((department: { specialists: any[] }) => {
    //   setSpecialtiesData((prevSpecialistsData: any) => [
    //     ...prevSpecialistsData,
    //     ...department.specialists,
    //   ])
    // })
    // if (selectedDepartments.length === 0) {
    //   setSpecialtiesData(specialists)
    //   setSelectedSpecialties([])
    // }

    const filteredSpecialists = selectedSpecialties.filter((specialist: any) =>
      selectedDepartments.some((department: any) =>
        department.specialists.some(
          (departmentSpecialist: any) =>
            specialist.id === departmentSpecialist.id
        )
      )
    )
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedDepartments])

  useEffect(() => {
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      startDate: startDate
        ? dayjs(startDate)
            .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(startMinute))
            .toDate()
        : startDateValue,
      endDate: endDate
        ? dayjs(endDate)
            .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(endMinute))
            .toDate()
        : endDateValue,
    })
  }, [])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      keyword: keyword,
      ...(selectedDate != 'All' && {
        startDate: startDate
          ? dayjs(startDate)
              .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(startMinute))
              .toDate()
          : startDateValue,
        endDate: endDate
          ? dayjs(endDate)
              .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(endMinute))
              .toDate()
          : endDateValue,
      }),
      ...(free != 'All' && {
        free: true,
      }),
      branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
      departmentIDs: selectedDepartments.map(
        (department: { id: any }) => department.id
      ),
      specialistIDs: selectedSpecialties.map(
        (specialist: { id: any }) => specialist.id
      ),
      sourceIDs: selectedSources.map((source: { id: any }) => source.id),
      ...(status === 'NOT_QUALIFIED' &&
        (customNotQualifiedReason || notQualifiedReason) && {
          notQualifiedReason:
            customNotQualifiedReason ||
            notQualifiedReasonsList.find(
              (item: any) => item.id === notQualifiedReason
            ).name,
        }),
      ...(insuranceType != 'All' && {
        insuranceType,
      }),
      ...(recentInsuranceClaimStatus != 'All' && {
        recentInsuranceClaimStatus,
      }),
    })
    setOpen(false)
  }

  const handleClearFilters = () => {
    setKeyword('')
    setSelectedSources([])
    setSelectedBranches([])
    setSelectedDepartments([])
    setSelectedSpecialties([])
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
    setFilters(null)
    setSelectedDate('All')
    setInsuranceType('All')
    setCustomNotQualifiedReason(''), setNotQualifiedReason(null)
    //setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button size="sm" className="w-20">
          Filter
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="bg-white dark:bg-gray-900 md:min-w-[60rem] sm:w-full max-md:h-full overflow-y-scroll">
        <div className="flex justify-end">
          <Button variant="outline" size="icon" onClick={() => setOpen(false)}>
            <Cross1Icon className="h-3 w-3" />
          </Button>
        </div>
        <div className="w-full rounded-lg py-6 px-3 bg-white dark:bg-gray-900 mb-3">
          <form
            onSubmit={handleSubmit}
            className="flex flex-row flex-wrap items-center max-md:flex-col "
          >
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Free Leads</Label>
                <Select
                  value={free}
                  onValueChange={(value: any) => setFree(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>{free}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Free Only">Free Only</SelectItem>
                      <SelectItem value="All">All</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Branches</Label>
                <MySelect
                  selected={selectedBranches}
                  setSelected={setSelectedBranches}
                  data={branches}
                  selectMeta="branch"
                  disabled={free != 'All'}
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Departments</Label>
                <MySelect
                  selected={selectedDepartments}
                  setSelected={setSelectedDepartments}
                  // data={departmentsData}
                  data={departments.filter((department: any) =>
                    selectedBranches
                      .map((branch: any) => branch.id)
                      .includes(department.branchId)
                  )}
                  selectMeta="department"
                  disabled={free != 'All'}
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Specialists</Label>
                <MySelect
                  selected={selectedSpecialties}
                  setSelected={setSelectedSpecialties}
                  //data={specialtiesData}
                  data={specialists.filter((specialist: any) => {
                    return selectedDepartments.length
                      ? selectedDepartments
                          .map((department: any) => department.id)
                          .includes(specialist.departmentId) &&
                          !specialist.disabled
                      : selectedBranches
                          .map((branch: any) => branch.id)
                          .includes(specialist.branchId) && !specialist.disabled
                  })}
                  selectMeta="specialist"
                  disabled={free != 'All'}
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <Label className="text-sm font-semibold">Sources</Label>
                <MySelect
                  selected={selectedSources}
                  setSelected={setSelectedSources}
                  data={sources}
                  selectMeta="source"
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Date</Label>
                <Select
                  value={selectedDate}
                  onValueChange={(value: any) => setSelectedDate(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>{selectedDate}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Last 30 days">Last 30 days</SelectItem>
                      <SelectItem value="Last month">Last month</SelectItem>
                      <SelectItem value="This week">This week</SelectItem>
                      <SelectItem value="Last week">Last week</SelectItem>
                      <SelectItem value="Today">Today</SelectItem>
                      <SelectItem value="Yesterday">Yesterday</SelectItem>
                      <SelectItem value="Custom date">Custom Date</SelectItem>
                      <SelectItem value="All">All</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Start Date:</Label>
                <DateAndTime
                  date={startDate}
                  setDate={setStartDate}
                  hour={startHour}
                  setHour={setStartHour}
                  minute={startMinute}
                  setMinute={setStartMinute}
                  ampm={startAmpm}
                  setAmpm={setStartAmpm}
                  disabled={!(selectedDate === 'Custom date')}
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">End Date:</Label>
                <DateAndTime
                  date={endDate}
                  setDate={setEndDate}
                  hour={endHour}
                  setHour={setEndHour}
                  minute={endMinute}
                  setMinute={setEndMinute}
                  ampm={endAmpm}
                  setAmpm={setEndAmpm}
                  beforeSelectedDate={startDate}
                  disabled={!(selectedDate === 'Custom date')}
                />
              </div>
            </div>
            <div className="w-1/3 p-4 max-md:w-full">
              <div className="space-y-2">
                <Label className="text-sm font-semibold">Insurance Type</Label>
                <Select
                  value={insuranceType}
                  onValueChange={(value: any) => setInsuranceType(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>{insuranceType}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="Cash">Cash</SelectItem>
                      <SelectItem value="Insurance">Insurance</SelectItem>
                      <SelectItem value="All">All</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {status === 'Insurance' && (
              <div className="w-1/3 p-4 max-md:w-full">
                <div className="space-y-2">
                  {' '}
                  <Label className="text-sm font-semibold">
                    Insurance Status
                  </Label>
                  <Select
                    value={recentInsuranceClaimStatus}
                    onValueChange={(value: any) =>
                      setRecentInsuranceClaimStatus(value)
                    }
                  >
                    <SelectTrigger className="w-full ">
                      <SelectValue>{recentInsuranceClaimStatus}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {insuranceStatuses?.map((insuranceStatus: any) => {
                          return (
                            <SelectItem value={insuranceStatus.name}>
                              {insuranceStatus.name}
                            </SelectItem>
                          )
                        })}
                        <SelectItem value="All">All</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            {status === 'NOT_QUALIFIED' && (
              <div className="w-1/3 p-4 max-md:w-full">
                <div className="space-y-2 flex flex-col">
                  <NotQualifiedInput
                    reason={notQualifiedReason}
                    setReason={setNotQualifiedReason}
                    customReason={customNotQualifiedReason}
                    setCustomReason={setCustomNotQualifiedReason}
                    list={notQualifiedReasonsList}
                  />
                </div>
              </div>
            )}

            <div className="w-full p-4 flex justify-end">
              <div className="space-x-2 mt-7">
                <Button size="sm">Apply Filters</Button>
                <Button size="sm" type="button" onClick={handleClearFilters}>
                  Clear Filters
                </Button>
              </div>
            </div>
          </form>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
