import { Font, StyleSheet, Text, View, Image } from '@react-pdf/renderer'
import reportFont from 'assets/fonts/report-font.ttf'
import reportBackground from 'assets/report-bg.jpg'
import { toPng } from 'html-to-image'
import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import Loader from 'components/shared/loader'

Font.register({
  family: 'Bebas Neue',
  src: reportFont,
})

const styles = StyleSheet.create({
  body: {
    backgroundImage: `url(${reportBackground})`,
    margin: 0,
    padding: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  title: {
    fontSize: 30,
    color: '#00B4EF',
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subtitle: {
    fontSize: 15,
    color: '#4b5563',
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 5,
  },
  boxNumber: {
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
    fontSize: 50,
  },
  boxText: {
    fontSize: 20,
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
})

export default function LeadsByBooking({ data }: any) {
  return (
    <>
      <View style={{ paddingTop: 50 }}>
        <Text style={{ ...styles.title }}>LEADS BY BOOKING</Text>
        <View
          style={{
            marginTop: 20,
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            gap: 10,
          }}
        >
          <View style={{ ...styles.box, borderBottomColor: '#2761D7' }}>
            <Text style={{ ...styles.boxNumber, color: '#2761D7' }}>
              {data?.bookedLeads}
            </Text>
            <Text style={{ ...styles.boxText, color: '#2761D7' }}>Booked</Text>
          </View>

          <View style={{ ...styles.box, borderBottomColor: '#2DB58A' }}>
            <Text style={{ ...styles.boxNumber, color: '#2DB58A' }}>
              {data?.showLeads}
            </Text>
            <Text style={{ ...styles.boxText, color: '#2DB58A' }}>Show</Text>
          </View>

          <View style={{ ...styles.box, borderBottomColor: '#E68D30' }}>
            <Text style={{ ...styles.boxNumber, color: '#E68D30' }}>
              {data?.noShowLeads}
            </Text>
            <Text style={{ ...styles.boxText, color: '#E68D30' }}>No Show</Text>
          </View>

          <View style={{ ...styles.box, borderBottomColor: '#E2366E' }}>
            <Text style={{ ...styles.boxNumber, color: '#E2366E' }}>
              {data?.notQualifiedLeads}
            </Text>
            <Text style={{ ...styles.boxText, color: '#E2366E' }}>
              Not Qualified
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}
