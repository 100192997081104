import { Button, buttonVariants } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Textarea } from 'components/ui/textarea'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, isTimestampPastThan } from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { TbFile, TbMicrophone, TbPhoto, TbPlus, TbVideo } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import RecordMessage from './record-message'
import TemplateList from './template-list'
import Loader from 'components/shared/loader'
import { useToast } from 'components/ui/use-toast'
import { RiErrorWarningFill } from 'react-icons/ri'

interface ChatBottomBarProps {
  phoneNumber?: string
  lead: any
  lastReply?: any
  sendMessage: (newMessage: any) => void
  sendMedia: (newMessage: any) => void
  sendTemplate: (template: any) => void
}
type Record = {
  id: number
  name: string
  file: any
}

export default function ChatBottomBar({
  phoneNumber,
  lead,
  lastReply,
  sendMessage,
  sendMedia,
  sendTemplate,
}: ChatBottomBarProps) {
  const { toast } = useToast()

  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [isRecording, setIsRecording] = useState(false)
  const [isRecordingPaused, setIsRecordingPaused] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationRef = useRef<any>(null)
  let recorder: MediaRecorder
  let recordingChunks: BlobPart[] = []

  const mediaRecorderRef = useRef<{
    stream: MediaStream | null
    analyser: AnalyserNode | null
    mediaRecorder: MediaRecorder | null
    audioContext: AudioContext | null
  }>({
    stream: null,
    analyser: null,
    mediaRecorder: null,
    audioContext: null,
  })

  useEffect(() => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current
    const canvasCtx = canvas.getContext('2d')
    const WIDTH = canvas.width
    const HEIGHT = canvas.height

    const drawWaveform = (dataArray: Uint8Array) => {
      if (!canvasCtx) return
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      canvasCtx.fillStyle = '#939393'

      const barWidth = 1
      const spacing = 1
      const maxBarHeight = HEIGHT / 2.5
      const numBars = Math.floor(WIDTH / (barWidth + spacing))

      for (let i = 0; i < numBars; i++) {
        const barHeight = Math.pow(dataArray[i] / 128.0, 8) * maxBarHeight
        const x = (barWidth + spacing) * i
        const y = HEIGHT / 2 - barHeight / 2
        canvasCtx.fillRect(x, y, barWidth, barHeight)
      }
    }
    const visualizeVolume = () => {
      if (
        !mediaRecorderRef.current?.stream?.getAudioTracks()[0]?.getSettings()
          .sampleRate
      )
        return
      const bufferLength =
        (mediaRecorderRef.current?.stream?.getAudioTracks()[0]?.getSettings()
          .sampleRate as number) / 100
      const dataArray = new Uint8Array(bufferLength)

      const draw = () => {
        if (!isRecording) {
          cancelAnimationFrame(animationRef.current || 0)
          return
        }
        if (isRecordingPaused) {
          return
        }
        animationRef.current = requestAnimationFrame(draw)
        mediaRecorderRef.current?.analyser?.getByteTimeDomainData(dataArray)
        drawWaveform(dataArray)
      }

      draw()
    }

    if (isRecording) {
      visualizeVolume()
    } else {
      if (canvasCtx) {
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      }
      cancelAnimationFrame(animationRef.current || 0)
    }

    return () => {
      cancelAnimationFrame(animationRef.current || 0)
    }
  }, [isRecording, isRecordingPaused])

  const [currentRecord, setCurrentRecord] = useState<Record>({
    id: -1,
    name: '',
    file: null,
  })

  const documentAcceptedFileTypes = [
    'audio/aac',
    'audio/mp4',
    'audio/mpeg',
    'audio/amr',
    'audio/ogg',
    'audio/opus',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/plain',
    'application/vnd.ms-excel',
  ]

  const handleFileUpload = async (event: any) => {
    const files = event.target.files
    const filesArray = [...files]

    for (let i = 0; i < filesArray.length; i++) {
      if (!documentAcceptedFileTypes.includes(filesArray[i].type)) {
        toast({
          title: 'Error occurred',
          description: 'Unsupported file type',
        })
        return
      }
    }

    toast({
      title: 'Uploading media',
      description: 'File(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'DOCUMENT' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }
  }

  const handleImageUpload = async (event: any) => {
    const files = event.target.files || []
    setIsUploading(true)
    const filesArray = [...files]

    toast({
      title: 'Uploading media',
      description: 'Image(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'IMAGE' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }

    setIsUploading(false)
    setIsPopoverOpen(false)
  }

  const handleVideoUpload = async (event: any) => {
    const files = event.target.files

    const filesArray = [...files]

    toast({
      title: 'Uploading media',
      description: 'Video(s) are being uploaded',
    })

    try {
      await Promise.all(
        filesArray.map((file: File) => sendMedia({ file, type: 'VIDEO' }))
      )
    } catch (error) {
      console.log(error)
      toast({
        title: 'Error occurred',
        description: 'Failed to upload media',
      })
    }
  }

  function startRecording() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          setIsRecording(true)
          setIsRecordingPaused(false)
          // ============ Analyzing ============
          const AudioContext = window.AudioContext
          const audioCtx = new AudioContext()
          const analyser = audioCtx.createAnalyser()
          const source = audioCtx.createMediaStreamSource(stream)
          source.connect(analyser)
          mediaRecorderRef.current = {
            stream,
            analyser,
            mediaRecorder: null,
            audioContext: audioCtx,
          }

          const mimeType = MediaRecorder.isTypeSupported('audio/mpeg')
            ? 'audio/mpeg'
            : MediaRecorder.isTypeSupported('audio/webm')
              ? 'audio/webm'
              : 'audio/wav'

          const options = { mimeType }
          mediaRecorderRef.current.mediaRecorder = new MediaRecorder(
            stream,
            options
          )
          mediaRecorderRef.current.mediaRecorder.start()
          recordingChunks = []
          // ============ Recording ============
          recorder = new MediaRecorder(stream)
          recorder.start()
          recorder.ondataavailable = (e) => {
            recordingChunks.push(e.data)
            console.log('recordingChunks ', recordingChunks)
            const recordBlob = new Blob(recordingChunks, {
              type: 'audio/ogg; codecs=opus',
            })
            console.log('recordBlob 1', recordBlob)
            const file = new File([recordBlob], 'recorded_audio.ogg', {
              type: 'audio/ogg; codecs=opus',
            })
            console.log('my_file 1', file)
            sendMedia({ file, type: 'AUDIO' })
          }

          // recorder.onstop = () => {
          //   const recordBlob = new Blob(recordingChunks, {
          //     type: 'audio/ogg; codecs=opus',
          //   })
          //   console.log('recordBlob 1', recordBlob)
          //   const file = new File([recordBlob], 'recorded_audio.ogg', {
          //     type: 'audio/ogg',
          //   })
          //   console.log('my_file 1', file)
          //   sendMedia({ file, type: 'AUDIO' })
          // }
        })
        .catch((error) => {
          alert(error)
          console.log(error)
        })
    }
  }

  function pauseRecording() {
    const { mediaRecorder } = mediaRecorderRef.current

    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause()
      setIsRecordingPaused(true)
    }
  }

  function resumeRecording() {
    const { mediaRecorder } = mediaRecorderRef.current

    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume()
      setIsRecordingPaused(false)
    }
  }

  // function stopRecording() {
  //   console.log('here!!!')
  //   // try {
  //   const { mediaRecorder, stream, analyser, audioContext } =
  //     mediaRecorderRef.current

  //   if (mediaRecorder) {
  //     mediaRecorder.onstop = () => {
  //       const recordBlob = new Blob(recordingChunks, {
  //         type: 'audio/mpeg',
  //       })
  //       setCurrentRecord({
  //         ...currentRecord,
  //         file: window.URL.createObjectURL(recordBlob),
  //       })
  //       recordingChunks = []
  //       console.log('recordBlob ', recordingChunks)

  //       // sendMedia({ recordBlob, type: 'AUDIO' })
  //     }
  //     mediaRecorder.stop()
  //   }

  //   setIsRecording(false)
  //   setIsRecordingPaused(false)
  // }

  function stopRecording() {
    const { mediaRecorder, stream, analyser, audioContext } =
      mediaRecorderRef.current
    if (mediaRecorder && stream) {
      mediaRecorder.stop()

      const audioTracks = mediaRecorder.stream.getAudioTracks()
      audioTracks.forEach((track) => {
        track.stop()
      })

      const blob = new Blob(recordingChunks, { type: 'audio/webm' })
      const url = URL.createObjectURL(blob)

      // Send the recorded audio data (blob or url) to the desired destination
      console.log('recordBlob ', recordingChunks)
      setIsRecording(false)
      setIsRecordingPaused(false)
    }
  }

  function resetRecording() {
    const { mediaRecorder, stream, analyser, audioContext } =
      mediaRecorderRef.current

    if (mediaRecorder) {
      mediaRecorder.onstop = () => {
        recordingChunks = []
      }
      mediaRecorder.stop()
    } else {
      alert('recorder instance is null!')
    }

    // Stop the web audio context and the analyser node
    if (analyser) {
      analyser.disconnect()
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
    }
    if (audioContext) {
      audioContext.close()
    }
    setIsRecording(false)
    setIsRecordingPaused(false)

    // Clear the animation frame and canvas
    cancelAnimationFrame(animationRef.current || 0)
    const canvas = canvasRef.current
    if (canvas) {
      const canvasCtx = canvas.getContext('2d')
      if (canvasCtx) {
        const WIDTH = canvas.width
        const HEIGHT = canvas.height
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  const handleSend = () => {
    if (message.trim()) {
      const newMessage = message.trim()
      sendMessage(newMessage)
      setMessage('')

      if (inputRef.current) {
        inputRef.current.focus()
      }
    } else if (isRecording) {
      stopRecording()
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSend()
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault()
      setMessage((prev) => prev + '\n')
    }
  }

  const onClickImageUpload = () => {
    document.getElementById('imageUpload')?.click()
  }

  const onClickVideoUpload = () => {
    document.getElementById('videoUpload')?.click()
  }

  const onClickDocumentUpload = () => {
    document.getElementById('fileUpload')?.click()
  }

  return (
    <div className="absolute bottom-0 right-0 left-0 p-3 bg-white border-t">
      <div className="w-full bg-white rounded-xl flex items-center gap-3">
        {lastReply && !isTimestampPastThan(lastReply, 24 * 60) && (
          <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <PopoverTrigger asChild>
              <Link
                to="#"
                className={cn(
                  buttonVariants({ variant: 'ghost', size: 'icon' }),
                  'h-9 w-9',
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                )}
              >
                {/* add spinner for loading */}
                {isUploading ? (
                  <Loader />
                ) : (
                  <TbPlus size={20} className="text-muted-foreground" />
                )}
              </Link>
            </PopoverTrigger>
            <PopoverContent side="top" className="w-full p-1">
              <div style={{ minWidth: '100px' }}>
                <div>
                  <Button
                    className="w-full justify-start"
                    variant="ghost"
                    onClick={onClickImageUpload}
                  >
                    <TbPhoto className="mr-2 text-blue-500 text-xl" />
                    <span>Images</span>
                  </Button>
                </div>
                <div>
                  <Button
                    className="w-full justify-start"
                    variant="ghost"
                    onClick={onClickVideoUpload}
                  >
                    <TbVideo className="mr-2 text-red-500 text-xl" />
                    <span>Videos</span>
                  </Button>
                </div>
                <div>
                  <Button
                    className="w-full justify-start"
                    variant="ghost"
                    onClick={onClickDocumentUpload}
                  >
                    <TbFile className="mr-2 text-green-500 text-xl" />
                    <span>Documents</span>
                  </Button>
                </div>

                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                  multiple
                />

                <input
                  type="file"
                  accept="video/*"
                  id="videoUpload"
                  style={{ display: 'none' }}
                  onChange={handleVideoUpload}
                  multiple
                />
                <input
                  type="file"
                  id="fileUpload"
                  accept={documentAcceptedFileTypes.join(',')}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  multiple
                />
              </div>
            </PopoverContent>
          </Popover>
        )}
        <TemplateList
          sendTemplate={sendTemplate}
          phoneNumber={phoneNumber}
          lead={lead}
        />{' '}
        {/* Search */}
        {lastReply && !isTimestampPastThan(lastReply, 24 * 60) ? (
          <AnimatePresence initial={false}>
            <motion.div
              key="input"
              className="w-full relative"
              layout
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1 }}
              transition={{
                opacity: { duration: 0.05 },
                layout: {
                  type: 'spring',
                  bounce: 0.15,
                },
              }}
            >
              {isRecording ? (
                <RecordMessage
                  isRecording={isRecording}
                  isRecordingPaused={isRecordingPaused}
                  resetRecording={resetRecording}
                  canvasRef={canvasRef}
                />
              ) : (
                <Textarea
                  autoComplete="off"
                  value={message}
                  ref={inputRef}
                  onKeyDown={handleKeyPress}
                  onChange={handleInputChange}
                  name="message"
                  placeholder="Aa"
                  className="bg-white min-h-10"
                  rows={1}
                ></Textarea>
              )}
            </motion.div>
            {message.trim() || isRecording ? (
              <Link
                to="#"
                className={cn(
                  buttonVariants({ variant: 'ghost', size: 'icon' }),
                  'h-9 w-9',
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0'
                )}
                onClick={handleSend}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  width={24}
                  height={24}
                  strokeWidth={2}
                >
                  {' '}
                  <path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z"></path>{' '}
                  <path d="M6.5 12h14.5"></path>{' '}
                </svg>
              </Link>
            ) : (
              <></>
              // <TbMicrophone
              //   className="text-muted-foreground h-8 w-8 lg:h-6 lg:w-6"
              //   onClick={startRecording}
              // />
            )}
          </AnimatePresence>
        ) : (
          <div className="flex justify-between items-center  p-2 px-4   gap-2 rounded-md bg-gray-200 text-gray-600">
            <RiErrorWarningFill className="text-muted-foreground h-8 w-8 lg:h-5 lg:w-5" />
            <span className="text-center text-xs">
              This user has not sent a message in the past 24 hours. You can
              still interact with them, but they will only receive template
              messages.
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
