import {
  useClaimLeadMutation,
  useFollowUpMutation,
  useGetLeadQuery,
  useLazyGetLeadQuery,
} from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/textarea'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { convertTimeGap, isDatePastThan } from 'lib/utils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Checkbox } from 'components/ui/checkbox'
import { Label } from 'components/ui/label'
import { shouldBook } from 'lib/allowed'

export default function FollowUp({ open, setOpen, leadId, openBook }: any) {
  const { settings } = useApp()
  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [numberOfInsuranceClaims, setNumberOfInsuranceClaims] = useState(0)

  const { user } = useSelector((state: any) => state.user)
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [followUp, { isLoading }] = useFollowUpMutation()
  const [nextFollowUpDate, setNextFollowUpDate] = useState({
    nextFollowUpTimeGap: 0,
    nextFollowUptimeAccessValue: 0,
    nextFollowUptimeAccessType: '',
  })
  const [nextFollowUpEnabled, setNextFollowUpEnabled] = useState(false)

  const [claimLead] = useClaimLeadMutation()
  // const {
  //   data: lead,
  //   isLoading: isLoadingLead,
  //   isSuccess,
  // } = useGetLeadQuery(leadId, { skip: true })

  // useEffect(() => {
  //   if (
  //     isSuccess &&
  //     lead &&
  //     (!lead?.claimed || isDatePastThan(lead?.updatedAt))
  //   ) {
  //     claimLead({ id: leadId, data: true })
  //   }
  // }, [isSuccess])

  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setNumberOfInsuranceClaims(settings[0]?.numberOfInsuranceClaims)
    }
  }, [settings])

  useEffect(() => {
    const fetch = async () => {
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }
    }
    fetch()
  }, [])
  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setComment('')
    setError('')
  }, [open])

  useEffect(() => {
    if (
      error == 'Please add valid follow up time or deselect the Follow up' ||
      error == "Please add valid time, time should not start with '0'"
    )
      setError('')
  }, [nextFollowUpEnabled])

  async function addFollowUp() {
    setError('')
    if (comment === '') {
      setError('Please add a comment')
      return
    }

    if (nextFollowUpEnabled) {
      if (nextFollowUpDate.nextFollowUpTimeGap == 0) {
        setError('Please add valid follow up time or deselect the Follow up')
        return
      }
      if (
        (nextFollowUpDate?.nextFollowUptimeAccessValue)
          .toString()
          .startsWith('0')
      ) {
        setError("Please add valid time, time should not start with '0'")
        return
      }
    }

    const list = [
      { id: 1, name: 'Price is high' },
      { id: 2, name: 'Booked with another clinic' },
      { id: 3, name: 'Looking for a job' },
      { id: 4, name: 'Not interested anymore' },
      { id: 5, name: 'Other...' },
    ]

    try {
      await followUp({
        leadId: lead.id,
        comment,
        index:
          lead?.followUps?.length > 0
            ? lead?.followUps[lead?.followUps.length - 1]?.index + 1
            : 1,
        userId: user.id,
        nextFollowUpDate: nextFollowUpEnabled
          ? nextFollowUpDate
          : {
              nextFollowUpTimeGap: 0,
              nextFollowUptimeAccessValue: 0,
              nextFollowUptimeAccessType: '',
            },
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  useEffect(() => {
    setNextFollowUpDate({
      ...nextFollowUpDate,
      nextFollowUpTimeGap:
        nextFollowUpDate.nextFollowUptimeAccessValue &&
        nextFollowUpDate.nextFollowUptimeAccessType
          ? convertTimeGap(
              nextFollowUpDate.nextFollowUptimeAccessValue,
              nextFollowUpDate.nextFollowUptimeAccessType
            )
          : 0,
    })
  }, [
    nextFollowUpDate.nextFollowUptimeAccessValue,
    nextFollowUpDate.nextFollowUptimeAccessType,
  ])

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        {(isLoading || isLoadingLead) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            {lead && (
              <>
                <AlertDialogHeader>
                  <div className="flex items-center justify-between text-black">
                    <h1 className="text-lg font-semibold">
                      {lead.firstName} {lead.lastName}
                    </h1>
                    <div className="text-xs font-light py-1 px-2 bg-indigo-200 rounded-md text-indigo-600">
                      Follow up{' '}
                      {lead?.followUps?.length > 0
                        ? lead?.followUps[lead?.followUps.length - 1]?.index + 1
                        : 1}
                    </div>
                  </div>
                  <p className="text-gray-500 text-xs">{lead.phone}</p>
                  <p className="text-gray-500 text-xs">{lead.email}</p>
                  {error !== '' && (
                    <p className="text-red-500 text-xs">{error}</p>
                  )}
                  <Textarea
                    className="mt-5"
                    id="nex-follow-up-checkbox"
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder="Add comment"
                  />
                  <div className="items-top flex space-x-2 pt-3">
                    <Checkbox
                      id="next-follow-up-checkbox"
                      checked={nextFollowUpEnabled}
                      onCheckedChange={(checked) =>
                        setNextFollowUpEnabled(!nextFollowUpEnabled)
                      }
                    />
                    <div className="grid gap-1.5 leading-none">
                      <Label
                        htmlFor="next-follow-up-checkbox"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Next Follow Up After
                      </Label>
                    </div>
                  </div>
                  {nextFollowUpEnabled && (
                    <>
                      <div className="flex flex-row gap-3 items-center">
                        <p className="text-xs text-gray-500">
                          Select the time gap between this follow up and the
                          next one in minutes, hours, days or weeks
                        </p>
                        <div className="flex items-center gap-3 justify-end flex-1 pr-1">
                          <Input
                            className="w-[70px]"
                            type="number"
                            value={nextFollowUpDate.nextFollowUptimeAccessValue}
                            onChange={(event: any) => {
                              if (
                                (event?.target?.value)
                                  .toString()
                                  .startsWith('0')
                              ) {
                                setError(
                                  "Please add valid time, time should not start with '0'"
                                )

                                return
                              } else if (
                                error ==
                                "Please add valid time, time should not start with '0'"
                              ) {
                                setError('')
                              }
                              setNextFollowUpDate({
                                ...nextFollowUpDate,
                                nextFollowUptimeAccessValue: parseInt(
                                  event.target.value
                                ),
                              })
                            }}
                            min={1}
                            max={99}
                          />
                          <Select
                            value={nextFollowUpDate.nextFollowUptimeAccessType}
                            onValueChange={(value: any) =>
                              setNextFollowUpDate({
                                ...nextFollowUpDate,
                                nextFollowUptimeAccessType: value,
                              })
                            }
                          >
                            <SelectTrigger className="w-[100px]">
                              <SelectValue>
                                {!nextFollowUpDate.nextFollowUptimeAccessType
                                  ? ''
                                  : nextFollowUpDate.nextFollowUptimeAccessType ===
                                      'HOUR'
                                    ? 'Hours'
                                    : nextFollowUpDate.nextFollowUptimeAccessType ===
                                        'DAY'
                                      ? 'Days'
                                      : nextFollowUpDate.nextFollowUptimeAccessType ===
                                          'MINUTE'
                                        ? 'Minutes'
                                        : 'Weeks'}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="MINUTE">Minutes</SelectItem>
                                <SelectItem value="HOUR">Hours</SelectItem>
                                <SelectItem value="DAY">Days</SelectItem>
                                <SelectItem value="WEEK">Weeks</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </>
                  )}
                </AlertDialogHeader>

                <AlertDialogFooter className="max-md:gap-2">
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button
                    disabled={lead?.followUps.length >= numberOfFollowUps}
                    onClick={addFollowUp}
                  >
                    Add Follow up
                  </Button>

                  {lead?.status != 'BOOKED' && (
                    <Button
                      disabled={!shouldBook(lead, numberOfInsuranceClaims)}
                      onClick={() => {
                        openBook(true)
                        setOpen(false)
                      }}
                    >
                      Book
                    </Button>
                  )}
                </AlertDialogFooter>
                <div className="w-full border-t border-dashed" />
                {lead?.followUps?.length === 0 && (
                  <p className="text-sm text-gray-600 text-center mt-3">
                    No follow up history
                  </p>
                )}
                {lead?.followUps?.length > 0 && (
                  <h1 className="text-md font-semibold">Follow Up history</h1>
                )}
                <div className="overflow-hidden overflow-y-scroll pt-1 pl-3 pb-1 max-h-72 h-full flex flex-col gap-3 text-sm border">
                  {lead?.followUps
                    ?.slice(0, numberOfFollowUps)
                    .map((followUp: any) => (
                      <div className="bg-gray-50 rounded-md p-3 space-y-3">
                        <p className="text-indigo-700 text-xs">
                          {dayjs(followUp.createdAt).format('DD/MM/YYYY')}
                        </p>
                        {followUp.comment}
                        <p className="text-xs text-right">
                          Followed by: {followUp?.user?.firstName}{' '}
                          {followUp?.user?.lastName}
                        </p>
                      </div>
                    ))}
                </div>

                {lead?.isSentToInsurance &&
                  lead?.insuranceClaims?.length > 0 && (
                    <div className="bg-gray-50 rounded-md p-3 space-y-3 border flex justify-between items-end">
                      <div>
                        <p className="text-xs">
                          Sent to insurance:{' '}
                          {
                            lead.insuranceClaims[
                              lead.insuranceClaims.length - 1
                            ]?.status
                          }{' '}
                        </p>
                      </div>
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
