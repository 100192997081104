import { formatDate } from 'lib/utils'
import { Actions } from './actions'
import { AddComment } from './add-comment'
import { Comment } from './comment'
import dayjs from 'dayjs'
import { TbUser, TbUserScan } from 'react-icons/tb'
export const Task = ({
  task,
  user,
  setOpenUpdate,
  setUpdateStatus,
  setSelectedTask,
  isUpdateLoading,
}: any) => {
  return (
    <div className="bg-gray-50 rounded-md p-6 space-y-3 border ">
      <div className="flex items-center justify-between">
        <p className="text-indigo-700 text-xl font-semibold">{task.title}</p>
        <div className="flex gap-1 justify-start  items-center w-28">
          {' '}
          <span
            className={`text-white p-2 py-1 text-xs rounded-md w-20 text-center ${getTaskStatusColor(task.status)}`}
          >
            {' '}
            {task?.status.replace('_', ' ')}{' '}
          </span>
          {!(task.status == 'Canceled' || task.status == 'Completed') && (
            <Actions
              setOpenUpdate={setOpenUpdate}
              setUpdateStatus={setUpdateStatus}
              setSelectedTask={setSelectedTask}
              task={task}
              isUpdateLoading={isUpdateLoading}
              user={user}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-0 ">
        <h1 className="font-semibold">Description:</h1>
        <p className="text-sm p-0 ml-2">{task.details}</p>
      </div>
      <div className="pt-4">
        <p className="test-xs  flex items-center gap-1 ">
          <TbUserScan />{' '}
          <span className="font-semibold text-xs">Assignee:</span>{' '}
          <span className={`   text-xs `}>
            {' '}
            {task?.assignee?.firstName} {task?.assignee?.lastName}
          </span>
        </p>
        <p className="test-xs  flex items-center gap-1 ">
          <TbUserScan />{' '}
          <span className="font-semibold text-xs">Reporter:</span>{' '}
          <span className={`   text-xs `}>
            {' '}
            {task?.reporter?.firstName} {task?.reporter?.lastName}
          </span>
        </p>
      </div>

      <div className="w-full flex  justify-end">
        <div className="flex flex-col items-start  pb-10 gap-1 text-slate-600">
          {task.recurring && (
            <p className="text-xs  ">Recurrence {task.recurrenceType}</p>
          )}
          {task.recurring && task.recurrenceEnd && (
            <p className="text-xs  ">
              Recurrence ends {formatDate(task.recurrenceEnd)}
            </p>
          )}
          {task.dueDate && (
            <p className="text-xs ">
              Due {dayjs(task.dueDate).format('MMMM D, YYYY  hh:mm A')}{' '}
            </p>
          )}
          <p className="text-xs ">
            Created {dayjs(task.createdAt).format('MMMM D, YYYY  hh:mm A')}{' '}
          </p>

          {/* {user.role != 'AGENT' && (
            <p className="text-xs ">
              Assignee {task?.assignee?.firstName} {task?.assignee?.lastName}{' '}
            </p>
          )} */}
        </div>
      </div>

      <AddComment taskId={task.id} />
      {task.comments?.length > 0 && (
        <div className="max-h-72 p-2 overflow-scroll border shadow-sm rounded-md">
          {task?.comments?.map((comment: any) => {
            return <Comment comment={comment} />
          })}
        </div>
      )}
    </div>
  )
}

const getTaskStatusColor = (status: string) => {
  switch (status) {
    case 'Pending':
      return 'bg-orange-500'

    case 'In_Review':
      return 'bg-pink-800'
    case 'In_Progress':
      return 'bg-orange-700'
    case 'Completed':
      return 'bg-green-600'
    case 'Canceled':
      return 'bg-red-600'
    default:
      return 'bg-black'
  }
}
