import InsuranceProviders from './components/insurance-provider'
import InsuranceStatuses from './components/insurance-status'

export const Insurance = () => {
  return (
    <div className="flex flex-col gap-10">
      <InsuranceProviders />
      <InsuranceStatuses />
    </div>
  )
}
