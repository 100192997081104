import { Font, StyleSheet, Text, View, Image } from '@react-pdf/renderer'
import reportFont from 'assets/fonts/report-font.ttf'

Font.register({
  family: 'Bebas Neue',
  src: reportFont,
})

const styles = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  title: {
    fontSize: 25,
    color: '#00B4EF',
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subtitle: {
    fontSize: 15,
    color: '#4b5563',
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxNumber: {
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
    fontSize: 15,
  },
  boxText: {
    fontSize: 12,
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subTitle: {
    font0Size: 20,
    color: '#064AAB',
    fontFamily: 'Bebas Neue',
  },
  row: {
    marginTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default function LeadsBySource({ id }: any) {
  const data = sessionStorage.getItem(`chart-${id}`)
  return (
    <View
      style={{
        marginTop: 50,
      }}
    >
      <Text style={{ ...styles.title }}>LEADS BY SOURCE</Text>
      {data && (
        <Image
          src={data}
          style={{ width: '100%', minHeight: 180, marginTop: 20 }}
        />
      )}
    </View>
  )
}
