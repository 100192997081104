import {
  Page,
  Text,
  View,
  Document,
  Image,
  Svg,
  Line,
  StyleSheet,
} from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { convertCamelCaseToTitleCase, formatPrice } from 'lib/utils'

const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingTop: 90,
    position: 'relative',
    fontFamily: 'Helvetica',
    color: '#333',
  },
  mainHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 50,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
  },
  mainInfo: {
    padding: 50,
    gap: 20,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 0,

    position: 'relative',
  },
  chart: { padding: 5 },
  section: {
    margin: 30,
    padding: 10,
    flexGrow: 1,
  },

  userInfoContainer: {
    gap: 10,
    marginTop: 40,
    width: '100%',
    padding: 20,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 10,
    backgroundColor: '#f8fafc',
    fontFamily: 'Helvetica',
  },
  userInfo: {
    fontSize: 12,
    color: 'black',
    gap: 5,
    fontWeight: 800,
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  bullet: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  bulletItem: {
    color: '#6B7280',
    fontSize: 10,
    marginLeft: 10, // Indent bullet points
    marginBottom: 3,
  },
  userDetails: { color: '#6B7280', fontSize: 10, marginBottom: 3 },
  dateDetails: {
    color: '#6B7280',
    fontSize: 10,
    margin: 7,
    marginBottom: 3,
    marginTop: 3,
  },
  dateInfoContainer: {
    gap: 10,
    marginTop: 20,
    width: '100%',
    padding: 10,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#f8fafc',
    fontFamily: 'Helvetica',
  },
  dateInfo: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  container: {
    padding: 12,
    borderWidth: 1,
    borderColor: '#d1d5db', // slate-300
    borderRadius: 5,
    position: 'relative',
    marginTop: 8,
  },

  noLeads: {
    textAlign: 'center',
    color: '#6b7280', // #6B7280-500
    fontSize: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // #6B7280-100
    paddingBottom: 4,
    marginBottom: 4,
  },
  leadRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // #6B7280-100
    paddingVertical: 4,
    justifyContent: 'space-between',
  },
  leadCell: {
    fontSize: 10,
    flexGrow: 1,
    textAlign: 'left',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 60,
  },

  pdfTitle: {
    backgroundColor: 'white',
    color: '#08B3E2',
    fontSize: 20,
    textAlign: 'center',
    width: '100%',
    fontWeight: 'extrabold',
    paddingTop: 20,
    fontFamily: 'Helvetica',
  },
  title: {
    backgroundColor: 'white',
    color: '08B3E2',
    fontSize: 14,
    fontWeight: 'bold',
  },
  clinicDescriptionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 20,
    textAlign: 'center',
    color: '#333',
  },
  logo: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 100, // Adjust as needed
    height: 'auto',
  },
  clinicLogo: {
    position: 'absolute',
    top: 0,
    left: 50,
    width: 80, // Adjust as needed
    height: 'auto',
  },
  clinicDescription: {
    marginTop: 15,
    fontSize: 12,
    lineHeight: 1.5,
    color: '#6B7280',
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fff',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    textAlign: 'center',
    fontSize: 8,
    color: '#6b7280', // #6B7280-500
  },

  header2: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    color: '#08B3E2',
  },
  timelineContainer: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    padding: 10,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,

    backgroundColor: '#f8fafc',
  },
  actionContainer: {
    position: 'relative',
    padding: 15,
    paddingRight: 30,

    borderRadius: 5,
    //backgroundColor: '#f9f9f9',
    marginBottom: 28,
    zIndex: 1,
    // height: '205',
  },
  actionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#08B3E2',
  },
  detail: {
    fontSize: 12,
    marginBottom: 3,
  },
  actionedAt: {
    fontSize: 8,
    marginBottom: 3,
    color: '#6B7280', // #6B7280-500
  },
  actionDetailsItem: {
    color: 'black',
    fontSize: 10,
    marginLeft: 10, // Indent bullet points
    marginBottom: 3,
  },

  line: {
    position: 'absolute',
    right: '5%',
    top: 35,
    width: 1,
    height: '100%',
    backgroundColor: '#efeded',
    zIndex: -1,
  },
  dot: {
    position: 'absolute',
    right: '4.5%',
    width: 6,
    height: 6,
    top: 15,
    borderRadius: '50%',
    backgroundColor: '#08B3E2',
    //marginLeft: -10, // Center the dot
  },
  actionBy: { fontSize: 8, color: '#6B7280' },
  itemCell: {
    fontSize: 8,
    flexGrow: 1,
    textAlign: 'left',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 130,
  },
  itemRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // #6B7280-100
    paddingVertical: 4,
    justifyContent: 'space-between',
  },
  tableContainer: {
    padding: 12,

    borderRadius: 5,
    position: 'relative',
    marginTop: 8,
  },
})

const Header = ({ clinicLogoUrl }: any) => {
  return (
    <>
      <Image
        src={{
          uri: clinicLogoUrl,
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.clinicLogo}
      />

      <Image
        src={{
          uri: '/logo.jpeg',
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.logo}
      />
    </>
  )
}

const Footer = () => {
  return (
    <View style={styles.footer}>
      <Text>
        {`\u00A9`} Doctorna By Code Guru, Al Barsha Business Centre Dubai, UAE,
        Mobile: +971 58 696 4342 | admin@codeguru.ae | All Rights Reserved.
      </Text>
    </View>
  )
}
export const LeadInfoReport = ({ lead, clinicDescription }: any) => {
  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, backgroundColor: '#f8fafc' }}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />
        <View>
          <Text style={styles.pdfTitle}>Lead Journey</Text>
        </View>
        <View style={styles.mainInfo}>
          <View style={styles.dateInfoContainer}>
            <View>
              <View style={styles.dateInfo}>
                <Text>Generated on: </Text>
                <Text style={styles.dateDetails}>
                  {dayjs(new Date()).format('DD/MM/YYYY h:mm A')}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.userInfoContainer}>
            <View style={styles.userInfo}>
              <Text style={styles.name}>
                Name: {lead.firstName + ' ' + lead.lastName}
              </Text>
              <Text style={styles.userDetails}>Email: {lead.email}</Text>
              <Text style={styles.userDetails}>Phone: {lead.phone}</Text>
              <Text style={styles.userDetails}>
                Whatsapp number: {lead.whatsappNumber}
              </Text>
              <Text style={styles.userDetails}>
                Source: {lead.source?.name}
              </Text>
              <Text style={styles.bullet}>Branches:</Text>
              {lead.branches.map((branch: any) => (
                <Text style={styles.bulletItem} key={branch.id}>
                  • {branch.name}
                </Text>
              ))}
              <Text style={styles.bullet}>Departments:</Text>
              {lead.departments.map((department: any) => (
                <Text style={styles.bulletItem} key={department.id}>
                  • {department.name}
                </Text>
              ))}
              <Text style={styles.bullet}>Specialists:</Text>
              {lead.specialists.map((specialist: any) => (
                <Text style={styles.bulletItem} key={specialist.id}>
                  • {specialist.name}
                </Text>
              ))}
            </View>
          </View>
        </View>
        {/* <Text style={styles.footer2}>
          Generated on {new Date().toLocaleDateString()}
        </Text> */}
        <Footer />
      </Page>

      <Page size="A4" style={{ ...styles.page }}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />
        <View style={styles.timelineContainer}>
          {lead?.leadActions?.map((action: any, index: any) => (
            <View key={index} style={styles.actionContainer}>
              <Text style={styles.actionTitle}>
                {action.action.replaceAll('_', ' ')}
              </Text>

              <View style={{ padding: 4, paddingTop: 10 }}>
                {action.updateDetails && (
                  <View style={styles.tableContainer}>
                    <>
                      <View style={styles.header}>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            ...styles.itemCell,
                            fontSize: 10,
                          }}
                        >
                          Field
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            ...styles.itemCell,
                            fontSize: 10,
                          }}
                        >
                          Previous
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            ...styles.itemCell,
                            fontSize: 10,
                          }}
                        >
                          Current
                        </Text>
                      </View>

                      <>
                        {[
                          'firstName',
                          'lastName',
                          'email',
                          'phone',
                          'whatsappNumber',
                          'alternativePhone',
                          'source',
                          'nationality',
                          'gender',
                          'emiratesId',
                          'mrnUrn',
                          'insuranceType',
                          'insuranceNumber',
                          'insuranceProvider',
                        ].map((field) => {
                          if (action.updateDetails[field])
                            return (
                              <View style={styles.itemRow}>
                                <Text style={styles.itemCell}>
                                  {convertCamelCaseToTitleCase(field)}
                                </Text>
                                <Text
                                  style={{
                                    ...styles.itemCell,
                                    color: '#6B7280',
                                  }}
                                >
                                  {action.updateDetails[field].prev}
                                </Text>
                                <Text
                                  style={{
                                    ...styles.itemCell,
                                    color: '#6B7280',
                                  }}
                                >
                                  {action.updateDetails[field].current}
                                </Text>
                              </View>
                            )
                        })}

                        {['branches', 'departments', 'specialists'].map(
                          (field) => {
                            if (action.updateDetails[field])
                              return (
                                <View style={styles.itemRow}>
                                  <Text style={styles.itemCell}>
                                    {convertCamelCaseToTitleCase(field)}
                                  </Text>
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                      gap: 4,
                                      fontSize: 8,
                                      flexGrow: 1,
                                      textAlign: 'left',
                                      width: '100%',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: 130,
                                      color: '#6B7280',
                                    }}
                                  >
                                    {action.updateDetails[field].prev?.map(
                                      (item: any, index: any) => (
                                        <Text key={index}>{item}</Text>
                                      )
                                    )}
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                      gap: 4,
                                      fontSize: 8,
                                      flexGrow: 1,
                                      textAlign: 'left',
                                      width: '100%',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: 130,
                                      color: '#6B7280',
                                    }}
                                  >
                                    {action.updateDetails[field].current?.map(
                                      (item: any, index: any) => (
                                        <Text key={index}>{item}</Text>
                                      )
                                    )}
                                  </View>
                                </View>
                              )
                          }
                        )}
                      </>
                    </>
                    <Text
                      style={{
                        fontSize: 8,
                        textAlign: 'center',
                        marginVertical: 5,
                        color: '#6B7280',
                      }}
                    >
                      Update details.
                    </Text>
                  </View>
                )}

                {action.followUpComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.followUpComment}
                  </Text>
                )}
                {action.nextFollowUpTimeGap && (
                  <Text style={styles.actionDetailsItem}>
                    • Next follow up time gap: {action.nextFollowUpTimeGap}{' '}
                  </Text>
                )}

                {action.bookDate && (
                  <Text style={styles.actionDetailsItem}>
                    • date:{' '}
                    {dayjs(action.bookDate).format('DD/MM/YYYY hh:mm:A')}
                  </Text>
                )}

                {action.bookBranch && (
                  <Text style={styles.actionDetailsItem}>
                    • branch: {action.bookBranch}
                  </Text>
                )}
                {action.bookDepartment && (
                  <Text style={styles.actionDetailsItem}>
                    • department:
                    {action.bookDepartment}
                  </Text>
                )}
                {action.bookSpecialist && (
                  <Text style={styles.actionDetailsItem}>
                    • specialist: {action.bookSpecialist}
                  </Text>
                )}
                {action.bookComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.bookComment}
                  </Text>
                )}
                {action.bookStatusComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.bookStatusComment}
                  </Text>
                )}
                {action.action == 'Update_Book_Status' && (
                  <Text style={styles.actionDetailsItem}>
                    • status: {action.bookStatusIsShow ? 'Show' : 'No Show'}
                  </Text>
                )}
                {action.action == 'Update_Book_Status' && (
                  <Text style={styles.actionDetailsItem}>
                    • is paid: {action.bookStatusIsPaid ? 'Yes' : 'No'}
                  </Text>
                )}
                {action.bookStatusIsPaid && (
                  <Text style={styles.actionDetailsItem}>
                    • amount: {formatPrice(action.bookStatusAmount)}
                  </Text>
                )}

                {action.sendToInsuranceComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.sendToInsuranceComment}
                  </Text>
                )}
                {action.sendToInsuranceEndedReason && (
                  <Text style={styles.actionDetailsItem}>
                    • reason: {action.sendToInsuranceEndedReason}
                  </Text>
                )}
                {action.sendToInsuranceEndedComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.sendToInsuranceEndedComment}
                  </Text>
                )}
                {action.insuranceClaimStatus && (
                  <Text style={styles.actionDetailsItem}>
                    • status: {action.insuranceClaimStatus}
                  </Text>
                )}
                {action?.insuranceClaimExpiryDate && (
                  <Text style={styles.actionDetailsItem}>
                    • expiry date:{' '}
                    {dayjs(action?.insuranceClaimExpiryDate).format(
                      'DD/MM/YYYY hh:mm A'
                    )}{' '}
                  </Text>
                )}
                {action?.insuranceClaimAuthorizationNumber && (
                  <Text style={styles.actionDetailsItem}>
                    • Authorization number:{' '}
                    {action?.insuranceClaimAuthorizationNumber}{' '}
                  </Text>
                )}

                {action?.insuranceClaimApprovalAmount && (
                  <Text style={styles.actionDetailsItem}>
                    • Approving amount:{' '}
                    {formatPrice(action?.insuranceClaimApprovalAmount)}{' '}
                  </Text>
                )}

                {action.insuranceClaimComment && (
                  <Text style={styles.actionDetailsItem}>
                    • comment: {action.insuranceClaimComment}
                  </Text>
                )}
                {action.notQualifiedReason && (
                  <Text style={styles.actionDetailsItem}>
                    • reason: {action.notQualifiedReason}
                  </Text>
                )}
                {action?.attachmentDescription && (
                  <Text style={styles.actionDetailsItem}>
                    • Description: {action?.attachmentDescription}{' '}
                  </Text>
                )}
              </View>
              <View style={{ paddingTop: 10, display: 'flex', gap: 1 }}>
                <Text style={styles.actionBy}>
                  action at{' '}
                  {dayjs(action.createdAt).format('DD/MM/YYYY hh:mm:A')}
                </Text>
                <Text style={styles.actionBy}>
                  action by:{' '}
                  {action.user?.firstName + ' ' + action.user?.lastName}
                </Text>
              </View>
              <View style={{ ...styles.dot }} />
              {index < lead?.leadActions.length - 1 && (
                <View style={{ ...styles.line }} />
              )}
            </View>
          ))}
        </View>
        <Footer />
      </Page>

      {/* <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{lead.followUps?.length} follow ups</Text>

        <View style={styles.container}>
          {lead.followUps?.length < 1 ? (
            <Text style={styles.noLeads}>No follow ups</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    maxWidth: 150,

                    fontSize: 12,
                  }}
                >
                  Comment
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    maxWidth: 90,
                    fontSize: 12,
                  }}
                >
                  Date
                </Text>
              </View>
              {lead.followUps?.map((followUp: any) => (
                <View key={followUp?.id} style={styles.leadRow}>
                  <Text style={{ ...styles.leadCell, maxWidth: 150 }}>
                    {followUp?.comment}
                  </Text>
                  <Text style={{ ...styles.leadCell, maxWidth: 90 }}>
                    {dayjs(followUp?.date).format('DD/MM/YYYY')}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{lead.bookings?.length} bookings</Text>

        <View style={styles.container}>
          {lead.bookings?.length < 1 ? (
            <Text style={styles.noLeads}>No bookings</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Date
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Branch
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Department
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Specialist
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Show
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Paid
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Amount
                </Text>
              </View>
              {lead.bookings.map((booking: any) => (
                <View key={booking?.id} style={styles.leadRow}>
                  <Text style={styles.leadCell}>
                    {dayjs(booking?.date).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={styles.leadCell}>{booking?.branch?.name}</Text>
                  <Text style={styles.leadCell}>
                    {booking?.department?.name}
                  </Text>
                  <Text style={styles.leadCell}>
                    {booking?.specialist?.name}
                  </Text>
                  {booking?.isShowUpdated ? (
                    <>
                      <Text style={styles.leadCell}>
                        {booking.isShow ? 'Yes' : 'No'}
                      </Text>
                      <Text style={styles.leadCell}>
                        {booking.isPaid ? 'Yes' : 'No'}
                      </Text>
                      <Text style={styles.leadCell}>
                        {formatPrice(booking.amount)}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={styles.leadCell}>-</Text>
                      <Text style={styles.leadCell}>- </Text>
                      <Text style={styles.leadCell}>- </Text>
                    </>
                  )}
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>
          {lead.insuranceClaims?.length} insurance claims
        </Text>

        <View style={styles.container}>
          {lead.insuranceClaims?.length < 1 ? (
            <Text style={styles.noLeads}>No Insurance claims</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    maxWidth: 90,

                    fontSize: 12,
                  }}
                >
                  Date
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    maxWidth: 150,

                    fontSize: 12,
                  }}
                >
                  Comment
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    maxWidth: 90,
                    fontSize: 12,
                  }}
                >
                  Status
                </Text>
              </View>
              {lead.insuranceClaims?.map((insuranceClaim: any) => (
                <View key={insuranceClaim?.id} style={styles.leadRow}>
                  <Text style={{ ...styles.leadCell, maxWidth: 90 }}>
                    {dayjs(insuranceClaim?.createdAt).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={{ ...styles.leadCell, maxWidth: 150 }}>
                    {insuranceClaim?.comment}
                  </Text>
                  <Text style={{ ...styles.leadCell, maxWidth: 90 }}>
                    {insuranceClaim?.status}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page> */}

      <Page size="A4" style={{ ...styles.page, backgroundColor: '#f9f9f9' }}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.clinicDescriptionTitle}>About Us</Text>

        <Text style={styles.clinicDescription}>{clinicDescription}</Text>
        <Footer />
      </Page>
    </Document>
  )
}
