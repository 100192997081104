import { Button, buttonVariants } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'

import {
  cn,
  containsSubstring,
  convertToCamelCase,
  convertToTitleCase,
} from 'lib/utils'
import { useEffect, useRef, useState } from 'react'
import { FcDataSheet } from 'react-icons/fc'
import { GiReturnArrow } from 'react-icons/gi'
import { TEMPLATE_VARIABLES } from './create-template'
import PreviewTemplateMessage from './preview-template-message'
import { CiEdit } from 'react-icons/ci'

interface SendTemplateProps {
  template: any
  setStep: (step: any) => void
  setCampaignData: (step: any) => void
}

const initialData = {
  name: '',
  language: '',
  mediaSample: '',
  headerMediaSampleFile: null,
  headerMediaSampleFileContent: null,
  headerTextSample: {},
  bodySample: {},
}

export default function FillCampaignTemplate({
  template,
  setCampaignData,
  setStep,
}: SendTemplateProps) {
  const [data, setData] = useState<any>(initialData)
  const [error, setError] = useState('')

  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )

  const headerFormat = headerComponent?.format
  const headerTextVariables = headerComponent?.example?.header_text
  const headerTextVariablesCount = headerComponent?.example?.header_text?.length
  const bodyVariables = bodyComponent?.example?.body_text
  const bodyVariablesCount =
    bodyComponent?.example?.body_text?.length > 0
      ? bodyComponent?.example?.body_text[0].length
      : 0

  useEffect(() => {
    const headerTextSample: any = {}
    if (headerTextVariablesCount > 0) {
      for (let i = 0; i < headerTextVariablesCount; i++) {
        //headerTextSample[i + 1] = headerTextVariables[i]
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (
            containsSubstring(headerTextVariables[i], TEMPLATE_VARIABLES[j])
          ) {
            headerTextSample[i + 1] = TEMPLATE_VARIABLES[j]
          }
        }
      }
    }

    const bodySample: any = {}
    if (bodyVariablesCount > 0) {
      for (let i = 0; i < bodyVariablesCount; i++) {
        for (let j = 0; j < TEMPLATE_VARIABLES.length; j++) {
          if (containsSubstring(bodyVariables[0][i], TEMPLATE_VARIABLES[j])) {
            bodySample[i + 1] = TEMPLATE_VARIABLES[j]
          }
        }
      }
    }
    setData({
      ...initialData,
      headerTextSample,
      bodySample,
    })
    setError('')
  }, [template])

  async function fillTemplate() {
    setError('')

    /* header text variables validation */

    const headerTextSample = Object.values(data.headerTextSample)
    if (
      headerTextSample.length < headerTextVariablesCount ||
      headerTextSample.some((element) => element == '' || !element)
    ) {
      {
        return setError('Header text variables required')
      }
    }

    /* header media sample validation */
    if (
      (headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT') &&
      !data.headerMediaSampleFile
    ) {
      return setError('Media sample required')
    }

    /* body variables validation */
    const bodySample = Object.values(data.bodySample)
    if (
      bodySample.length < bodyVariablesCount ||
      bodySample.some((element) => element == '' || !element)
    ) {
      {
        return setError('Body variables sample required')
      }
    }

    setCampaignData((prev: any) => {
      return {
        ...prev,
        templateData: {
          name: template?.name,
          category: template?.category,
          language: template?.language,
          headerFormat,
          headerMediaSampleFile: data.headerMediaSampleFile,
          headerTextSample,
          bodySample,
          components: template?.components,
        },
      }
    })
    setStep(2)
  }

  return (
    <div
      className={`sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3 `}
    >
      <div className="h-full w-full">
        <div className="">
          <div className="flex gap-2 max-md:flex-col items-center">
            {template && (
              <PreviewTemplateMessage
                template={template}
                headerTextSample={Object.values(data.headerTextSample)}
                bodySample={Object.values(data.bodySample)}
                headerMediaSample={data.headerMediaSampleFileContent}
              />
            )}
            <div className=" p-4">
              {((headerFormat == 'TEXT' && headerTextVariablesCount > 0) ||
                headerFormat == 'VIDEO' ||
                headerFormat == 'IMAGE' ||
                headerFormat == 'DOCUMENT' ||
                bodyVariablesCount > 0) && (
                <h1 className="flex items-center font-medium gap-1  ">
                  Fill dynamic information <FcDataSheet size={20} />
                </h1>
              )}
              <div className="w-full space-y-3 mt-5 px-1">
                {((headerFormat == 'TEXT' && headerTextVariablesCount > 0) ||
                  headerFormat == 'VIDEO' ||
                  headerFormat == 'IMAGE' ||
                  headerFormat == 'DOCUMENT') && (
                  <div className="w-full space-y-2  p-2 ">
                    <Label>Header</Label>
                    {headerFormat == 'TEXT' && headerTextVariablesCount > 0 && (
                      <DynamicTextArea
                        data={data}
                        setData={setData}
                        indexKey={'headerText'}
                        vars={headerTextVariablesCount}
                        samples={headerTextVariables}
                      />
                    )}
                    {(headerFormat == 'VIDEO' ||
                      headerFormat == 'IMAGE' ||
                      headerFormat == 'DOCUMENT') && (
                      <MediaSelect
                        data={data}
                        setData={setData}
                        indexKey="headerMedia"
                        format={headerFormat}
                        template={template}
                      />
                    )}
                  </div>
                )}
                {bodyVariablesCount > 0 && (
                  <div className="w-full space-y-2  p-2 ">
                    <Label>Body</Label>

                    <DynamicTextArea
                      data={data}
                      setData={setData}
                      indexKey={'body'}
                      vars={bodyVariablesCount}
                      samples={bodyVariables[0]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {error !== '' && <div className="text-rose-600 text-sm">{error}</div>}

          <div className="pt-5 flex items-center justify-between">
            <div />
            <Button size="sm" onClick={fillTemplate}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

function DynamicTextArea({ data, setData, indexKey, vars, samples }: any) {
  const sampleKey = indexKey + 'Sample'

  const handleVariableValueChange = (index: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: { ...prev[sampleKey], [index]: value },
    }))
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          {Array.from(Array(vars)).map((placeholder: any, index: any) => (
            <div className="flex  gap-1 items-center">
              <Label className="w-1/2 ">
                {convertToTitleCase(samples[index])}
                <span className="ml-1 text-rose-500 ">*</span>
              </Label>

              <div className="flex items-center gap-2">
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger
                      disabled={data[sampleKey][index + 1] != 'lead_name'}
                    >
                      <div>
                        <Input
                          type={'text'}
                          className=""
                          placeholder={samples[index]}
                          value={data[sampleKey][index + 1] || ''}
                          disabled={TEMPLATE_VARIABLES.includes(
                            data[sampleKey][index + 1]
                          )}
                          onChange={(e) =>
                            handleVariableValueChange(index + 1, e.target.value)
                          }
                        />
                      </div>
                    </TooltipTrigger>
                    {TEMPLATE_VARIABLES.includes(
                      data[sampleKey][index + 1]
                    ) && (
                      <TooltipContent
                        className="bg-gray-50 border shadow p-2 py-4"
                        sideOffset={0}
                      >
                        <span className="text-xs ml-2 italic text-gray-500 flex flex-col items-start gap-2">
                          * this field will be populated with lead's{' '}
                          {convertToCamelCase(data[sampleKey][index + 1])}
                          <Button
                            variant="outline"
                            size={'sm'}
                            className="flex  gap-1 relative "
                            onClick={() => {
                              handleVariableValueChange(index + 1, '')
                            }}
                          >
                            {' '}
                            modify
                            <CiEdit size={16} className="text-gray-700" />
                          </Button>
                        </span>
                      </TooltipContent>
                    )}{' '}
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function MediaSelect({ setData, data, indexKey, format, template }: any) {
  const fileRef = useRef<any>()
  const sampleKey = indexKey + 'SampleFile'
  const contentKey = indexKey + 'SampleFileContent'

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setData((prev: any) => {
      return { ...prev, [sampleKey]: null, [contentKey]: null }
    })
  }

  useEffect(() => {
    replaceFileHandler()
  }, [template])

  const handleMediaFileChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setData((prev: any) => {
          return { ...prev, [sampleKey]: file, [contentKey]: reader.result }
        })
      }
      reader.readAsDataURL(file)
    }
  }
  return (
    <div>
      <div className="flex mt-2  flex-col gap-2">
        <div className="w-full items-center flex justify-between">
          <Label className="w-1/3 ">
            Media
            <span className="ml-1 text-rose-500 ">*</span>
          </Label>
          <label
            htmlFor="media"
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-44 border text-left text-xs',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            ADD {format}
            <input
              id="media"
              type="file"
              ref={fileRef}
              accept={
                format == 'IMAGE'
                  ? 'image/*'
                  : format == 'VIDEO'
                    ? 'video/*'
                    : '*'
              }
              style={{ display: 'none' }}
              className="cursor-pointer"
              onChange={handleMediaFileChange}
            />
          </label>

          <Button
            onClick={replaceFileHandler}
            variant={'outline'}
            className="text-xs font-medium flex items-center"
          >
            reset
            <GiReturnArrow
              size={10}
              className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
            />
          </Button>
        </div>
        <span className="text-xs">{data[sampleKey]?.name}</span>
      </div>
    </div>
  )
}
