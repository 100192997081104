import { Cross1Icon } from '@radix-ui/react-icons'
import { useGetTemplatesQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useState } from 'react'
import PreviewTemplate from './preview-template'
import { capitalizeFirstLetter } from 'lib/utils'

interface ViewTemplatesProps {
  open: boolean
  setOpen: (open: any) => void
}
export default function ViewTemplates({ open, setOpen }: ViewTemplatesProps) {
  const { data: templates, isLoading, isError } = useGetTemplatesQuery()
  const [openPreviewTemplate, setOpenPreviewTemplate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(false)

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild></AlertDialogTrigger>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[900px] bg-white dark:bg-gray-900 pr-3">
          {isLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}
          {isError && (
            <div className="h-40 grid place-content-center">
              <Error message="Server error, contact administrator please" />
            </div>
          )}
          {!isError && !isLoading && (
            <div className="h-full w-full">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">Templates</h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="w-full max-h-[500px] overflow-hidden overflow-y-scroll mt-4">
                <div className="w-full overflow-hidden overflow-x-scroll">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="text-left border-b w-full ">
                        <th className="xl:w-60">Name</th>
                        <th className="xl:w-40 ">Category</th>
                        <th className="xl:w-32">Language</th>
                        <th className="xl:w-40">Status</th>
                        <th className="">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {templates?.data?.map((template: any) => (
                        <tr
                          key={template.id}
                          className="w-full border-b border-gray-100 last:border-b-0 py-1"
                        >
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {template?.name}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {capitalizeFirstLetter(template?.category)}{' '}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {template.language == 'en'
                              ? 'English'
                              : template.language == 'ar'
                                ? 'Arabic'
                                : ''}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {capitalizeFirstLetter(template?.status)}{' '}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            <Button
                              size={'sm'}
                              onClick={() => {
                                setSelectedTemplate(template)
                                setOpenPreviewTemplate(true)
                              }}
                            >
                              Preview
                            </Button>{' '}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </AlertDialogContent>
      </AlertDialog>
      <PreviewTemplate
        open={openPreviewTemplate}
        setOpen={setOpenPreviewTemplate}
        template={selectedTemplate}
      />
    </>
  )
}
