import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import LeadInterest from './lead-interest'
import LeadInfo from './lead-info'
import { Button } from 'components/ui/button'

export const LeadDetails = ({
  isLoading,
  loadingLead,
  loading,
  isErrorLead,
  isUpdateError,
  errorLead,
  lead,
  isDetailsPage,
  data,
  setData,
  handleChange,
  addBranch,
  addDepartment,
  addSpecialist,
  branches,
  departments,
  specialists,
  error,
  editLead,
  navigate,
}: any) => {
  return (
    <>
      {(isLoading || loadingLead || loading) && (
        <div className="h-screen grid place-content-center">
          <Loader />
        </div>
      )}
      {(isErrorLead || isUpdateError) && (
        <div className="h-screen grid place-content-center">
          <Error
            message={
              errorLead?.status == 404
                ? 'Lead Not Found'
                : 'Server error, contact administrator please'
            }
          />
        </div>
      )}
      {lead && !isLoading && !loadingLead && !loading && (
        <>
          {isDetailsPage ? (
            <div className="pt-5 flex items-center justify-between">
              <h1 className="text-lg font-medium flex items-center gap-1">
                Lead Info
              </h1>
            </div>
          ) : (
            <div className="pt-5 flex items-center justify-between">
              <h1 className="text-lg font-medium">Update Lead</h1>
            </div>
          )}

          <div className="flex justify-end">
            <LeadInfo
              data={data}
              setData={setData}
              handleChange={handleChange}
              isDetailsPage={isDetailsPage}
            />
          </div>

          <div className="flex-1 mt-10 border-t border-dashed" />
          {(!isDetailsPage ||
            (isDetailsPage && lead?.branches?.length > 0)) && (
            <div className="flex justify-end">
              <LeadInterest
                data={data}
                setData={setData}
                addBranch={addBranch}
                addDepartment={addDepartment}
                addSpecialist={addSpecialist}
                isDetailsPage={isDetailsPage}
                branches={isDetailsPage ? lead?.branches : branches}
                departments={isDetailsPage ? lead?.departments : departments}
                specialists={isDetailsPage ? lead?.specialists : specialists}
              />
            </div>
          )}
          {lead && !isDetailsPage && (
            <div className="pr-5 flex">
              {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
              <div className="flex-1" />

              <div className="flex items-center gap-2">
                <Button
                  variant={'outline'}
                  onClick={() => {
                    if (lead) setData(lead)
                    navigate(`/lead-details/${lead?.id}`)
                  }}
                >
                  Cancel
                </Button>

                <Button onClick={editLead}>Update</Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
