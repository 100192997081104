import { createContext, useContext, useEffect, useMemo, useState } from 'react'

export const ReportContext = createContext<any | null>(null)

export const ReportProvider = ({ children }: { children: any }) => {
  const [usersData, setUsersData] = useState<any>(null)
  const [bookingData, setBookingData] = useState<any>(null)
  const [sourceData, setSourceData] = useState<any>(null)

  return (
    <ReportContext.Provider
      value={{
        usersData,
        setUsersData,
        bookingData,
        setBookingData,
        sourceData,
        setSourceData,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export default function useReport() {
  return useContext(ReportContext)
}
