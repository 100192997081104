import { z } from 'zod'

export const isEmail = (value: string) => {
  const emailSchema = z.string().email()
  return emailSchema.safeParse(value).success
}

export const isPhone = (value: string) => {
  const phoneSchema = z.string().min(10)
  return phoneSchema.safeParse(value).success
}

export const isURL = (value: string) => {
  const urlSchema = z.string().url().max(256)
  return urlSchema.safeParse(value).success
}

export const isLowercaseUnderscore = (value: string) => {
  const lowercaseUnderscoreSchema = z.string().regex(/^[a-z_]+$/)
  return lowercaseUnderscoreSchema.safeParse(value).success
}
