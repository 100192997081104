import { Cross1Icon } from '@radix-ui/react-icons'
import { useGetTemplatesQuery } from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { PreviewMedia } from './preview-media'
import dayjs from 'dayjs'
interface PreviewTemplateProps {
  template: any
  open: boolean
  setOpen: (open: any) => void
}

export default function PreviewTemplate({
  template,
  open,
  setOpen,
}: PreviewTemplateProps) {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )
  const footerComponent = template?.components?.find(
    (component: any) => component.type == 'FOOTER'
  )

  const buttonComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )

  const headerFormat = headerComponent?.format

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[600px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg font-semibold capitalize">
              {template?.name}
            </h1>

            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>

          <div className="w-full max-h-[500px]  overflow-hidden overflow-y-scroll mt-4 flex flex-col gap-2 p-4 whitespace-pre-wrap">
            <span className="bg-gray-100 text-white py-2 px-4 rounded-md max-w-xs">
              <span className="font-bold text-sm text-black">Sender Name</span>
              {headerComponent && headerFormat == 'TEXT' && (
                <span className="block mt-1 w-full text-black font-bold">
                  {headerComponent?.text?.replace(
                    /{{(\d+)}}/g,
                    (_: any, num: any) =>
                      headerComponent?.example?.header_text[+num - 1] || ''
                  )}
                </span>
              )}
              {headerComponent && headerFormat != 'TEXT' && (
                <PreviewMedia
                  type={headerFormat}
                  href={headerComponent?.example?.header_handle[0]}
                  filename={'document'}
                />
              )}
              <span className="block mt-1 w-full text-black">
                {bodyComponent?.text.replace(
                  /{{(\d+)}}/g,
                  (_: any, num: any) =>
                    bodyComponent?.example?.body_text[0][+num - 1] || ''
                )}
              </span>
              {footerComponent && (
                <span className="block mt-1 w-full text-gray-500 text-xs">
                  {footerComponent?.text}
                </span>
              )}
              {buttonComponent && (
                <>
                  {buttonComponent.buttons.map((button: any) => {
                    return (
                      <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                        {button.text}
                      </div>
                    )
                  })}
                </>
              )}
              <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
                {dayjs(new Date()).format('YYYY-MM-DD HH:mm A')}
              </span>
            </span>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
