import dayjs from 'dayjs'
import { ChatMedia } from './media'

interface ChatListProps {
  content?: any
  type: string
  senderName?: string
  mediaId?: string
  filename?: string
  templateHeaderFormat?: string
  templateHeaderText?: string
  templateHeaderMediaId?: string
  templateBodyText?: string
  templateFooterText?: string
  templateButtonsTexts?: string[]
  timestamp?: any
}

export default function Bubble({
  content,
  type,
  senderName,
  mediaId,
  filename,
  templateHeaderFormat,
  templateHeaderText,
  templateHeaderMediaId,
  templateBodyText,
  templateFooterText,
  templateButtonsTexts,
  timestamp,
}: ChatListProps) {
  return (
    <>
      {type === 'TEXT' && (
        <div className="max-w-xs max-md:max-w-[16rem]">
          <div className="py-2 px-3 bg-gray-100 rounded-2xl">
            <span className="font-bold text-[11px] text-gray-500">
              {senderName}
            </span>
            <span className="block w-full">{content}</span>
          </div>
          <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
            {dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
          </span>
        </div>
      )}
      {mediaId && (
        <ChatMedia
          type={type}
          mediaId={mediaId}
          content={content}
          filename={filename}
          senderName={senderName}
          timestamp={timestamp}
        />
      )}
      {type === 'TEMPLATE' && (
        <div className="max-w-xs max-md:max-w-[16rem]">
          <div className="bg-gray-100 rounded-2xl py-2 px-4">
            <span className="text-[11px] text-gray-500">{senderName}</span>
            {templateHeaderFormat == 'TEXT' && (
              <span className="block mt-1 w-full font-bold">
                {templateHeaderText}
              </span>
            )}
            {templateHeaderFormat && templateHeaderFormat != 'TEXT' && (
              <ChatMedia
                type={templateHeaderFormat}
                mediaId={templateHeaderMediaId!}
                // TODO: filename to be saved
                filename={'document'}
                isTemplate={true}
              />
            )}
            <span className="block mt-1 w-full pb-3">{templateBodyText}</span>
            {templateFooterText && (
              <div className="border-t">
                <span className="block mt-1 w-full text-gray-500 text-xs">
                  {templateFooterText}
                </span>
              </div>
            )}
            {templateButtonsTexts && templateButtonsTexts?.length > 0 && (
              <>
                {templateButtonsTexts.map((text: any) => {
                  return (
                    <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                      {text}
                    </div>
                  )
                })}
              </>
            )}
          </div>
          <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
            {dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
          </span>
        </div>
      )}
    </>
  )
}
