import { Button } from 'components/ui/button'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { useState } from 'react'
import { useGetTemplatesQuery } from 'app/features/whatsapp'
import { cn } from 'lib/utils'

interface SelectTemplateProps {
  selectedTemplate: any
  selectTemplate: (template: any) => void
  displayLabel?: boolean
  TriggerIcon?: any
  displayCheckIcon?: boolean
}

export const SelectTemplate = ({
  selectTemplate,
  selectedTemplate,
  displayLabel = true,
  TriggerIcon = null,
  displayCheckIcon = true,
}: SelectTemplateProps) => {
  const [open, setOpen] = useState(false)
  const { data: templates, isLoading, isError } = useGetTemplatesQuery()

  return (
    <>
      {displayLabel && <div>Select Template</div>}
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger
          asChild
          className={`${!TriggerIcon && 'min-w-[220px]'}`}
        >
          {TriggerIcon ? (
            <Button variant="ghost" size="icon">
              <TriggerIcon />
            </Button>
          ) : (
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="justify-between border-gray-300 shadow-sm dark:border-gray-800 bg-white dark:bg-gray-900"
            >
              {selectedTemplate?.id ? selectedTemplate.name : 'Select Template'}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          {isError && (
            <div className="w-[300px] h-[200px] grid place-content-center">
              <Error message="Server error, contact administrator please" />
            </div>
          )}
          {isLoading && (
            <div className="w-[300px] h-[200px] grid place-content-center">
              <Loader />
            </div>
          )}
          {!isError && !isLoading && (
            <Command className="w-[200px] overflow-y-auto ">
              <CommandInput placeholder={`Search Templates`} className="h-9" />
              <CommandEmpty>List is empty</CommandEmpty>
              <CommandGroup>
                <ScrollArea className="h-52 w-full">
                  {templates?.data?.map((item: any) => (
                    <>
                      {item.status == 'APPROVED' && (
                        <CommandItem
                          id={item.id}
                          key={`${item.id}`}
                          value={item}
                          onSelect={(currentValue) => {
                            selectTemplate(item)
                            setOpen(false)
                          }}
                          className="capitalize hover:bg-white"
                        >
                          {item.name}
                          {displayCheckIcon && (
                            <CheckIcon
                              className={cn(
                                'ml-auto h-4 w-4',
                                selectedTemplate?.id === item?.id
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                          )}
                        </CommandItem>
                      )}
                    </>
                  ))}
                </ScrollArea>
              </CommandGroup>
            </Command>
          )}
        </PopoverContent>
      </Popover>
    </>
  )
}
