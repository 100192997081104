import { api } from '../api'

interface StatisticsReturnedData {
  totalConversations: number
  totalNewConversations: number
  totalOpenConversations: number
  totalRepliedConversations: number
  totalSentMessages: number
  totalDeliveredMessages: number
  totalReadMessages: number
  totalFailedMessages: number
}

interface StatisticsArguments {
  from: string
  to: string
}

interface AnalyticsReturnedData {
  start: string
  end: string
  sent: number
  delivered: number
}

interface AnalyticsArguments {
  from: string
  to: string
  granularity: string
}

interface ResponseReportReturnedData {
  system: {
    avgFirstResponseTime: number
    avgOverallResponseTime: number
    missedMessages: number
  }
  agents: {
    avgFirstResponseTime: number
    avgOverallResponseTime: number
    missedMessages: number
  }[]
}

interface ResponseReportArguments {
  startDate: string
  endDate: string
}

interface CampingsReturnedData {
  createdAt: string
  succeeded: number
  failed: number
}

interface CampingsArguments {
  from: string
  to: string
}

export const whatsappStats = api.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get Whatsapp Statistics.
     */
    getStatistics: builder.query<StatisticsReturnedData, StatisticsArguments>({
      query: (filters) =>
        `/whatsapp/whatsapp-statistics?from=${filters.from}&to=${filters.to}`,
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Analytics.
     */
    getAnalytics: builder.query<AnalyticsReturnedData[], AnalyticsArguments>({
      query: (filters) =>
        `/whatsapp/whatsapp-analytics?from=${filters.from}&to=${filters.to}&granularity=${filters.granularity}`,
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Response Time Report.
     */
    getResponseTimeReport: builder.query<
      ResponseReportReturnedData,
      ResponseReportArguments
    >({
      query: (filters) =>
        `/whatsapp/responses-report?filters=${JSON.stringify(filters)}`,
      providesTags: ['WhatsappStats'],
    }),

    /**
     * Get Whatsapp Campaigns.
     */
    getCampaigns: builder.query<CampingsReturnedData[], CampingsArguments>({
      query: (filters) =>
        `/whatsapp/campaigns?filters=${JSON.stringify(filters)}`,
      providesTags: ['WhatsappStats'],
    }),
  }),
})

export const {
  useGetStatisticsQuery,
  useGetAnalyticsQuery,
  useGetResponseTimeReportQuery,
} = whatsappStats
