import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
  Svg,
  StyleSheet,
} from '@react-pdf/renderer'
import dayjs from 'dayjs'

const styles = StyleSheet.create({
  page: { padding: 40, paddingTop: 90, position: 'relative' },
  mainHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 50,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
  },
  mainInfo: {
    padding: 50,
    gap: 20,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 0,

    position: 'relative',
  },
  chart: { padding: 5 },
  section: {
    margin: 30,
    padding: 10,
    flexGrow: 1,
  },

  userInfoContainer: {
    gap: 10,
    marginTop: 40,
    width: '100%',
    padding: 20,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 10,
    backgroundColor: '#f9f9f9',
    fontFamily: 'Helvetica',
  },
  userInfo: {
    fontSize: 12,
    color: 'black',
    gap: 5,
    fontWeight: 800,
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  bullet: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  bulletItem: {
    color: 'gray',
    fontSize: 10,
    marginLeft: 10, // Indent bullet points
    marginBottom: 3,
  },
  userDetails: { color: 'gray', fontSize: 10, marginBottom: 3 },
  dateDetails: {
    color: 'gray',
    fontSize: 10,
    margin: 7,
    marginBottom: 3,
    marginTop: 3,
  },
  dateInfoContainer: {
    gap: 10,
    marginTop: 20,
    width: '100%',
    padding: 10,
    borderWidth: 0.5,
    borderColor: '#08B3E2',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#f9f9f9',
    fontFamily: 'Helvetica',
  },
  dateInfo: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  container: {
    padding: 12,
    borderWidth: 1,
    borderColor: '#d1d5db', // slate-300
    borderRadius: 5,
    position: 'relative',
    marginTop: 8,
  },

  noLeads: {
    textAlign: 'center',
    color: '#6b7280', // gray-500
    fontSize: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // gray-100
    paddingBottom: 4,
    marginBottom: 4,
  },
  leadRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6', // gray-100
    paddingVertical: 4,
    justifyContent: 'space-between',
  },
  leadCell: {
    fontSize: 10,
    flexGrow: 1,
    textAlign: 'left',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 90,
  },

  title: {
    backgroundColor: 'white',
    color: '#08B3E2',
    fontSize: 14,
    fontWeight: 'bold',
  },
  clinicDescriptionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 20,
    textAlign: 'center',
    color: '#333',
  },
  logo: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 100, // Adjust as needed
    height: 'auto',
  },
  clinicLogo: {
    position: 'absolute',
    top: 0,
    left: 50,
    width: 80, // Adjust as needed
    height: 'auto',
  },
  clinicDescription: {
    marginTop: 15,
    fontSize: 12,
    lineHeight: 1.5,
    color: '#555',
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fff',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    textAlign: 'center',
    fontSize: 8,
    color: '#6b7280', // gray-500
  },
})

const Header = ({ clinicLogoUrl }: any) => {
  return (
    <>
      <Image
        src={{
          uri: clinicLogoUrl,
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.clinicLogo}
      />

      <Image
        src={{
          uri: '/logo.jpeg',
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: '',
        }}
        style={styles.logo}
      />
    </>
  )
}

const Footer = () => {
  return (
    <View style={styles.footer}>
      <Text>
        {`\u00A9`} Doctorna By Code Guru, Al Barsha Business Centre Dubai, UAE,
        Mobile: +971 58 696 4342 | admin@codeguru.ae | All Rights Reserved.
      </Text>
    </View>
  )
}
export const UserPerformanceReport = ({
  userPerformanceImage,
  sourcesImage,
  user,
  showBookings,
  noShowBooking,
  startDate,
  endDate,
  sourcesStatisticsLength,
  clinicDescription,
}: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />
        <View style={styles.mainInfo}>
          <View style={styles.dateInfoContainer}>
            <View>
              <View style={styles.dateInfo}>
                <Text>Generated At: </Text>
                <Text style={styles.dateDetails}>
                  {dayjs(new Date()).format('DD/MM/YYYY h:mm A')}
                </Text>
              </View>

              <View style={styles.dateInfo}>
                <Text>Date range: </Text>
                {startDate || endDate ? (
                  <>
                    {startDate && (
                      <Text style={styles.dateDetails}>
                        From: {dayjs(startDate).format('DD/MM/YYYY')}
                      </Text>
                    )}
                    {startDate && (
                      <Text style={styles.dateDetails}>
                        Until: {dayjs(endDate).format('DD/MM/YYYY')}
                      </Text>
                    )}
                  </>
                ) : (
                  <Text style={styles.dateDetails}>All Time</Text>
                )}
              </View>
            </View>
          </View>

          <View style={styles.userInfoContainer}>
            <View style={styles.userInfo}>
              <Text style={styles.name}>
                Name: {user.firstName + ' ' + user.lastName}
              </Text>
              <Text style={styles.userDetails}>Email: {user.email}</Text>
              {user.role === 'ADMIN' || user.role === 'SUPERADMIN' ? (
                <>
                  <Text
                    style={{
                      ...styles.bulletItem,
                    }}
                  >
                    • Has full access
                  </Text>{' '}
                </>
              ) : (
                <>
                  <Text style={styles.userDetails}>
                    Group: {user.group?.name}
                  </Text>
                  <Text style={styles.bullet}>Branches:</Text>
                  {user.branches.map((branch: any) => (
                    <Text style={styles.bulletItem} key={branch.id}>
                      • {branch.name}
                    </Text>
                  ))}
                  <Text style={styles.bullet}>Departments:</Text>
                  {user.departments.map((department: any) => (
                    <Text style={styles.bulletItem} key={department.id}>
                      • {department.name}
                    </Text>
                  ))}
                  <Text style={styles.bullet}>Specialists:</Text>
                  {user.specialists.map((specialist: any) => (
                    <Text style={styles.bulletItem} key={specialist.id}>
                      • {specialist.name}
                    </Text>
                  ))}
                </>
              )}
            </View>
          </View>
        </View>
        <Footer />
      </Page>
      <Page
        size="A4"
        style={{ padding: 10, paddingTop: 40, position: 'relative' }}
      >
        {' '}
        <Image
          src={{
            uri: '/logo.jpeg',
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
            body: '',
          }}
          style={styles.logo}
        />
        <View style={styles.chartContainer}>
          <View style={{ ...styles.chart, height: '30%' }}>
            <View style={{ gap: 5 }}>
              <Text style={{ color: 'indigo' }}>User Performance</Text>
              <Text
                style={{ fontStyle: 'italic', color: 'gray', fontSize: 10 }}
              >
                Score:{user.userScore}
              </Text>
            </View>
            <Image
              src={userPerformanceImage}
              style={{ width: '40%', height: '100%' }}
            />
          </View>
          <View style={{ ...styles.chart, flexGrow: 1 }}>
            <View style={{ gap: 5 }}>
              <Text style={{ color: 'indigo' }}>By Sources</Text>
            </View>
            <Image
              src={sourcesImage}
              style={{
                width: '100%',

                height:
                  sourcesStatisticsLength <= 2
                    ? '40%'
                    : sourcesStatisticsLength <= 4
                      ? '60%'
                      : sourcesStatisticsLength <= 6
                        ? '80%'
                        : sourcesStatisticsLength <= 9
                          ? '80%'
                          : '100%',
              }}
            />
          </View>
        </View>
        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{user.leads?.length} leads created</Text>

        <View style={styles.container}>
          {user.leads?.length < 1 ? (
            <Text style={styles.noLeads}>No leads</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Source
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Phone
                </Text>
              </View>
              {user.leads?.map((lead: any) => (
                <View key={lead.id} style={styles.leadRow}>
                  <Text
                    style={styles.leadCell}
                  >{`${lead.firstName} ${lead.lastName}`}</Text>
                  <Text style={styles.leadCell}>{lead.source?.name}</Text>
                  <Text style={styles.leadCell}>{lead.phone}</Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{user.followUps?.length} follow ups</Text>

        <View style={styles.container}>
          {user.followUps?.length < 1 ? (
            <Text style={styles.noLeads}>No follow ups</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Comment
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Date
                </Text>
              </View>
              {user.followUps?.map((followUp: any) => (
                <View key={followUp?.id} style={styles.leadRow}>
                  <Text style={styles.leadCell}>
                    {`${followUp?.lead?.firstName} ${followUp?.lead?.lastName}`}
                  </Text>
                  <Text style={styles.leadCell}>{followUp?.comment}</Text>
                  <Text style={styles.leadCell}>
                    {dayjs(followUp?.date).format('DD/MM/YYYY')}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{user.bookings?.length} bookings</Text>

        <View style={styles.container}>
          {user.bookings?.length < 1 ? (
            <Text style={styles.noLeads}>No bookings</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Name
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Date
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Branch
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Department
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Specialist
                </Text>
              </View>
              {user.bookings.map((booking: any) => (
                <View key={booking?.id} style={styles.leadRow}>
                  <Text
                    style={styles.leadCell}
                  >{`${booking?.lead?.firstName} ${booking?.lead?.lastName}`}</Text>
                  <Text style={styles.leadCell}>
                    {dayjs(booking?.date).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={styles.leadCell}>{booking?.branch?.name}</Text>
                  <Text style={styles.leadCell}>
                    {booking?.department?.name}
                  </Text>
                  <Text style={styles.leadCell}>
                    {booking?.specialist?.name}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>{showBookings?.length} show bookings</Text>

        <View style={styles.container}>
          {showBookings?.length < 1 ? (
            <Text style={styles.noLeads}>No Show bookings</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Name
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Date
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Branch
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Department
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Specialist
                </Text>
              </View>
              {showBookings.map((booking: any) => (
                <View key={booking?.id} style={styles.leadRow}>
                  <Text
                    style={styles.leadCell}
                  >{`${booking?.lead?.firstName} ${booking?.lead?.lastName}`}</Text>
                  <Text style={styles.leadCell}>
                    {dayjs(booking?.date).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={styles.leadCell}>{booking?.branch?.name}</Text>
                  <Text style={styles.leadCell}>
                    {booking?.department?.name}
                  </Text>
                  <Text style={styles.leadCell}>
                    {booking?.specialist?.name}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>
          {noShowBooking?.length} no-show bookings
        </Text>

        <View style={styles.container}>
          {noShowBooking?.length < 1 ? (
            <Text style={styles.noLeads}>No No-Show bookings</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Name
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Date
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Branch
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Department
                </Text>
                <Text style={{ fontWeight: 'bold', ...styles.leadCell }}>
                  Specialist
                </Text>
              </View>
              {noShowBooking.map((booking: any) => (
                <View key={booking?.id} style={styles.leadRow}>
                  <Text
                    style={styles.leadCell}
                  >{`${booking?.lead?.firstName} ${booking?.lead?.lastName}`}</Text>
                  <Text style={styles.leadCell}>
                    {dayjs(booking?.date).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={styles.leadCell}>{booking?.branch?.name}</Text>
                  <Text style={styles.leadCell}>
                    {booking?.department?.name}
                  </Text>
                  <Text style={styles.leadCell}>
                    {booking?.specialist?.name}
                  </Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.page}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.title}>
          {user.notQualifiedLeads?.length} leads not qualified
        </Text>

        <View style={styles.container}>
          {user.notQualifiedLeads?.length < 1 ? (
            <Text style={styles.noLeads}>No not-qualified leads</Text>
          ) : (
            <>
              <View style={styles.header}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Source
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    ...styles.leadCell,
                    fontSize: 15,
                  }}
                >
                  Phone
                </Text>
              </View>
              {user.notQualifiedLeads?.map((lead: any) => (
                <View key={lead.id} style={styles.leadRow}>
                  <Text
                    style={styles.leadCell}
                  >{`${lead.firstName} ${lead.lastName}`}</Text>
                  <Text style={styles.leadCell}>{lead.source?.name}</Text>
                  <Text style={styles.leadCell}>{lead.phone}</Text>
                </View>
              ))}
            </>
          )}
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={{ ...styles.page, backgroundColor: '#f9f9f9' }}>
        <Header clinicLogoUrl={'/clinic-logo.jpeg'} />

        <Text style={styles.clinicDescriptionTitle}>About Us</Text>

        <Text style={styles.clinicDescription}>{clinicDescription}</Text>
        <Footer />
      </Page>
    </Document>
  )
}
