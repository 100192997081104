import { PreviewMedia } from './preview-media'
import dayjs from 'dayjs'
import { replaceAndConvertPlaceholders } from 'lib/utils'
interface PreviewTemplateProps {
  template: any
  headerMediaSample: any
  headerTextSample: string[]
  bodySample: string[]
}

export default function PreviewTemplateMessage({
  template,
  headerMediaSample,
  headerTextSample,
  bodySample,
}: PreviewTemplateProps) {
  const headerComponent = template?.components?.find(
    (component: any) => component.type == 'HEADER'
  )

  const bodyComponent = template?.components?.find(
    (component: any) => component.type == 'BODY'
  )
  const footerComponent = template?.components?.find(
    (component: any) => component.type == 'FOOTER'
  )

  const buttonComponent = template?.components?.find(
    (component: any) => component.type == 'BUTTONS'
  )

  const headerFormat = headerComponent?.format

  return (
    <div className="sm:max-w-[425px] md:max-w-[600px] bg-white dark:bg-gray-900 pr-3">
      <div className="h-full w-full">
        <div className="w-full max-h-[500px]  overflow-hidden overflow-y-scroll mt-4 flex flex-col gap-2 p-4 whitespace-pre-wrap">
          <span className="bg-gray-100 text-white py-2 px-4 rounded-md max-w-xs">
            <span className="font-bold text-sm text-black">Sender Name</span>
            {headerComponent && headerFormat == 'TEXT' && (
              <span className="block mt-1 w-full text-black font-bold">
                {/*
                // TODO: to be deleted
                {headerComponent?.text?.replace(
                  /{{(\d+)}}/g,
                  (_: any, num: any) =>
                    headerComponent?.example?.header_text[+num - 1] || ''
                )} */}
                {replaceAndConvertPlaceholders(
                  headerComponent?.text,
                  headerTextSample
                )}
              </span>
            )}
            {headerComponent && headerFormat != 'TEXT' && (
              <PreviewMedia
                type={headerFormat}
                href={
                  headerMediaSample
                  //headerComponent?.example?.header_handle[0]
                }
                filename={'document'}
              />
            )}
            <span className="block mt-1 w-full text-black">
              {/*
              // TODO: to be deleted
              {bodyComponent?.text.replace(
                /{{(\d+)}}/g,
                (_: any, num: any) =>
                  bodyComponent?.example?.body_text[0][+num - 1] || ''
              )} */}
              {replaceAndConvertPlaceholders(bodyComponent?.text, bodySample)}
            </span>
            {footerComponent && (
              <span className="block mt-1 w-full text-gray-500 text-xs">
                {footerComponent?.text}
              </span>
            )}
            {buttonComponent && (
              <>
                {buttonComponent.buttons.map((button: any) => {
                  return (
                    <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                      {button.text}
                    </div>
                  )
                })}
              </>
            )}
            <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
              {dayjs(new Date()).format('YYYY-MM-DD HH:mm A')}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
