import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { TbInfoCircle } from 'react-icons/tb'
import './dashboard-stat.css'

interface CardProps {
  number: number | string | undefined
  icon: React.ReactNode
  title: string
  description: string
}

/**
 * A card component that displays a number, icon, title, and description.
 * The description is shown in a tooltip on hover.
 *
 * @param number - The number to display.
 * @param icon - The icon to display.
 * @param title - The title to display.
 * @param description - The description to display in the tooltip.
 */
const Card: React.FC<CardProps> = ({ number, icon, title, description }) => {
  return (
    <div className="p-4 bg-white shadow-lg rounded-lg border border-gray-200">
      {/* First Row */}
      <div className="flex justify-between items-center mb-4">
        <span className="text-2xl font-bold text-gray-700">{number}</span>
        <div className="text-blue-500">{icon}</div>
      </div>

      {/* Second Row */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-800">{title}</h3>

        <Tooltip.Provider delayDuration={50}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button className="IconButton">
                <TbInfoCircle />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="TooltipContent" sideOffset={5}>
                {description}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </div>
  )
}

export default Card
