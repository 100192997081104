import { useEffect, useState, useRef } from 'react'
import html2canvas from 'html2canvas'
import Chart from './chart'

export default function ChartSection({ id, data }: any) {
  const chartRef = useRef<any>(null)

  async function getImageUrl(chartRef: any) {
    const canvas = await html2canvas(chartRef.current)
    return canvas.toDataURL('image/png')
  }

  async function handleReportImage() {
    const base64Image = await getImageUrl(chartRef)
    sessionStorage.setItem(`chart-${id}`, base64Image)
  }

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {
        handleReportImage()
      }
    }, 3000)
  }, [chartRef])
  return (
    <div ref={chartRef}>
      <Chart data={data} />
    </div>
  )
}
