import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useCreateTemplateMutation,
  useSessionUploadFileMutation,
} from 'app/features/whatsapp'
import { CustomInput } from 'components/shared/custom-input'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button, buttonVariants } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  capitalizeFirstLetter,
  cn,
  extractPlaceholdersIntoArray,
  prepareMediaFormData,
  replacePlaceholdersByIndices,
} from 'lib/utils'
import { isLowercaseUnderscore, isPhone, isURL } from 'lib/validation'
import { useEffect, useRef, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { GiReturnArrow } from 'react-icons/gi'
import { toast } from 'components/ui/use-toast'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { IoAddCircleOutline } from 'react-icons/io5'
import { HiVariable } from 'react-icons/hi2'
import { HiOutlineEmojiHappy } from 'react-icons/hi'
import Picker from 'emoji-picker-react'
import PhoneInput from 'react-phone-input-2'

export const TEMPLATE_VARIABLES = ['FIRST_NAME', 'EMAIL', 'NATIONALITY']
const TEMPLATE_BUTTONS: any = {
  QUICK_REPLY: {
    type: 'QUICK_REPLY',
    label: 'UNSUBSCRIBE',
  },
  URL: {
    type: 'URL',
    label: 'URL',
  },
  PHONE_NUMBER: {
    type: 'PHONE_NUMBER',
    label: 'PHONE NUMBER',
  },
}
const initialData = {
  name: '',
  language: '',
  category: '',
  header: 'none',
  headerText: '',
  headerTextSample: {},
  headerMedia: 'IMAGE',
  headerMediaSampleFile: null,
  body: '',
  bodySample: {},
  footer: '',
  buttons: [],
}

export default function CreateTemplate({ open, setOpen }: any) {
  const [createTemplateFunction] = useCreateTemplateMutation()
  const [sessionUploadFileFunction] = useSessionUploadFileMutation()
  // const [open, setOpen] = useState(false)
  const [data, setData] = useState<any>(initialData)
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setStep(1)
    setData(initialData)
  }, [open])

  useEffect(() => {
    setError('')
  }, [step])

  function validate1() {
    if (data.name.length < 1) {
      return setError('Name is required')
    }

    if (!isLowercaseUnderscore(data.name)) {
      return setError('Name can only have lowercase letters and underscores')
    }

    if (data.category === '') {
      return setError('Category is required')
    }

    if (data.language === '') {
      return setError('Language is required')
    }

    setStep(step + 1)
  }

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  async function createTemplate() {
    setLoading(true)
    setError('')

    // TODO: to be deleted
    /* header text variables validation */
    /*
    const headerTextVariablesCount = Array.from(
      data['headerText'].matchAll(/{{(\d+)}}/g),
      (match: any) => match[1]
    )
    const headerTextSample = Object.values(data.headerTextSample)
    if (
      headerTextSample.length < headerTextVariablesCount.length ||
      headerTextSample.some((element) => element == '')
    ) {
      {
        setLoading(false)
        return setError('Header text variables sample required')
      }
    }
*/
    const headerText = replacePlaceholdersByIndices(data['headerText'])
    const headerTextSample = extractPlaceholdersIntoArray(data['headerText'])
    /* header media sample validation */
    if (data.header == 'media' && !data.headerMediaSampleFile) {
      setLoading(false)
      return setError('Media sample required')
    }

    /* body validation */
    if (data.body === '') {
      setLoading(false)
      return setError('Body is required')
    }

    // TODO: to be deleted
    /* body variables validation */
    /*const bodyVariablesCount = Array.from(
      data['body'].matchAll(/{{(\d+)}}/g),
      (match: any) => match[1]
    )
    const bodySample = Object.values(data.bodySample)
    if (
      bodySample.length < bodyVariablesCount.length ||
      bodySample.some((element) => element == '')
    ) {
      {
        setLoading(false)
        return setError('Body variables sample required')
      }
    }
      */
    const body = replacePlaceholdersByIndices(data['body'])
    const bodySample = extractPlaceholdersIntoArray(data['body'])

    try {
      /* Header media upload */
      let headerMediaSampleFile = null
      if (data.header == 'media') {
        const formData = prepareMediaFormData({
          my_file: data.headerMediaSampleFile,
        })
        const uploadResult: any = await sessionUploadFileFunction(formData)
        if (uploadResult?.error) {
          setLoading(false)
          return setError('Failed to upload media, please try again')
        }
        headerMediaSampleFile = uploadResult?.data?.h
      }

      /* create template */
      const result: any = await createTemplateFunction({
        ...data,
        headerMediaSampleFile,
        headerText,
        headerTextSample,
        body,
        bodySample,
      })

      if (result?.error) {
        setLoading(false)
        return setError('Failed to create template, please try again')
      }
      toast({
        title: 'Template added successfully!',
        description:
          'new template added successfully, it will be reviewed by Meta, if accepted, you can start use it',
      })
      setLoading(false)
      setData(initialData)
      setOpen(false)
    } catch (error) {
      setLoading(false)
      return setError('Failed to create template, please try again')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {/* <AlertDialogTrigger asChild>
        <span>New Template</span>
      </AlertDialogTrigger> */}

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
            <div className="flex w-full items-center justify-between">
              <h1 className="text-lg font-semibold capitalize">New Template</h1>

              <Button
                variant="outline"
                size="icon"
                onClick={() => setOpen(false)}
              >
                <Cross1Icon className="h-3 w-3" />
              </Button>
            </div>
            {step === 1 && (
              <>
                <div className="w-full space-y-3 mt-5 px-1">
                  <div className="w-full flex items-center gap-3">
                    <CustomInput
                      name="name"
                      label="Name"
                      value={data.name}
                      setValue={handleChange}
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-full flex items-center gap-3">
                    <div className="w-full space-y-2">
                      <Label>
                        Language <span className="ml-1 text-rose-500">*</span>
                      </Label>
                      <Select
                        value={data.language}
                        onValueChange={(value: any) =>
                          setData({ ...data, language: value })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue>
                            {capitalizeFirstLetter(
                              data.language == 'en'
                                ? 'English'
                                : data.language == 'ar'
                                  ? 'Arabic'
                                  : ''
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value={'ar'}>Arabic</SelectItem>
                            <SelectItem value={'en'}>English</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="w-full space-y-2">
                      <Label>
                        Category
                        <span className="ml-1 text-rose-500">*</span>
                      </Label>
                      <Select
                        value={data.category}
                        onValueChange={(value: any) =>
                          setData({ ...data, category: value })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue>
                            {capitalizeFirstLetter(data.category)}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value={'UTILITY'}>
                              Utility (Reminder, Location, Appt. status){' '}
                            </SelectItem>
                            <SelectItem value={'MARKETING'}>
                              Marketing (Boost offers and promos){' '}
                            </SelectItem>
                            <SelectItem value={'AUTHENTICATION'}>
                              Authentication (OTP)
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex items-center">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}
                  <div className="flex-1" />
                  <Button size="sm" onClick={validate1}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {step === 2 && (
              <div className="space-y-3 mt-5 ">
                <div className="w-full space-y-3 mt-5 px-1">
                  <div className="w-full space-y-2  p-2 border-t">
                    <Label>Header</Label>
                    <Select
                      value={data.header}
                      onValueChange={(value: any) =>
                        setData({
                          ...data,
                          header: value,
                        })
                      }
                    >
                      <SelectTrigger className="w-1/3 ">
                        <SelectValue>{data.header}</SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="none">none</SelectItem>
                          <SelectItem value="text">text</SelectItem>
                          <SelectItem value="media">media</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {data?.header == 'text' && (
                      <DynamicTextArea
                        data={data}
                        setData={setData}
                        indexKey={'headerText'}
                        maxVars={1}
                      />
                    )}
                    {data.header == 'media' && (
                      <MediaSelect
                        data={data}
                        setData={setData}
                        indexKey="headerMedia"
                      />
                    )}
                  </div>
                  <div className="w-full space-y-2  p-2  ">
                    <Label>
                      Body <span className="ml-1 text-rose-500">*</span>
                    </Label>
                    <DynamicTextArea
                      data={data}
                      setData={setData}
                      indexKey={'body'}
                    />
                  </div>
                  <div className="w-full flex items-center gap-3 p-2 ">
                    <CustomInput
                      name="footer"
                      label="Footer"
                      value={data.footer}
                      setValue={handleChange}
                      type="text"
                    />
                  </div>
                  <div className="w-full space-y-2  p-2  ">
                    <Label>
                      Buttons{' '}
                      {data.buttons.length > 0
                        ? `(${data.buttons.length})`
                        : ''}
                    </Label>
                    <Buttons
                      data={data}
                      setData={setData}
                      indexKey={'buttons'}
                    />
                  </div>
                </div>
                {error !== '' && (
                  <div className="text-rose-600 text-sm">{error}</div>
                )}
                <div className="flex items-center gap-3 justify-between">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  <Button size="sm" onClick={createTemplate} disabled={loading}>
                    Create Template
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function DynamicTextArea({ data, setData, indexKey, maxVars }: any) {
  const [lastIndex, setLastIndex] = useState(0)
  const sampleKey = indexKey + 'Sample'
  const [placeHolders, setPlaceHolders] = useState<any>([])
  const [newVariable, setNewVariable] = useState<any>()
  const [openDropdownVariables, setOpenDropdownVariables] = useState(false)
  const [showPicker, setShowPicker] = useState(false)

  useEffect(() => {
    const placeholders = Array.from(
      data[indexKey].matchAll(/{{(\d+)}}/g),
      (match: any) => match[1]
    )
    setPlaceHolders(placeholders)
  }, [data[indexKey]])

  useEffect(() => {
    setLastIndex(placeHolders.length)
    const variables = data[sampleKey]
    const filteredVariables = Object.keys(variables).reduce(
      (acc: any, key: string) => {
        if (placeHolders.includes(key)) {
          acc[key] = variables[key]
        }
        return acc
      },
      {}
    )
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: filteredVariables,
    }))
  }, [placeHolders])

  const handleTextChange = (e: any) => {
    const newText = e.target.value
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: newText,
      }
    })
  }

  // TODO: to be deleted
  const addVariable = () => {
    const newIndex = lastIndex + 1
    const newVariable = `{{${+newIndex}}}`

    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: prev[indexKey] + ' ' + newVariable + ' ',
      }
    })
    setLastIndex(newIndex)
  }

  const addStaticVariable = (variable: string) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]:
          prev[indexKey] +
          ' {{' +
          variable.toUpperCase().replace(' ', '_') +
          '}} ',
      }
    })
    setOpenDropdownVariables(false)
  }

  // TODO: to be deleted
  const handleVariableValueChange = (index: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [sampleKey]: { ...prev[sampleKey], [index]: value },
    }))
  }
  const resetHandler = () => {
    setLastIndex(0)
    setPlaceHolders([])
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: '',
        [sampleKey]: {},
      }
    })
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: prev[indexKey] + emojiObject.emoji,
      }
    })
    setShowPicker(false)
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center relative flex-1">
          <DropdownMenu open={openDropdownVariables}>
            <DropdownMenuTrigger
              onClick={() => {
                setOpenDropdownVariables(true)
              }}
            >
              <Button
                variant={'outline'}
                className="text-xs  w-32 flex items-center gap-1 justify-center text-indigo-600"
              >
                {' '}
                <IoAddCircleOutline size={16} />
                Variables
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-96 p-2 max-h-96 overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col gap-2 py-2">
                  <h1>Select variable</h1>
                  {maxVars && placeHolders.length == 0 && (
                    <span className=" text-xs italic text-gray-500 ml-2 mb-2">
                      You can add up to {maxVars} variable!
                    </span>
                  )}
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpenDropdownVariables(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <DropdownMenuSeparator />

              {TEMPLATE_VARIABLES.map((variable: string, index: number) => {
                return (
                  <div
                    className={`flex flex-col gap-2 border-b p-2 rounded-md hover:cursor-pointer hover:bg-slate-100 ${index == TEMPLATE_VARIABLES.length - 1 && 'border-b-0'}`}
                    onClick={() => {
                      addStaticVariable(variable)
                    }}
                  >
                    <h1 className="text-sm text-indigo-600">{`{{${variable}}}`}</h1>
                    <span className=" text-xs text-gray-500 ml-2 mb-2">
                      Add the lead's {variable.toLowerCase().replace('_', ' ')}
                    </span>
                  </div>
                )
              })}

              <DropdownMenuSeparator />
              <div className="flex flex-col gap-1 p-1">
                <h1 className="text-sm">Other</h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Add other variable to be filled when sending this template
                </span>

                <div className="flex items-center gap-3 ">
                  <Input
                    value={newVariable}
                    onChange={(e: any) => setNewVariable(e.target.value)}
                    type="text"
                    placeholder="e.g. DISCOUNT"
                  />
                  <Button
                    onClick={() => {
                      addStaticVariable(newVariable)
                      setNewVariable('')
                    }}
                    disabled={maxVars && placeHolders.length >= maxVars}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              {/* 
              // TODO: to be deleted
              <div className="flex flex-col gap-2 py-2">
              <Button
                onClick={addVariable}
                disabled={maxVars && placeHolders.length >= maxVars}
                variant={'outline'}
                className="text-xs w-20 flex items-center gap-1 justify-center"
              >
                Add
                <IoAddCircleOutline size={16} />
              </Button>
            </div>
            <span className="text-xs ml-2 italic text-gray-500 flex flex-col items-start gap-2">
              * fields contain /name/ will be prefilled with lead name.
            </span>
            <div className="flex flex-col gap-2 max-h-32 overflow-y-scroll p-2">
              {placeHolders.map((placeholder: any, index: any) => (
                <Input
                  type={'text'}
                  className="w-44 h-8 max-h-[2rem]  min-h-[2rem]"
                  placeholder={`Enter content for {{${placeholder}}}`}
                  value={data[sampleKey][placeholder] || ''}
                  onChange={(e) =>
                    handleVariableValueChange(placeholder, e.target.value)
                  }
                />
              ))}
            </div> */}
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowPicker((val) => !val)}
          >
            <HiOutlineEmojiHappy
              size={20}
              className="emoji-icon hover:cursor-pointer text-indigo-600"
            />
          </Button>

          <div className="picker-container  absolute">
            {showPicker && (
              <Picker
                pickerStyle={{ width: '100%' }}
                onEmojiClick={onEmojiClick}
              />
            )}
          </div>
        </div>

        <Button
          onClick={resetHandler}
          variant={'outline'}
          className="text-xs font-medium flex items-center"
        >
          reset
          <GiReturnArrow
            size={10}
            className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
          />
        </Button>
      </div>
      <div className=" py-2 px-4 mt-2 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <textarea
          className="px-0 w-full  mb-3 text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
          value={data[indexKey]}
          placeholder="Add a text..."
          onChange={handleTextChange}
          rows={4}
        ></textarea>
      </div>{' '}
    </div>
  )
}

function MediaSelect({ setData, data, indexKey }: any) {
  const fileRef = useRef<any>()
  const sampleKey = indexKey + 'SampleFile'

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setData((prev: any) => {
      return { ...prev, [sampleKey]: null }
    })
  }

  const handleMediaFileChange = (event: any) => {
    const file = event.target.files[0]
    setData((prev: any) => {
      return { ...prev, [sampleKey]: file }
    })
  }
  return (
    <div>
      <div className=" flex items-start my-4">
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'IMAGE' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'IMAGE',
              }
            })
          }}
        >
          Image
        </Button>
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'VIDEO' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'VIDEO',
              }
            })
          }}
        >
          Video
        </Button>
        <Button
          className={` transition-all w-20 h-8 rounded-none ${data[indexKey] == 'DOCUMENT' ? 'bg-indigo-600' : 'bg-indigo-100'}`}
          onClick={() => {
            replaceFileHandler()
            setData((prev: any) => {
              return {
                ...prev,
                [indexKey]: 'DOCUMENT',
              }
            })
          }}
        >
          Document
        </Button>
      </div>

      <div className="flex mt-2  flex-col gap-2">
        <div className="w-full items-center flex justify-between">
          <label
            htmlFor="media"
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-32 border text-left text-xs',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            Add Sample
            <input
              id="media"
              type="file"
              ref={fileRef}
              accept={
                data[indexKey] == 'IMAGE'
                  ? 'image/*'
                  : data[indexKey] == 'VIDEO'
                    ? 'video/*'
                    : '*'
              }
              style={{ display: 'none' }}
              className="cursor-pointer"
              onChange={handleMediaFileChange}
            />
          </label>

          <Button
            onClick={replaceFileHandler}
            variant={'outline'}
            className="text-xs font-medium flex items-center"
          >
            reset
            <GiReturnArrow
              size={10}
              className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
            />
          </Button>
        </div>
        <span className="text-xs">{data[sampleKey]?.name}</span>
      </div>
    </div>
  )
}

function Buttons({ data, setData, indexKey }: any) {
  const [openAddButton, setOpenAddButton] = useState(false)

  const [quickReplyText, setQuickReplyText] = useState('')
  const [quickReplyError, setQuickReplyError] = useState('')
  const isQuickReplyDisabled = data[indexKey].find(
    (item: any) => item.type == TEMPLATE_BUTTONS.QUICK_REPLY.type
  )
  const [urlText, setUrlText] = useState('')
  const [urlValue, setUrlValue] = useState('')
  const [urlError, setUrlError] = useState('')
  const isUrlDisabled = data[indexKey].find(
    (item: any) => item.type == TEMPLATE_BUTTONS.URL.type
  )
  const [phoneNumberText, setPhoneNumberText] = useState('')
  const [phoneNumberValue, setPhoneNumberValue] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const isPhoneNumberDisabled = data[indexKey].find(
    (item: any) => item.type == TEMPLATE_BUTTONS.PHONE_NUMBER.type
  )

  const addButton = (button: {
    type: string
    text: string
    url?: string
    phone_number?: string
  }) => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: [...prev[indexKey], { ...button }],
      }
    })
    setOpenAddButton(false)
  }
  const resetHandler = () => {
    setData((prev: any) => {
      return {
        ...prev,
        [indexKey]: [],
      }
    })
    setQuickReplyError('')
    setQuickReplyText('')
    setPhoneNumberError('')
    setPhoneNumberText('')
    setPhoneNumberValue('')
    setUrlError('')
    setUrlText('')
    setUrlValue('')
  }

  return (
    <div className="py-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2  relative flex-1">
          <DropdownMenu open={openAddButton}>
            <DropdownMenuTrigger
              onClick={() => {
                setOpenAddButton(true)
              }}
            >
              <Button
                variant={'outline'}
                className="text-xs  w-32 flex items-center gap-1 justify-center text-indigo-600"
              >
                {' '}
                <IoAddCircleOutline size={16} />
                Button
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-96 p-2 max-h-[30rem] overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col gap-2 py-2">
                  <h1>Select button</h1>
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpenAddButton(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <DropdownMenuSeparator />

              <div
                className={`flex flex-col gap-1 border-b p-2 rounded-md  ${isQuickReplyDisabled ? 'opacity-60' : ' hover:bg-slate-50'} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.QUICK_REPLY.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to unsubscribing from receiving messages
                  from this system
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={quickReplyText}
                    onChange={(e: any) => setQuickReplyText(e.target.value)}
                    placeholder="e.g. Stop Receiving Messages"
                    disabled={isQuickReplyDisabled}
                  />
                </div>
                <div className="flex justify-between">
                  {quickReplyError ? (
                    <span className=" text-xs text-red-500">
                      {quickReplyError}
                    </span>
                  ) : (
                    <div />
                  )}{' '}
                  <Button
                    onClick={() => {
                      if (!quickReplyText)
                        return setQuickReplyError('displayed text is required')

                      setQuickReplyError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.QUICK_REPLY.type,
                        text: quickReplyText,
                      })
                    }}
                    disabled={isQuickReplyDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              <div
                className={`flex flex-col gap-2 border-b p-2 rounded-md  ${isUrlDisabled ? 'opacity-60' : ' hover:bg-slate-50'} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.URL.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to navigate to some URL
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={urlText}
                    onChange={(e: any) => setUrlText(e.target.value)}
                    placeholder="e.g. Visit Website"
                    disabled={isUrlDisabled}
                  />
                </div>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Value <span className="ml-1 text-rose-500">*</span>
                  </Label>

                  <Input
                    value={urlValue}
                    onChange={(e: any) => setUrlValue(e.target.value)}
                    placeholder="e.g. https://doctorna.com"
                    disabled={isUrlDisabled}
                  />
                </div>
                <div className="flex justify-between">
                  {urlError ? (
                    <span className=" text-xs text-red-500">{urlError}</span>
                  ) : (
                    <div />
                  )}
                  <Button
                    onClick={() => {
                      if (!urlText)
                        return setUrlError('displayed text is required')
                      if (!isURL(urlValue))
                        return setUrlError('valid url is required')

                      setUrlError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.URL.type,
                        text: urlText,
                        url: urlValue,
                      })
                    }}
                    disabled={isUrlDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>

              <div
                className={`flex flex-col gap-2 border-b p-2 rounded-md  ${isPhoneNumberDisabled ? 'opacity-60' : ' hover:bg-slate-50'} `}
              >
                <h1 className="text-sm text-indigo-600">
                  {TEMPLATE_BUTTONS.PHONE_NUMBER.label}
                </h1>
                <span className=" text-xs text-gray-500 ml-2 mb-2">
                  Give lead the ability to Call some Phone Number
                </span>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Displayed <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <Input
                    value={phoneNumberText}
                    onChange={(e: any) => setPhoneNumberText(e.target.value)}
                    placeholder="e.g. Call"
                    disabled={isPhoneNumberDisabled}
                  />
                </div>
                <div className="flex gap-4 items-center">
                  <Label className="text-xs flex items-center w-1/3 ">
                    Value <span className="ml-1 text-rose-500">*</span>
                  </Label>
                  <PhoneInput
                    country={'ae'}
                    value={phoneNumberValue}
                    inputProps={{
                      id: { id: phoneNumberValue },
                      name: { id: phoneNumberValue },
                      required: true,
                    }}
                    disabled={isPhoneNumberDisabled}
                    onChange={(value: any) => setPhoneNumberValue(value)}
                  />
                </div>
                <div className="flex justify-between">
                  {phoneNumberError ? (
                    <span className=" text-xs text-red-500">
                      {phoneNumberError}
                    </span>
                  ) : (
                    <div />
                  )}
                  <Button
                    onClick={() => {
                      if (!phoneNumberText)
                        return setPhoneNumberError('displayed text is required')
                      if (!isPhone(phoneNumberValue))
                        return setPhoneNumberError(
                          'valid phone number is required'
                        )

                      setPhoneNumberError('')
                      addButton({
                        type: TEMPLATE_BUTTONS.PHONE_NUMBER.type,
                        text: phoneNumberText,
                        phone_number: phoneNumberValue,
                      })
                    }}
                    disabled={isPhoneNumberDisabled}
                    variant={'outline'}
                    className="text-xs w-20 flex items-center gap-1 justify-center"
                  >
                    Add
                    <IoAddCircleOutline size={16} />
                  </Button>
                </div>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Button
          onClick={resetHandler}
          variant={'outline'}
          className="text-xs font-medium flex items-center"
        >
          reset
          <GiReturnArrow
            size={10}
            className="ml-2 text-gray-600 hover:cursor-pointer hover:text-gray-900  "
          />
        </Button>
      </div>
      <div className="flex "></div>
    </div>
  )
}
