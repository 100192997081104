import { Cross1Icon } from '@radix-ui/react-icons'
import { useUpdateProfileMutation } from 'app/features/whatsapp'
import { CustomTextArea } from 'components/shared/custom-text-area'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { toast } from 'components/ui/use-toast'
import { prepareMediaFormData } from 'lib/utils'
import { isEmail, isURL } from 'lib/validation'
import { useState } from 'react'

export default function WhatsappProfileSettings({ profile }: any) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [data, setData] = useState<any>({
    email: profile.email || '',
    about: profile.about || '',
    address: profile.address || '',
    description: profile.description || '',
    vertical: profile.vertical || '',
    websites: profile.websites ? profile.websites[0] : '',
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [previewSource, setPreviewSource] = useState('')
  const [updateProfile] = useUpdateProfileMutation()

  const verticalOptions = [
    'AUTO',
    'BEAUTY',
    'APPAREL',
    'EDU',
    'ENTERTAIN',
    'EVENT_PLAN',
    'FINANCE',
    'GROCERY',
    'GOVT',
    'HOTEL',
    'HEALTH',
    'NONPROFIT',
    'PROF_SERVICES',
    'RETAIL',
    'TRAVEL',
    'RESTAURANT',
    'NOT_A_BIZ',
    'OTHER',
  ]

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0]
    previewFile(file)
    setSelectedFile(file)
  }

  const previewFile = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPreviewSource(reader.result?.toString() || '')
    }
  }

  async function updateWhatsappProfile() {
    setError('')
    setLoading(true)
    if (data.email && !isEmail(data.email)) {
      setLoading(false)
      return setError('Email is not valid')
    }
    if (data.websites && !isURL(data.websites)) {
      setLoading(false)
      return setError('Website is not valid')
    }
    try {
      const profile = prepareMediaFormData({
        email: data.email || '',
        about: data.about || '',
        address: data.address || '',
        description: data.description || '',
        vertical: data.vertical || 'OTHER',
        my_file: selectedFile,
        websites: data.websites ? [data.websites] : [],
      })

      const result: any = await updateProfile(profile)
      if (result?.error?.status === 409) {
        setLoading(false)
        return setError(result?.error?.data?.message)
      }
      if (result?.error) {
        setLoading(false)
        return setError('Failed to update profile, please try again')
      }
      setError('')
      setLoading(false)
      toast({
        title: 'Updated Successfully',
        description: `whatsapp profile updated successfully `,
      })
    } catch (error) {
      return setError('Failed to update profile, please try again')
    }
  }

  return (
    <div className="h-full w-full">
      <div className="w-full space-y-3  px-1">
        <div className="flex w-full items-center justify-between">
          <div className="w-full flex items-center gap-3">
            <span>
              <label htmlFor="imageUpload">
                <Avatar className="flex">
                  <AvatarImage
                    src={
                      previewSource ||
                      profile.profile_picture_url ||
                      'https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg'
                    }
                    alt="@shadcn"
                    width={6}
                    height={6}
                    className="w-10 h-10 "
                  />
                </Avatar>

                <Input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
              </label>
            </span>
            <h1 className="text-lg font-semibold capitalize">Edit Profile</h1>
          </div>
        </div>
        <div className="w-full flex items-center gap-3">
          <Input2
            name="about"
            label="About"
            value={data.about}
            setValue={handleChange}
            type="text"
          />
          <Input2
            name="address"
            label="Address"
            value={data.address}
            setValue={handleChange}
            type="text"
          />
        </div>
        <div className="w-full flex items-center gap-3">
          <Input2
            name="email"
            label="Email"
            value={data.email}
            setValue={handleChange}
            type="email"
          />
          <Select2
            label="Domain"
            list={verticalOptions}
            value={data.vertical}
            setValue={(value: any) =>
              setData({
                ...data,
                vertical: value,
              })
            }
          />
        </div>
        <div className="w-full flex items-center gap-3">
          <Input2
            name="websites"
            label="Website"
            value={data.websites}
            setValue={handleChange}
            type="url"
          />
        </div>
        <div className="w-full flex items-center gap-3">
          <CustomTextArea
            label="Description"
            name="description"
            value={data.description}
            setValue={handleChange}
            type="text"
          />
        </div>
        {error !== '' && <p className="text-red-500 text-xs">{error}</p>}
        <div className="w-full p-4 flex justify-end">
          <Button size="sm" onClick={updateWhatsappProfile} disabled={loading}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}

const Input2 = ({
  label,
  name,
  required,
  value,
  setValue,
  type,
  disabled,
}: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && <span className="ml-1 text-rose-500">*</span>}
      </Label>
      <Input
        id={name}
        type={type}
        value={value}
        onChange={setValue}
        disabled={disabled}
      />
    </div>
  )
}

const Select2 = ({ label, name, required, list, value, setValue }: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && <span className="ml-1 text-rose-500">*</span>}
      </Label>
      <Select value={value} onValueChange={setValue}>
        <SelectTrigger>
          <SelectValue
            placeholder={
              list.find((item: any) => item === value) === undefined
                ? 'Select Vertical'
                : list.find((item: any) => item === value)
            }
          />
        </SelectTrigger>
        <SelectContent>
          {list.map((item: any) => (
            <SelectItem key={item} value={item}>
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
