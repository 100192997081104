import { useState } from 'react'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical } from 'react-icons/tb'
import EditUser from '../edit-user'
import ResetUser from './reset-user'
import DisableUser from './disable-user'
import RemoveUser from '../remove-user'
import { useRemoveUserMutation } from 'app/features/users'

export function RowAction({ user }: any) {
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [openReset, setOpenReset] = useState(false)
  const [openDisable, setOpenDisable] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [removeUser] = useRemoveUserMutation()

  const remove = async () => {
    await removeUser(selectedUser.id)
    window.location.reload()
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <TbDotsVertical size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {user.verified ? (
            <>
              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenReset(true)
                }}
              >
                Reset Password
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenDisable(true)
                }}
              >
                {user.disabled ? 'Enable' : 'Disable'}
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenEdit(true)
                }}
              >
                Edit
              </DropdownMenuItem>
            </>
          ) : (
            <DropdownMenuItem
              onClick={() => {
                setSelectedUser(user)
                setOpenRemove(true)
              }}
            >
              Remove
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedUser && openReset && (
        <ResetUser
          user={selectedUser}
          open={openReset}
          setOpen={setOpenReset}
        />
      )}
      {selectedUser && openDisable && (
        <DisableUser
          user={selectedUser}
          open={openDisable}
          setOpen={setOpenDisable}
        />
      )}
      {selectedUser && openEdit && (
        <EditUser user={selectedUser} open={openEdit} setOpen={setOpenEdit} />
      )}
      {selectedUser && openRemove && (
        <RemoveUser remove={remove} open={openRemove} setOpen={setOpenRemove} />
      )}
    </>
  )
}
