import { Cross1Icon } from '@radix-ui/react-icons'
import { useUpdateConversationMutation } from 'app/features/whatsapp'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { toast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'

export const WhatsappConversationManage = ({
  open,
  setOpen,
  conversationId,
  name,
}: any) => {
  const [updateError, setUpdateError] = useState('')
  const [conversationName, setConversationName] = useState(name)
  const [updateConversation, { isLoading: updateLoading }] =
    useUpdateConversationMutation()

  useEffect(() => {
    setConversationName(name)
  }, [name])

  const handleUpdateConversation = async (e: any) => {
    e.preventDefault()
    setUpdateError('')
    if (conversationName === '') return setUpdateError('Name is required')
    try {
      const result: any = await updateConversation({
        id: conversationId,
        name: conversationName,
      })
      if (result?.error) return setUpdateError('Failed to update conversation')
      toast({
        title: 'Updated successfully',
        description: 'Conversation updated successfully',
      })
      setUpdateError('')
      setOpen(false)
    } catch (err) {
      setUpdateError('Failed to update conversation')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {/* <AlertDialogTrigger className="disabled:text-gray-300 hover:cursor-pointer      p-0    disabled={!isDetailsPage}">
        <Button
          variant="ghost"
          className="flex h-8 w-8   p-0 data-[state=open]:bg-muted relative"
        >
          <IoPricetagSharp size={18} />
        </Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize"> Rename</h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="space-y-3 my-5">
                <div className="flex flex-col gap-2 p-1 py-4   ">
                  <Label>Name</Label>

                  <div className="flex items-center gap-3 ">
                    <Input
                      name="name"
                      value={conversationName}
                      placeholder={'Tag'}
                      onChange={(e: any) => setConversationName(e.target.value)}
                      type="text"
                      required
                      disabled={updateLoading}
                    />

                    <Button
                      onClick={handleUpdateConversation}
                      disabled={updateLoading}
                      variant={'outline'}
                      className="text-xs w-20 flex items-center gap-1 justify-center"
                    >
                      Update
                    </Button>
                  </div>
                  {updateError !== '' && (
                    <div className="text-rose-600 text-sm">{updateError}</div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
