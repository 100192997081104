import { Font, StyleSheet, Text, View } from '@react-pdf/renderer'
import reportFont from 'assets/fonts/report-font.ttf'

Font.register({
  family: 'Bebas Neue',
  src: reportFont,
})

const styles = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  title: {
    fontSize: 30,
    color: '#00B4EF',
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subtitle: {
    fontSize: 15,
    color: '#4b5563',
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxNumber: {
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
    fontSize: 15,
  },
  boxText: {
    fontSize: 12,
    fontFamily: 'Bebas Neue',
  },
  subTitle: {
    font0Size: 15,
    fontFamily: 'Bebas Neue',
  },
  row: {
    marginTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const Row = ({ name, data }: any) => {
  return (
    <View wrap={false} style={styles.row}>
      <Text style={{ ...styles.subTitle, marginTop: 20, flex: 1 }}>{name}</Text>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '#AF56DB' }}>
          {data?.NEW || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '#AF56DB' }}>New</Text>
      </View>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '#2761D7' }}>
          {data?.BOOKED || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '#2761D7' }}>Booked</Text>
      </View>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '#2DB58A' }}>
          {data?.SHOW || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '#2DB58A' }}>Show</Text>
      </View>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '#E68D30' }}>
          {data?.NO_SHOW || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '#E68D30' }}>No Show</Text>
      </View>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '#E2366E' }}>
          {data?.NOT_QUALIFIED || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '#E2366E' }}>
          Not Qualified
        </Text>
      </View>

      <View style={styles.box}>
        <Text style={{ ...styles.boxNumber, color: '##57534e' }}>
          {data?.FOLLOW_UP || 0}
        </Text>
        <Text style={{ ...styles.boxText, color: '##57534e' }}>Follow Up</Text>
      </View>
    </View>
  )
}

const dd = [
  {
    _count: 1,
    sourceId: '65e7055210c2516740f98e1f',
    status: 'NEW',
    source: {
      id: '65e7055210c2516740f98e1f',
      name: 'Facebook',
      createdAt: '2024-03-05T11:43:14.100Z',
      updatedAt: '2024-03-05T11:43:14.100Z',
    },
  },
  {
    _count: 4,
    sourceId: '65e7055810c2516740f98e20',
    status: 'NEW',
    source: {
      id: '65e7055810c2516740f98e20',
      name: 'Google Ads',
      createdAt: '2024-03-05T11:43:20.454Z',
      updatedAt: '2024-03-05T11:43:20.454Z',
    },
  },
  {
    _count: 1,
    sourceId: '65eeaa3469c24bbbaa8b6209',
    status: 'NEW',
    source: {
      id: '65eeaa3469c24bbbaa8b6209',
      name: 'Instagram',
      createdAt: '2024-03-11T06:52:36.788Z',
      updatedAt: '2024-03-11T06:52:36.788Z',
    },
  },
  {
    _count: 1,
    sourceId: '6603f2c0a677c77454413098',
    status: 'FOLLOW_UP',
    source: {
      id: '6603f2c0a677c77454413098',
      name: 'Website',
      createdAt: '2024-03-27T10:19:44.195Z',
      updatedAt: '2024-03-27T10:19:44.195Z',
    },
  },
  {
    _count: 1,
    sourceId: '6653100c998ab6a9ae3cdbfa',
    status: 'BOOKED',
    source: {
      id: '6653100c998ab6a9ae3cdbfa',
      name: 'whatsapp',
      createdAt: '2024-05-26T10:33:48.152Z',
      updatedAt: '2024-05-26T10:34:00.736Z',
    },
  },
  {
    _count: 1,
    sourceId: '6653100c998ab6a9ae3cdbfa',
    status: 'NEW',
    source: {
      id: '6653100c998ab6a9ae3cdbfa',
      name: 'whatsapp',
      createdAt: '2024-05-26T10:33:48.152Z',
      updatedAt: '2024-05-26T10:34:00.736Z',
    },
  },
  {
    _count: 1,
    sourceId: '671ceb54250cce78d4faf458',
    status: 'FOLLOW_UP',
    source: {
      id: '671ceb54250cce78d4faf458',
      name: 'meta',
      createdAt: '2024-10-26T13:14:59.159Z',
      updatedAt: '2024-10-26T13:14:59.159Z',
    },
  },
  {
    _count: 1,
    sourceId: '6749ace176f3ae08c5962607',
    status: 'NEW',
    source: {
      id: '6749ace176f3ae08c5962607',
      name: 'Others',
      createdAt: '2024-11-29T12:00:33.001Z',
      updatedAt: '2024-11-29T12:00:33.001Z',
    },
  },
]

export default function SourceDetails({ data }: any) {
  const sources = data.map((lead: any) => lead.source.name)
  const uniqueSources = Array.from(new Set(sources))

  // how many leads for each source
  const counts: Record<string, Record<string, number>> = {}
  const countsArray: any[] = []
  data.forEach((lead: any) => {
    const { source, status, _count } = lead
    const { name } = source
    counts[name] = counts[name] || {}
    counts[name][status] = (counts[name][status] || 0) + _count
    countsArray.push({
      label: name,
      data: counts[name] || {},
    })
  })

  // function to  get unique array of objects

  function getUniqueArray(arr: any, comp: any) {
    const unique = arr
      .map((e: any) => e[comp])
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e])

    return unique
  }

  const uniqueCountsArray = getUniqueArray(countsArray, 'label')
  return (
    <View
      style={{
        marginTop: 50,
      }}
    >
      <Text style={{ ...styles.title }}>SOURCE DETAILS</Text>

      <View
        style={{
          gap: 10,
        }}
      >
        {uniqueCountsArray.map((source: any) => {
          return (
            <Row key={source.label} name={source.label} data={source.data} />
          )
        })}
      </View>
    </View>
  )
}
