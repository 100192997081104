import { useGetMetaMessageQuery } from 'app/features/meta'
import { cn } from 'lib/utils'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import Bubble from '../components/bubble'

interface ChatListProps {
  index: number
  id: string
  selectedUserName: string
  timestamp: Date
}

export function MessengerMessage({
  index,
  id,
  selectedUserName,
  timestamp,
}: ChatListProps) {
  const { data: message } = useGetMetaMessageQuery(id)

  return (
    <div
      key={index}
      className={cn(
        'flex flex-col gap-2 p-4 px-0 whitespace-pre-wrap',
        message?.from?.name !== selectedUserName ? 'items-end' : 'items-start'
      )}
    >
      <div className="flex gap-1 items-center">
        {message?.from?.name === selectedUserName && (
          <Avatar className="flex justify-center items-center">
            <AvatarImage
              src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
              alt="@shadcn"
              width={6}
              height={6}
            />
          </Avatar>
        )}
        <Bubble
          content={message?.message}
          type={'TEXT'}
          senderName={message?.from?.name}
          timestamp={timestamp}
        />
        {message?.from?.name !== selectedUserName && (
          <Avatar className="flex justify-center items-center">
            <AvatarImage
              src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
              alt="@shadcn"
              width={6}
              height={6}
            />
          </Avatar>
        )}
      </div>
    </div>
  )
}
