import {
  useClaimLeadMutation,
  useLazyGetLeadQuery,
  useSendToInsuranceMutation,
} from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/textarea'
import useApp from 'hooks/useApp'
import { isDatePastThan } from 'lib/utils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export default function SendToInsurance({ open, setOpen, leadId }: any) {
  const { settings } = useApp()
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const { user } = useSelector((state: any) => state.user)
  const [comment, setComment] = useState('')
  const [isApproved, setIsApproved] = useState(false)
  const [error, setError] = useState('')
  const [sendToInsurance, { isLoading }] = useSendToInsuranceMutation()

  const [claimLead] = useClaimLeadMutation()

  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    const fetch = async () => {
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }
    }
    fetch()
  }, [])
  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setComment('')
    setError('')
  }, [open])

  async function sendToInsuranceHandler() {
    setError('')
    try {
      await sendToInsurance({
        leadId: lead.id,
        comment,
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        {(isLoading || isLoadingLead) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            {lead && (
              <>
                <AlertDialogHeader>
                  <div className="flex items-center justify-between text-black">
                    <h1 className="text-lg font-semibold">
                      {lead.firstName} {lead.lastName}
                    </h1>
                  </div>
                  <p className="text-gray-500 text-xs">{lead.phone}</p>
                  <p className="text-gray-500 text-xs pb-4">{lead.email}</p>
                  {error !== '' && (
                    <p className="text-red-500 text-xs">{error}</p>
                  )}
                  <Textarea
                    className="mt-5"
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder="Add comment"
                  />
                </AlertDialogHeader>

                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button
                    disabled={
                      lead?.insuranceType == 'Cash' ||
                      (lead?.isSentToInsurance && !lead?.isSendToInsuranceEnded)
                    }
                    onClick={sendToInsuranceHandler}
                  >
                    Send to insurance
                  </Button>
                </AlertDialogFooter>
              </>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
