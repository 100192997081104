import React from 'react'
import { cn } from 'lib/utils'
import useApp from 'hooks/useApp'

type HeaderButtonProps = {
  activeTab: string
  label: string
  unreadConversationsCount: number
  onClick: () => void
}

const headers = [
  'WhatsApp',
  'Messenger',
  'Instagram',
  'Facebook Comments',
  'Instagram Comments',
]

export const availableMetaSections = ['WhatsApp']

export default function Header({ activeTab, setActiveTab }: any) {
  const { unreadConversationsCount } = useApp()
  return (
    <div className="text-base w-full flex gap-3 items-center py-3 overflow-x-auto overflow-y-hidden">
      {headers.map((header: string) => (
        <HeaderButton
          key={header}
          activeTab={activeTab}
          label={header}
          unreadConversationsCount={unreadConversationsCount}
          onClick={() => setActiveTab(header)}
        />
      ))}
    </div>
  )
}

const HeaderButton = ({
  activeTab,
  label,
  unreadConversationsCount,
  onClick,
}: HeaderButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        activeTab === label ? 'bg-gray-200' : 'bg-transparent',
        'ml-3 md:ml-0 px-3 py-1 rounded-md flex items-center gap-3 cursor-pointer hover:bg-gray-100 duration-300'
      )}
    >
      <span className="whitespace-nowrap">{label}</span>
      {label === 'WhatsApp' && unreadConversationsCount > 0 && (
        <div className="text-[11px] w-4 h-4 rounded-full bg-rose-600 text-white grid place-content-center">
          {unreadConversationsCount < 99 ? unreadConversationsCount : '99+'}
        </div>
      )}
    </div>
  )
}
