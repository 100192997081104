import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import reportFont from 'assets/fonts/report-font.ttf'
import logo from 'assets/logo.jpeg'
import reportBackground from 'assets/report-bg.jpg'
import { toPng } from 'html-to-image'
import { forwardRef, useEffect, useState } from 'react'
import AgentPage from './agents-page'
import CustomPage from './custom-page'
import LeadsByBooking from './leads-by-booking'
import LeadsBySource from './leads-by-source'
import Loader from 'components/shared/loader'
import * as htmlToImage from 'html-to-image'
import SourceDetails from './source-details'
import useReport from 'hooks/useReport'

Font.register({
  family: 'Bebas Neue',
  src: reportFont,
})

const styles = StyleSheet.create({
  body: {
    backgroundImage: `url(${reportBackground})`,
    margin: 0,
    position: 'relative',
  },
  logo: {
    top: '20px',
    left: '20px',
    right: '0px',
    fontSize: 50,
    color: '#ff0000',
    position: 'absolute',
    width: 140,
    height: 25,
  },
  title: {
    bottom: '25px',
    left: '20px',
    right: '0px',
    fontSize: 70,
    position: 'absolute',
    marginHorizontal: 'auto',
    color: '#00B4EF',
    fontFamily: 'Bebas Neue',
    fontWeight: 'ultrabold',
  },
  subTitle: {
    bottom: '95px',
    left: '22px',
    right: '0px',
    font0Size: 20,
    position: 'absolute',
    marginHorizontal: 'auto',
    width: '60%,',
    color: '#064AAB',
    fontFamily: 'Bebas Neue',
  },
  date: {
    bottom: '20px',
    left: '24px',
    right: '0px',
    fontSize: 20,
    position: 'absolute',
    marginHorizontal: 'auto',
    width: '60%,',
    color: '#064AAB',
    fontFamily: 'Bebas Neue',
  },
  textLogo: {
    top: '20px',
    left: '20px',
    right: '0px',
    fontSize: 50,
    color: '#ff0000',
    position: 'absolute',
  },
  text: {
    fontSize: 50,
    color: '#997141',
    textAlign: 'center',
    position: 'absolute',
    marginHorizontal: 'auto',
    justifyContent: 'center',
  },
  mainText: {
    fontSize: 30,
    lineHeight: 1.5,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

export default function UserReports({ count }: any) {
  const { usersData, bookingData, sourceData } = useReport()

  return (
    <PDFViewer
      style={{ width: '100%', height: '100vh' }}
      className="h-100 col-4"
    >
      <Document>
        <Page size="A4" style={styles.body}>
          <View>
            <Image
              style={{
                height: '100%',
                width: '99%',
                marginHorizontal: 'auto',
              }}
              src={reportBackground}
            ></Image>
          </View>

          <Image style={{ ...styles.logo }} src={logo}></Image>

          <Text style={{ ...styles.subTitle }}>PERFORMANCE</Text>
          <Text style={{ ...styles.title }}>REPORT</Text>
          <Text style={{ ...styles.date }}>November 20204</Text>
        </Page>
        {Array.from(Array(count).keys()).map((i) => (
          <Page size="A4" style={[styles.body, { paddingHorizontal: 20 }]}>
            <LeadsBySource id={i} />
          </Page>
        ))}
        {bookingData && sourceData && (
          <CustomPage>
            <SourceDetails data={sourceData} />
            <LeadsByBooking data={bookingData} />
          </CustomPage>
        )}
        {usersData && (
          <CustomPage>
            <AgentPage data={usersData} />
          </CustomPage>
        )}
      </Document>
    </PDFViewer>
  )
}
