import { cn } from 'lib/utils'
import {
  TbLayoutGrid,
  TbListDetails,
  TbLogout,
  TbSettings,
  TbProgressCheck,
  TbStethoscope,
  TbUserScan,
  TbCircleDashedX,
  TbSocial,
} from 'react-icons/tb'

import { HiMiniEyeSlash } from 'react-icons/hi2'

import { CiSquareQuestion } from 'react-icons/ci'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { Switch } from 'components/ui/switch'
import { useEffect, useState } from 'react'

import useApp from 'hooks/useApp'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { accessibleTab, activeTab } from 'lib/allowed'
import { BiTask } from 'react-icons/bi'
import { useLogoutMutation } from 'app/features/auth'
import { Button } from 'components/ui/button'

const item = {
  title: 'Playground',
  url: '/social',
  isActive: true,
  items: [
    {
      title: 'History',
      url: '/social-1',
    },
    {
      title: 'Starred',
      url: '/social-2',
    },
    {
      title: 'Settings',
      url: '/social-3',
    },
  ],
}

export default function Sidebar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [socialOpen, setSocialOpen] = useState(false)

  const { pathname } = useLocation()
  const { user } = useSelector((state: any) => state.user)
  const {
    activeTabs: settingsActiveTabs,
    canBeFollowedUpLeadsCount,
    canBeClaimedInsuranceLeadsCount,
    loginRequestsCount,
    pendingTasksCount,
    unreadConversationsCount,
  } = useApp()
  const [logout] = useLogoutMutation()

  const [show, setShow] = useState(pathname == '/not-show' ? false : true)
  const showSwitchHandler = () => {
    setShow((prev) => !prev)
    if (!show) {
      navigate('/show')
    } else navigate('/not-show')
  }

  const [animateFollowUpCount, setAnimateFollowUpCount] = useState(false)
  useEffect(() => {
    setAnimateFollowUpCount(true)
    const timer = setTimeout(() => {
      setAnimateFollowUpCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [canBeFollowedUpLeadsCount])

  const [animateClaimInsuranceCount, setAnimateClaimInsuranceCount] =
    useState(false)
  useEffect(() => {
    setAnimateClaimInsuranceCount(true)
    const timer = setTimeout(() => {
      setAnimateClaimInsuranceCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [canBeClaimedInsuranceLeadsCount])

  const [animateLoginRequestsCount, setAnimateLoginRequestsCount] =
    useState(false)
  useEffect(() => {
    setAnimateLoginRequestsCount(true)
    const timer = setTimeout(() => {
      setAnimateLoginRequestsCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [loginRequestsCount])

  const [animateTasks, setAnimateTasks] = useState(false)
  useEffect(() => {
    setAnimateTasks(true)
    const timer = setTimeout(() => {
      setAnimateTasks(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [pendingTasksCount])

  const [animateUnreadConversationsCount, setAnimateUnreadConversationsCount] =
    useState(false)
  useEffect(() => {
    setAnimateUnreadConversationsCount(true)
    const timer = setTimeout(() => {
      setAnimateUnreadConversationsCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [unreadConversationsCount])

  return (
    <div className="hidden border-r md:block">
      <div className="flex h-full max-h-screen flex-col gap-2 overflow-y-scroll">
        <div className="flex gap-3 h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          <TbStethoscope size={20} className="text-indigo-600" />
          <h1 className="font-bold text-xl tracking-tight flex">Doctorna</h1>
        </div>
        <nav className="flex flex-col h-full p-3 gap-1">
          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') &&
            accessibleTab('Dashboard', user, settingsActiveTabs) && (
              <Link
                to="/dashboard"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/dashboard')
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-600'
                )}
              >
                <TbLayoutGrid
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="hover:text-gray-800">Dashboard</span>
              </Link>
            )}
          {user.role !== 'ADMIN' &&
            accessibleTab('Dashboard', user, settingsActiveTabs) && (
              <Link
                to="/agents-dashboard"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/agents-dashboard')
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-600'
                )}
              >
                <TbLayoutGrid
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="hover:text-gray-800">Dashboard</span>
              </Link>
            )}
          {accessibleTab('Leads', user, settingsActiveTabs) && (
            <Link
              to="/leads"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname?.startsWith('/leads')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbListDetails
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800 ">Leads </span>

              {canBeFollowedUpLeadsCount && canBeFollowedUpLeadsCount > 0 ? (
                <span className="absolute top-1/3 right-8 max-lg:right-6 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname.startsWith('/leads')
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />
                    {/* <GiWhistle size={20} className="transform scale-x-[-1]" /> */}

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateFollowUpCount ? 'animate-pulse-once' : ''}`}
                    >
                      {canBeFollowedUpLeadsCount <= 99
                        ? canBeFollowedUpLeadsCount
                        : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}
          {accessibleTab('Booked', user, settingsActiveTabs) && (
            <Link
              to="/booked"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/booked')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbProgressCheck
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Booked</span>
            </Link>
          )}
          {accessibleTab('Show', user, settingsActiveTabs) && (
            <span
              onClick={() => {
                if (!pathname.includes('show')) {
                  if (show) navigate('/show')
                  else navigate('/not-show')
                }
              }}
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group hover:cursor-pointer',
                pathname?.includes('show')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              {show ? (
                <FaEye size={18} className="group-hover:text-indigo-600" />
              ) : (
                <HiMiniEyeSlash
                  size={18}
                  className="group-hover:text-indigo-600"
                />
              )}
              <span className="hover:text-gray-800">
                {show ? 'Show' : 'Not show'}
              </span>
              {pathname.includes('show') && (
                <span className={` flex-1 flex justify-end`}>
                  <Switch
                    className="data-[state=checked]:bg-indigo-800"
                    checked={show}
                    onCheckedChange={showSwitchHandler}
                  />
                </span>
              )}
            </span>
          )}
          {accessibleTab('Not_Qualified', user, settingsActiveTabs) && (
            <Link
              to="/not-qualified"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/not-qualified')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbCircleDashedX
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">NotQualified</span>
            </Link>
          )}

          {accessibleTab('Insurance', user, settingsActiveTabs) && (
            <Link
              to="/insurance"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname == '/insurance'
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <IoShieldCheckmarkSharp
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Insurance</span>
              {canBeClaimedInsuranceLeadsCount &&
              canBeClaimedInsuranceLeadsCount > 0 ? (
                <span className="absolute top-1/3 right-8 max-lg:right-6 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname == '/insurance'
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />
                    {/* <GiWhistle size={20} className="transform scale-x-[-1]" /> */}

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateClaimInsuranceCount ? 'animate-pulse-once' : ''}`}
                    >
                      {canBeClaimedInsuranceLeadsCount <= 99
                        ? canBeClaimedInsuranceLeadsCount
                        : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}
          {accessibleTab('Insurance_Portal', user, settingsActiveTabs) && (
            <Link
              to="/insurance-portal"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/insurance-portal')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <IoShieldCheckmarkSharp
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Insurance Portal</span>
            </Link>
          )}

          {accessibleTab('Users', user, settingsActiveTabs) && (
            <Link
              to="/users"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/users')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbUserScan size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Users</span>
            </Link>
          )}
          {accessibleTab('Login_Requests', user, settingsActiveTabs) && (
            <Link
              to="/agents-login-requests"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname?.startsWith('/agents-login-requests')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <CiSquareQuestion
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Login Requests</span>

              {loginRequestsCount && loginRequestsCount > 0 ? (
                <span className="absolute top-1/3 right-8 max-lg:right-6 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname.startsWith('/agents-login-requests')
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />
                    {/* <GiWhistle size={20} className="transform scale-x-[-1]" /> */}

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateLoginRequestsCount ? 'animate-pulse-once' : ''}`}
                    >
                      {loginRequestsCount <= 99 ? loginRequestsCount : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}
          {activeTab('Tasks', settingsActiveTabs) && (
            <Link
              to="/tasks"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname?.startsWith('/tasks')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <BiTask size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Tasks</span>

              {pendingTasksCount && pendingTasksCount > 0 ? (
                <span className="absolute top-1/3 right-8 max-lg:right-6 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname.startsWith('/tasks')
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />
                    {/* <GiWhistle size={20} className="transform scale-x-[-1]" /> */}

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateTasks ? 'animate-pulse-once' : ''}`}
                    >
                      {pendingTasksCount <= 99 ? pendingTasksCount : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}
          {accessibleTab('Social', user, settingsActiveTabs) && (
            <Link
              to="/social"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname?.startsWith('/social')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbSocial size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Social</span>
              {unreadConversationsCount && unreadConversationsCount > 0 ? (
                <span className="absolute top-1/3 right-8 max-lg:right-6 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname.startsWith('/social')
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateUnreadConversationsCount ? 'animate-pulse-once' : ''}`}
                    >
                      {unreadConversationsCount <= 99
                        ? unreadConversationsCount
                        : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}

          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
            <Link
              to="/settings"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/settings')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbSettings size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Settings</span>
            </Link>
          )}
          <div className="flex-1" />
          <div
            className="cursor-pointer p-3 rounded-md flex flex-col gap-1 duration-300 hover:bg-gray-100"
            // TODO: review
            //   onClick={() => dispatch(removeCredentials())}
            onClick={async () => await logout(undefined)}
          >
            <div className="flex items-center gap-2">
              <TbLogout size={15} />
              <span className="flex text-sm">Logout</span>
            </div>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <div className="flex text-sm capitalize">
              {user?.firstName} {user?.lastName}
            </div>
            <div className="flex text-xs text-gray-500">{user?.email}</div>
          </div>
        </nav>
      </div>
    </div>
  )
}
