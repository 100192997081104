import { createRef, forwardRef, useEffect, useRef, useState } from 'react'
import UserPopUp from './user-pop-up'
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  PieChart,
  Pie,
  Label,
} from 'recharts'

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../../../components/ui/chart'
import { Button } from 'components/ui/button'
import useReport from 'hooks/useReport'

export default function UsersSection({ users }: any) {
  const { setUsersData } = useReport()
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserIndex, setSelectedUserIndex] = useState(0)
  // sort users by score
  const arrayToSort = [...users]
  const sortedUsers = arrayToSort.sort(
    (a: any, b: any) => b.userScore - a.userScore
  )
  //   setUsersData(sortedUsers)

  useEffect(() => {
    setUsersData(sortedUsers)
  }, [users])

  function divideArray(bookings: any) {
    return bookings.reduce(
      (result: any, booking: any) => {
        if (booking.isShow && booking.isShowUpdated) {
          result[0].push(booking)
        } else if (!booking.isShow && booking.isShowUpdated) {
          result[1].push(booking)
        } else {
          result[2].push(booking)
        }
        return result
      },
      [[], [], []]
    )
  }
  const [elRefs, setElRefs] = useState([])

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(users.length)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    )
  }, [users])

  return (
    <div className="p-3 rounded-md border bg-white">
      <h1 className="font-semibold">User Performance</h1>
      <div className="mt-3 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
        {sortedUsers.map((user: any, i) => {
          const [showBookings, noShowBooking, booking] = divideArray(
            user.bookings
          )
          return (
            <div
              key={user.id}
              className="flex flex-col border border-gray-300 p-3 rounded-md hover:bg-gray-50 duration-300"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-between w-full">
                  <h1 className="font-semibold">
                    {user.firstName} {user?.lastName}
                  </h1>
                  <span className="text-[11px] text-indigo-600 p-1 rounded-md bg-indigo-100">
                    score {user.userScore}
                  </span>
                </div>
              </div>
              <p className="text-xs text-gray-500">{user.email}</p>
              {/* chart */}
              <div className={`mt-3 `} ref={elRefs[selectedUserIndex]}>
                <UserChart
                  data={{
                    booked: user.bookingCount,
                    followed: user.followUpCount,
                    created: user.leadCount,
                    notQualified: user.notQualifiedLeadsCount,
                    show: showBookings.length,
                    noShow: noShowBooking.length,
                    // show: user.leads.filter((lead: any) => lead.status === "SHOW")
                    // 	.length,
                    // noShow: user.leads.filter(
                    // 	(lead: any) => lead.status === "NO_SHOW"
                    // ).length,
                    // filter based on status of show or no show
                    // show: user.showCount,
                    // noShow: user.noShow
                  }}
                />
              </div>
              {/* <div
                style={{
                  width: '50vw',

                  position: 'absolute',
                  top: '-5000px',
                  left: '0px',
                }}
              >
                <div ref={elRefs[i]}>
                  <UserChart
                    data={{
                      booked: user.bookingCount,
                      followed: user.followUpCount,
                      created: user.leadCount,
                      notQualified: user.notQualifiedLeadsCount,
                      show: showBookings.length,
                      noShow: noShowBooking.length,
                    }}
                  />
           
                </div>
           
              </div> */}

              <div className="mt-3">
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => {
                    setSelectedUser(user)
                    setSelectedUserIndex(i)
                    setOpen(true)
                  }}
                >
                  All Details
                </Button>
              </div>
            </div>
          )
        })}
        {selectedUser && elRefs.length && open && (
          <div className="col-span-2">
            <UserPopUp
              open={open}
              setOpen={setOpen}
              user={selectedUser}
              ref={elRefs[selectedUserIndex]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const UserChart = ({ data }: any) => {
  const chartData = [
    { status: 'booked', count: data.booked, fill: '#2761D8' },
    { status: 'followed', count: data.followed, fill: '#2DB78A' },
    { status: 'created', count: data.created, fill: '#E78D2F' },
    { status: 'notQualified', count: data.notQualified, fill: '#E2366F' },
    { status: 'show', count: data.show, fill: '#AF56DB' },
    { status: 'noShow', count: data.noShow, fill: '#E9C368' },
  ]

  const chartConfig = {
    count: {
      label: 'User Statistics',
    },
    show: {
      label: 'Show',
      color: '#AF56DB',
    },
    noShow: {
      label: 'No Show',
      color: '#E9C368',
    },
    booked: {
      label: 'Booked',
      color: '#2761D8',
    },
    followed: {
      label: 'Followed',
      color: '#2DB78A',
    },
    created: {
      label: 'Created',
      color: '#E78D2F',
    },
    notQualified: {
      label: 'Not Qualified',
      color: '#E2366F',
    },
  } satisfies ChartConfig
  return (
    <ChartContainer config={chartConfig} className="w-full h-full">
      <BarChart accessibilityLayer data={chartData} margin={{ top: 25 }}>
        <XAxis
          dataKey="status"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          style={{ fontSize: '0.6rem' }}
          interval={0}
          tickFormatter={(value) =>
            chartConfig[value as keyof typeof chartConfig]?.label
          }
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Bar dataKey="count" radius={3} barSize={30}>
          <LabelList
            position="top"
            offset={12}
            className="fill-foreground"
            fontSize={12}
          />
        </Bar>
      </BarChart>
    </ChartContainer>
  )
}

export const UserSourceStatisticsChart = ({ data }: any) => {
  const chartData = [
    { status: 'booked', count: data.booked, fill: '#2761D8' },
    { status: 'followed', count: data.followed, fill: '#2DB78A' },
    { status: 'created', count: data.created, fill: '#E78D2F' },
    { status: 'notQualified', count: data.notQualified, fill: '#E2366F' },
    { status: 'show', count: data.show, fill: '#AF56DB' },
    { status: 'noShow', count: data.noShow, fill: '#E9C368' },
  ]

  const chartConfig = {
    count: {
      label: 'Source Statistics',
    },
    show: {
      label: 'Show',
      color: '#AF56DB',
    },
    noShow: {
      label: 'No Show',
      color: '#E9C368',
    },
    booked: {
      label: 'Booked',
      color: '#2761D8',
    },
    followed: {
      label: 'Followed',
      color: '#2DB78A',
    },
    created: {
      label: 'Created',
      color: '#E78D2F',
    },
    notQualified: {
      label: 'Not Qualified',
      color: '#E2366F',
    },
  } satisfies ChartConfig
  return (
    <div className={` p-3  rounded-md  `}>
      <div className="flex  justify-between px-4">
        <h1>{data.name}</h1>
        <h1 className="text-gray-500">
          score:{' '}
          {data.booked + data.followed + data.created + data.show + data.noShow}
        </h1>
      </div>

      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square  max-h-[250px]"
      >
        <PieChart>
          <ChartTooltip content={<ChartTooltipContent hideLabel />} />
          <Pie
            data={chartData}
            dataKey="count"
            label
            nameKey="status"
            innerRadius={60}
            strokeWidth={5}
          ></Pie>
        </PieChart>
      </ChartContainer>
      <div className="flex flex-wrap gap-4 justify-center">
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#AF56DB]" />
          <span className="text-xs text-gray-500">Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E9C368]" />
          <span className="text-xs text-gray-500">No Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2761D8]" />
          <span className="text-xs text-gray-500">Booked</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2DB78A]" />
          <span className="text-xs text-gray-500">Followed</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E78D2F]" />
          <span className="text-xs text-gray-500">Created</span>
        </div>

        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E2366F]" />
          <span className="text-xs text-gray-500">Not Qualified</span>
        </div>
      </div>
    </div>
  )
}
