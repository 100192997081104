import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from 'components/ui/menubar'
import CreateTemplate from './actions/create-template'
import CreateCampaign from './actions/create-campaign/create-campaign'
import InitConversation from './init-conversation'
import { useEffect, useState } from 'react'
import {
  MdContactEmergency,
  MdContacts,
  MdContactSupport,
  MdSpaceDashboard,
} from 'react-icons/md'
import ViewTemplates from './actions/view-templates'
import { TbDotsVertical } from 'react-icons/tb'
import { Button } from 'components/ui/button'
import { useSelector } from 'react-redux'
import ListCampaigns from './actions/list-campaigns'
import { useNavigate } from 'react-router-dom'

export const Actions = () => {
  const navigate = useNavigate()

  const { user } = useSelector((state: any) => state.user)
  const [openInitConversation, setOpenInitConversation] = useState(false)
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false)
  const [openCreateCampaign, setOpenCreateCampaign] = useState(false)
  const [openViewTemplates, setOpenViewTemplates] = useState(false)
  const [openListCampaigns, setOpenListCampaigns] = useState(false)

  const openWhatsappDashboard = () => {
    navigate('/whatsapp-stats')
  }

  return (
    <>
      <Menubar className="  border-none h-10 w-8 z-10  ">
        <MenubarMenu>
          <MenubarTrigger className="disabled:text-gray-300 hover:cursor-pointer p-0 ">
            <Button
              variant="ghost"
              className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
            >
              <TbDotsVertical size={18} />
            </Button>
          </MenubarTrigger>

          <MenubarContent className="bg-white border">
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenInitConversation(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {/* <InitConversation />
               */}
              <span>New Contact</span>

              <MenubarShortcut>
                <MdContacts size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenCreateTemplate(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {/* <CreateTemplate />
               */}
              <span>New Template</span>

              <MenubarShortcut>
                <MdContactSupport size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenCreateCampaign(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {' '}
              <span>New Campaign</span>
              <MenubarShortcut>
                <MdContactEmergency size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>

            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenViewTemplates(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              <span>All Templates</span>

              <MenubarShortcut>
                <MdContactSupport size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            <MenubarItem
              onSelect={(e) => {
                e.preventDefault()
                setOpenListCampaigns(true)
              }}
              className="hover:cursor-pointer hover:bg-gray-100 font-medium"
            >
              {' '}
              <span>All Campaigns</span>
              <MenubarShortcut>
                <MdContactEmergency size={15} className="text-gray-700" />
              </MenubarShortcut>
            </MenubarItem>
            {user.role != 'AGENT' && (
              <MenubarItem
                onSelect={(e) => {
                  e.preventDefault()
                  openWhatsappDashboard()
                }}
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
              >
                <span>Dashboard & statistics</span>

                <MenubarShortcut>
                  <MdSpaceDashboard size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
            )}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      {/* Have to follow this approach and not put Init Conversation directly in the MenubarItem depending on its trigger to solve the space issue */}
      <InitConversation
        open={openInitConversation}
        setOpen={setOpenInitConversation}
      />
      <CreateTemplate
        open={openCreateTemplate}
        setOpen={setOpenCreateTemplate}
      />
      <CreateCampaign
        open={openCreateCampaign}
        setOpen={setOpenCreateCampaign}
      />
      <ViewTemplates open={openViewTemplates} setOpen={setOpenViewTemplates} />
      <ListCampaigns open={openListCampaigns} setOpen={setOpenListCampaigns} />
    </>
  )
}
