import {
  Bar,
  BarChart,
  XAxis,
  CartesianGrid,
  LabelList,
  Label,
  Pie,
  PieChart,
} from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'components/ui/chart'

export default function Chart({ data }: any) {
  const counts: Record<string, Record<string, number>> = {}
  const countsArray: any[] = []
  data.forEach((lead: any) => {
    const { source, status, _count } = lead
    const { name } = source
    counts[name] = counts[name] || {}
    counts[name][status] = (counts[name][status] || 0) + _count
    countsArray.push({
      label: name,
      data: counts[name] || {},
    })
  })

  // function to  get unique array of objects

  function getUniqueArray(arr: any, comp: any) {
    const unique = arr
      .map((e: any) => e[comp])
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e])

    return unique
  }

  const uniqueCountsArray = getUniqueArray(countsArray, 'label')
  const section = Math.ceil(uniqueCountsArray.length / 2)

  return (
    <div className="grid grid-cols-2 gap-3">
      {uniqueCountsArray.map((obj: any, index: number) => (
        <div key={index} className="px-3 py-1 h-full w-full">
          <h2 className="text-sm font-semibold text-center">{obj.label}</h2>
          <ChartEntity obj={obj.data} />
        </div>
      ))}
    </div>
  )
}

const ChartEntity = ({ obj }: any) => {
  const chartData = [
    { status: 'notQualified', leads: obj?.NOT_QUALIFIED || 0, fill: '#E2366F' },
    { status: 'new', leads: obj?.NEW || 0, fill: '#AF56DB' },
    { status: 'show', leads: obj?.SHOW || 0, fill: '#2DB78A' },
    { status: 'noShow', leads: obj?.NO_SHOW || 0, fill: '#E78D2F' },
    { status: 'followUp', leads: obj?.FOLLOW_UP || 0, fill: '#E9C368' },
    { status: 'booked', leads: obj?.BOOKED || 0, fill: '#2761D8' },
  ]

  const chartConfig: any = {
    leads: {
      label: 'Leads',
    },
    notQualified: {
      label: 'Not Qualified',
      color: '#E2366F',
    },
    new: {
      label: 'New',
      color: '#AF56DB',
    },
    show: {
      label: 'Show',
      color: '#2DB78A',
    },
    noShow: {
      label: 'No Show',
      color: '#E78D2F',
    },
    followUp: {
      label: 'Follow Up',
      color: '#E9C368',
    },
    booked: {
      label: 'Booked',
      color: '#2761D8',
    },
  } satisfies ChartConfig

  return (
    <div className="w-full h-full">
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[250px] w-full"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="leads"
            nameKey="status"
            innerRadius={60}
            strokeWidth={5}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      ></tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      ></tspan>
                    </text>
                  )
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
      <div className="flex flex-wrap gap-2 justify-center">
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2761D8]" />
          <span className="text-xs text-gray-500">Booked</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2DB78A]" />
          <span className="text-xs text-gray-500">Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E78D2F]" />
          <span className="text-xs text-gray-500">No Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E2366F]" />
          <span className="text-xs text-gray-500">Not Qualified</span>
        </div>
      </div>
    </div>
  )
}
