import { useGetMetaContactsQuery } from 'app/features/meta'
import List from './list'

export default function Messenger({ selectedUser, setSelectedUser }: any) {
  const { data, isLoading, isFetching } = useGetMetaContactsQuery()

  return (
    <List
      contacts={data}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      loading={isLoading || isFetching}
    />
  )
}
