import { useGetUsersQuery } from 'app/features/users'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import Pagination from 'components/table/pagination'
import { Input } from 'components/ui/input'
import { cn } from 'lib/utils'
import { navigateAgents } from 'lib/allowed'

import CreateUser from './create-user'
import { RowAction } from './component/row-action'
import useApp from 'hooks/useApp'

export default function Users() {
  const { settingsActiveTabs } = useApp()
  const navigate = useNavigate()
  const { user } = useSelector((state: any) => state.user)
  const [keyword, setKeyword] = useState('')
  const [debouncedKeyword] = useDebounce(keyword, 700)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    query: keyword,
  })
  const { data, isLoading, isError, isFetching } = useGetUsersQuery(
    { ...filters },
    { refetchOnFocus: true }
  )

  useEffect(() => {
    if (user && user.role === 'AGENT') {
      navigate(navigateAgents(user, settingsActiveTabs))
    }
  }, [user])

  useEffect(() => {
    setFilters({
      ...filters,
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
      query: keyword,
    })
  }, [currentPage, debouncedKeyword, rowsPerPage])

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="p-3 w-full h-full space-y-3  overflow-scroll">
        <div className="flex items-center justify-between mt-12">
          <div>
            <Input
              type="text"
              placeholder="Search"
              value={keyword}
              className="bg-white"
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>

          <CreateUser />
        </div>
        <div className="border rounded-md bg-white w-full overflow-hidden overflow-x-scroll">
          <table
            className={cn(
              isFetching ? 'text-gray-400' : 'text-gray-800',
              'text-sm w-full border-collapse table-fixed'
            )}
          >
            <thead>
              <tr className="text-left border-b">
                <th className="xl:w-64">Name</th>
                <th className="xl:w-64">Email</th>
                <th className="xl:w-32">Status</th>
                <th className="xl:w-32">Role</th>
                <th className="xl:w-32">Group</th>
                <th className="xl:w-32">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.users.map((user: any) => (
                <tr key={user.id} className="border-b last:border-b-0">
                  <td className=" overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.firstName} {user.lastName}
                  </td>
                  <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.email}
                  </td>
                  <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.disabled ? (
                      <span className="rounded bg-gray-200 py-1 px-2 text-xs text-gray-800">
                        Disabled
                      </span>
                    ) : !user.verified ? (
                      <span className="rounded bg-yellow-200 py-1 px-2 text-xs text-yellow-800">
                        Pending
                      </span>
                    ) : (
                      <span className="rounded bg-green-200 py-1 px-2 text-xs text-green-800">
                        Active
                      </span>
                    )}
                  </td>

                  <td className="capitalize overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.role.toLowerCase()}
                  </td>
                  <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.group?.name}
                  </td>
                  <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {user.role === 'AGENT' && <RowAction user={user} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {data?.count && (
            <Pagination
              count={data?.count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      </div>
    </>
  )
}
