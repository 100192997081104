import { io, Socket } from 'socket.io-client'

export class SocketClient {
  private static instance: SocketClient
  private socket: Socket | undefined
  private socketServerToken: string = ''

  private constructor(socketServerToken: string) {
    this.socketServerToken = socketServerToken
    const socketServerUrl = process.env.REACT_APP_SOCKET_SERVER_URL
    if (!socketServerUrl) {
      console.warn(
        'No socket server url found. Cannot connect to socket server.'
      )
      return
    }

    this.socket = io(socketServerUrl, {
      reconnection: true,
      query: {
        token: socketServerToken,
      },
    })
    this.socket?.on('connect', () => {
      const room = process.env.REACT_APP_SOCKET_SERVER_ROOM!
      this.joinRoom(room)
    })
  }

  public static getInstance(socketServerToken: string): SocketClient {
    if (
      !SocketClient.instance ||
      SocketClient.instance.socketServerToken != socketServerToken
    ) {
      SocketClient.instance = new SocketClient(socketServerToken)
    }
    return SocketClient.instance
  }

  public emitMessage(message: string): void {
    this.socket?.emit('message', message)
  }

  public onMessage(message: string, callback: () => void): void {
    if (this.socket) {
      this.socket?.on(message, callback)
    }
  }

  public joinRoom(room: string): void {
    this.socket?.emit(IOEvents.JOIN_ROOM, room)
  }
  public emit(event: string, data: any): void {
    this.socket?.emit(event, data)
  }
  public on(event: IOEvents, callback: (data: any) => void): void {
    this.socket?.on(event, callback)
  }
  public off(event: IOEvents, callback: (data: any) => void): void {
    this.socket?.off(event, callback)
  }
}

export enum IOEvents {
  JOIN_ROOM = 'JOIN_ROOM',
  EMIT_EVENT = 'EMIT_EVENT',
  AUTH_DEVICES = 'AUTH_DEVICES',
  USER_UPDATED = 'USER_UPDATED',
  LEAD_UPDATED = 'LEAD_UPDATED',
  LEAD_CREATED = 'LEAD_CREATED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_UPDATED = 'TASK_UPDATED',
  SETTINGS_UPDATED = 'SETTINGS_UPDATED',
  NEW_META_MESSAGE = 'NEW_META_MESSAGE',
  NEW_WHATSAPP_MESSAGE = 'NEW_WHATSAPP_MESSAGE',
}
