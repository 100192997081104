import {
  useAddBranchMutation,
  useGetBranchesQuery,
  useUpdateBranchMutation,
} from 'app/features/branch'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit, TbMapPinCheck } from 'react-icons/tb'

const MAX_BRANCHES_NUMBER = process.env.REACT_APP_MAX_BRANCHES_NUMBER

export default function Branches() {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [selectedBranch, setSelectedBranch] = useState('')
  const [mode, setMode] = useState('add')

  const {
    data: branches,
    isError,
    refetch: refetchBranches,
    isLoading: dataLoading,
  } = useGetBranchesQuery()
  const [addBranch, { isLoading: createLoading, isError: createError }] =
    useAddBranchMutation()
  const [updateBranch, { isLoading: updateLoading, isError: updateError }] =
    useUpdateBranchMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (createError || updateError) setError('Failed to add branch')
  }, [createError, updateError])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setLocation('')
      setSelectedBranch('')
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleBranch = async (e: any) => {
    e.preventDefault()
    setError('')
    if (name === '' || location === '')
      return setError('Name and location are required')

    if (mode === 'add') {
      const recentBranches = await refetchBranches()
      if (recentBranches.error) return setError('server down, try again please')
      if (
        Number(MAX_BRANCHES_NUMBER) &&
        recentBranches?.data?.length! >= Number(MAX_BRANCHES_NUMBER)
      )
        return setError('You reached maximum number of branches')
    }

    if (mode === 'add') await addBranch({ name, location })
    else await updateBranch({ id: selectedBranch, data: { name, location } })
    setName('')
    setLocation('')
    setMode('add')
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Branches</h1>
      <p className="text-xs text-gray-500">
        Create branches that will be used to categorize the leads
      </p>
      {/* Form */}
      <form
        onSubmit={handleBranch}
        className="mt-5 flex items-center gap-3 max-md:flex-col "
      >
        <Input
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          type="text"
          placeholder="Branch Name"
          disabled={isLoading}
        />
        <Input
          value={location}
          onChange={(e: any) => setLocation(e.target.value)}
          type="text"
          placeholder="Branch Location"
          disabled={isLoading}
        />
        <Button
          type="submit"
          disabled={
            isLoading ||
            createLoading ||
            (mode == 'add' && branches?.length! >= Number(MAX_BRANCHES_NUMBER))
          }
          size="sm"
        >
          {mode === 'add' ? 'Add Branch' : 'Update Branch'}
        </Button>
        {mode === 'update' && (
          <Button size="sm" variant="secondary" onClick={() => setMode('add')}>
            Cancel update
          </Button>
        )}
      </form>
      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3 min-h-[100px] max-h-[300px] overflow-y-scroll border rounded-md p-2">
        {branches?.length == 0 && (
          <div className="flex items-center justify-center p-10 w-full">
            No branches added
          </div>
        )}
        {branches?.map((branch: any) => (
          <div
            key={branch.id}
            className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
          >
            <div className="flex items-center gap-3">
              <div>{branch.name}</div>
              <div className="flex gap-1 items-center">
                <TbMapPinCheck className="text-gray-500" />
                <div>{branch.location}</div>
              </div>
            </div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setName(branch.name)
                  setLocation(branch.location)
                  setSelectedBranch(branch.id)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
