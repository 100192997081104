import { useUpdateStatusMutation } from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'
import { Textarea } from 'components/ui/textarea'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'

export default function Status({ lead, open, setOpen }: any) {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [isPaid, setIsPaid] = useState(true)
  const [amount, setAmount] = useState(0)
  const [isShowComment, setIsShowComment] = useState('')
  const [error, setError] = useState('')
  const [updateStatus, { isLoading }] = useUpdateStatusMutation()

  async function handleUpdateStatus() {
    if (!status) return setError('Status is required')
    if (status == 'SHOW' && isPaid && !amount) {
      return setError('Amount is required')
    }

    setLoading(true)
    setError('')
    await updateStatus({
      id: lead.id,
      data: { status, isPaid, amount, isShowComment },
    })
    setLoading(false)
    setOpen(false)
  }
  useEffect(() => {
    setError('')
    setIsPaid(true)
    setAmount(0)
  }, [status])
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="outline">Send WhatsApp</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <>
          {!loading || !isLoading ? (
            <>
              <AlertDialogHeader>
                <AlertDialogTitle>Change lead status</AlertDialogTitle>
                <div className="flex gap-3 pb-4">
                  <div
                    onClick={() => setStatus('SHOW')}
                    className={cn(
                      status === 'SHOW' ? 'border-indigo-600' : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2'
                    )}
                  >
                    Show
                  </div>
                  <div
                    onClick={() => setStatus('NO_SHOW')}
                    className={cn(
                      status === 'NO_SHOW' ? 'border-indigo-600' : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2'
                    )}
                  >
                    No Show
                  </div>
                </div>
                {status == 'SHOW' && (
                  <div className="flex p-4 pt-2 gap-4  flex-col items-start">
                    <div className="flex gap-2 items-center">
                      <Label htmlFor="paid">Paid</Label>

                      <Switch
                        id="paid"
                        checked={isPaid}
                        onCheckedChange={setIsPaid}
                        aria-readonly
                      />
                    </div>
                    <div className="flex  gap-4 w-full items-center">
                      <Label htmlFor="amount">
                        Amount
                        <span className="ml-1 text-rose-500">*</span>
                      </Label>
                      <div className=" items-center flex relative">
                        <Input
                          placeholder="Enter amount"
                          name="amount"
                          type="number"
                          min={1}
                          value={amount}
                          onChange={(e: any) => {
                            setAmount(parseInt(e.target.value))
                          }}
                          className="w-full"
                          required
                          disabled={!isPaid}
                        />
                        <span className="text-gray-400 text-xs hover:cursor-pointer hover:text-gray-900 absolute right-8">
                          AED{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <Textarea
                  className="mt-1O"
                  value={isShowComment}
                  onChange={(e: any) => setIsShowComment(e.target.value)}
                  placeholder="Add comment"
                />
              </AlertDialogHeader>

              <AlertDialogFooter className="flex flex-row justify-between items-center w-full  ">
                <div className=" mr-auto">
                  {error !== '' && (
                    <p className="text-rose-500 text-sm">{error}</p>
                  )}
                </div>
                <div className="flex justify-center items-center gap-2">
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button onClick={handleUpdateStatus}>Update</Button>
                </div>
              </AlertDialogFooter>
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </>
      </AlertDialogContent>
    </AlertDialog>
  )
}
