import React from 'react'
import {
  useGetMetaConversationQuery,
  useSendMetaMessageMutation,
} from 'app/features/meta'
import { MessengerChatList } from '../chat-list/messenger'

interface ChatProps {
  selectedConversationId: string
  initialLead: any
  isDetailsPage: any
  metaConversationId: any
}

export default function Messenger({
  selectedConversationId,
  initialLead,
  isDetailsPage,
  metaConversationId,
}: ChatProps) {
  const [messagesState, setMessages] = React.useState<any[]>([])
  const [sendMessage] = useSendMetaMessageMutation()
  const {
    data: conversation,
    isLoading,
    isFetching,
  } = useGetMetaConversationQuery(selectedConversationId)

  const sendMessageClick = async (newMessage: any) => {
    const res: { data: any } | { error: any } = await sendMessage({
      userId: conversation?.userId,
      message: newMessage,
      conversationId: selectedConversationId,
    })
    if ('data' in res) {
      setMessages([...messagesState, res.data.message_id])
    }
  }

  const sendMediaClick = (newMessage: any) => {
    setMessages([...messagesState, newMessage])
  }

  return (
    <MessengerChatList
      messages={conversation?.messages}
      selectedUserId={conversation?.leadId!}
      selectedUserName={conversation?.name}
      sendMessage={sendMessageClick}
      sendMedia={sendMediaClick}
      isFetching={isFetching}
      isLoading={isLoading}
      initialLead={initialLead}
      isDetailsPage={isDetailsPage}
      metaConversationId={metaConversationId}
    />
  )
}
