import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useMediaFileMutation,
  useSendCampaignMutation,
} from 'app/features/whatsapp'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useEffect, useState } from 'react'
import { useGetLeadsWhatsappQuery } from 'app/features/lead'
import { cn, prepareMediaFormData } from 'lib/utils'

import FillCampaignTemplate from '../fill-campaign-template'
import { SelectTemplate } from '../select-template'
import { toast } from 'components/ui/use-toast'
import FilterLeads from './filter-leads'

const initialData = {
  template: {},
  leadNumbers: [],
}

export default function CreateCampaign({ open, setOpen }: any) {
  const [data, setData] = useState<any>(initialData)
  const [step, setStep] = useState(1)

  const [creatingError, setCreatingError] = useState('')
  const [isCreatingLoading, setIsCreatingLoading] = useState(false)

  const [sendCampaignFunction] = useSendCampaignMutation()
  const [mediaFileFunction] = useMediaFileMutation()

  useEffect(() => {
    setStep(1)
    setData(initialData)
    setCreatingError('')
    setIsCreatingLoading(false)
  }, [open])

  function addTemplate(template: any) {
    setData({ ...data, template: template })
  }

  function selectPhones(phones: string[]) {
    setData({ ...data, leadNumbers: phones })
  }

  const sendCampaignClick = async () => {
    if (!data.templateData || !data.leadNumbers || data.leadNumbers.length == 0)
      return setCreatingError('please select leads and fill template')

    setCreatingError('')
    setIsCreatingLoading(true)
    const {
      headerFormat,
      headerMediaSampleFile,
      name,
      language,
      category,
      headerTextSample,
      bodySample,
      components,
    } = data.templateData
    try {
      /* Header media upload */
      let uploadedHeaderMediaSampleFile = null

      if (
        headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT'
      ) {
        const formData = prepareMediaFormData({
          my_file: headerMediaSampleFile,
        })
        const uploadResult: any = await mediaFileFunction(formData)
        if (uploadResult?.error) {
          setIsCreatingLoading(false)
          return setCreatingError('Failed to upload media, please try again')
        }
        uploadedHeaderMediaSampleFile = uploadResult?.data?.id
      }

      /* Create Campaign */
      const result: any = await sendCampaignFunction({
        leads: data.leadNumbers,
        template: {
          name,
          category,
          language,
          headerFormat,
          headerMediaSampleFile: uploadedHeaderMediaSampleFile,
          headerTextSample,
          bodySample,
          components: components,
        },
      })

      if (result?.error) {
        setIsCreatingLoading(false)
        return setCreatingError('Failed to create campaign, please try again')
      }

      toast({
        title: 'Campaign created successfully',
        description: `${result.data?.successfulCampaigns} messages sent successfully, ${result.data?.failedCampaigns} messages failed `,
      })
      setData(initialData)
      setIsCreatingLoading(false)
      setCreatingError('')

      setOpen(false)
    } catch (error) {
      setIsCreatingLoading(false)
      return setCreatingError('Failed to create campaign, please try again')
    }
  }

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
          <div className="h-full w-full">
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  New Campaign
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>
              <div className={`space-y-3 mt-5 ${step != 1 && 'hidden'}`}>
                <SelectTemplate
                  selectTemplate={addTemplate}
                  selectedTemplate={data?.template}
                />
                <div className="mt-5 ">
                  {data.template.id && (
                    <FillCampaignTemplate
                      template={data.template}
                      setCampaignData={setData}
                      setStep={setStep}
                    />
                  )}
                </div>
              </div>

              <div className={`space-y-3 mt-5 ${step != 2 && 'hidden'}`}>
                <FilterLeads open={open} setSelectedPhones={selectPhones} />
                {creatingError !== '' && (
                  <div className="text-rose-600 text-sm">{creatingError}</div>
                )}
                <div className="pt-5 flex items-center justify-between">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  {data.leadNumbers?.length > 0 && (
                    <Button
                      size="sm"
                      onClick={sendCampaignClick}
                      disabled={isCreatingLoading}
                    >
                      Send Campaign
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
