import Loader from 'components/shared/loader'
import { useEffect, useState } from 'react'
import UserReports from './components/user-report'

export default function Reports() {
  const [loading, setLoading] = useState(true)
  const pagesCount = sessionStorage.getItem('total-charts') || '0'

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading)
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )

  return (
    <div className="relative w-full h-full">
      <UserReports count={parseInt(pagesCount)} />
    </div>
  )
}
